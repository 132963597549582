import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
// import Creddits from "../Creddit";
import "./PacManGameEVE.scss";
const style = {
  position: "absolute",
  backgroundColor: "black",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  border: "1px solid ",
  borderRadius: "15px",
  p: 4,
  minWidth: "500px",
};

export default function PackManGameEVE(props) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleKeyDown = useCallback(
    (e) => {
      var keys = [];

      if (e.key === "b") {
        if (!keys.includes("b")) {
          keys.push(e.key);
        }
      } else if (e.key === "u") {
        if (!keys.includes("u")) {
          keys.push(e.key);
        }
      } else if (e.key === "g") {
        if (!keys.includes("g")) {
          keys.push(e.key);
        }
      }
      if (keys.toString() === "b,u,g") {
        handleOpen();
        keys = [];
      }
    },
    [handleOpen] // No need to include keys in the dependency array
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Box sx={style}>
              <iframe
                title="pacman"
                className="Marco"
                src="./Easteregg/pacman.html"
                width={"100%"}
                height={"700px"}
              ></iframe>
              {/* <Creddits /> */}
            </Box>
          </Fade>
        </Modal>
      </Box>
    </>
  );
}
