import { env2 } from "../../../Helpers/Instance";
import { endPoint } from "../../../Services/EndPoint";
import {
  getRequest,
  updateRequestForm,
  downloadFile,
  getRequestText,
  postAppJsonRequest,
  downloadScriptZip,
} from "../../../Services/FetchRequests";
import {
  getMsgResult,
  getMsgResultText,
  getResultAndShowSuccess,
} from "../../../Services/ResponseValidation";

export const LevelColor = (level) => {
  let Color = "";
  switch (level) {
    case "Critical":
      Color = "#6a0000";
      break;
    case "High":
      Color = "#ff0000";
      break;

    case "Midd":
      Color = "#ff5830";
      break;

    case "Low":
      Color = "#ffffa2";
      break;

    case "None":
      Color = "#c039ff";
      break;
    default:
      Color = "#FFF";
      break;
  }
  return Color;
};

export const getType = (ext) => {
  let val = "";
  switch (ext) {
    case "bat":
      val = "Batch File";
      break;
    case "com":
      val = "Script File";
      break;
    case "exe":
      val = "Executable";
      break;
    case "xlsx":
      val = "Office Excel";
      break;
    case "docx":
      val = "Office Word";
      break;
    case "doc":
      val = "Office Word";
      break;

    default:
      val = "Unknown Extension";
      break;
  }
  return val;
};

export const tags = [
  { id: "tagOne", description: "untested" },
  { id: "tagTwo", description: "tested" },
  { id: "tagThree", description: "PartiallyTested" },
];

export async function AddNewScriptFileAsync(token, body) {
  const url = env2.api + endPoint.addNewScript;
  const response = await updateRequestForm(token, url, body);
  return getResultAndShowSuccess(response, "Success Upload Script");
}

export async function UpdateScriptById(token, id, body) {
  const url = env2.api + endPoint.editScript + id;
  const response = await updateRequestForm(token, url, body);
  return getResultAndShowSuccess(response, "Success Updated Script");
}

export async function GetScriptByIdAsync(token, id) {
  const url = env2.api + endPoint.getScriptById + id;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function GetPaginationAdvance(
  token,
  size,
  Nopage,
  orderby,
  order,
  bodyContent
) {
  const url =
    env2.api +
    endPoint.advancePaginationScript +
    size +
    "/" +
    Nopage +
    "?col=" +
    orderby +
    "&order=" +
    order;
  const body = JSON.stringify(bodyContent);
  const response = await postAppJsonRequest(token, url, body);
  return getMsgResult(response);
}

// export async function GetPaginationMin(token, size, Nopage, orderby, order) {
//   const url = env.api + endPoint.minPaginationScript + size + "/" + Nopage + "?col=" + orderby + "&order=" + order;
//   const response = await getRequest(token, url);
//   return getMsgResult(response);
// }

export async function downloadScriptFileAsync(token, file, mode) {
  const url = env2.api + endPoint.downloadScriptFile + file.id + "/" + mode;
  await downloadFile(token, url, file.name);
}
export async function getAllScripts(token) {
  const url = env2.api + endPoint.getAllScript;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}
export async function deleteScriptByIdAsync(token, id) {
  const url = env2.api + endPoint.deleteScript + id;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, "Delete Script");
}

export async function getCodeScript(token, id) {
  const url = env2.api + endPoint.getCodeScript + id + "/CODE";
  const response = await getRequestText(token, url);
  return getMsgResultText(response);
}

export async function GetPaginationAdvanceBox(
  token,
  size,
  Nopage,
  orderby,
  order,
  bodyContent
) {
  const url =
    env2.api +
    endPoint.advancePaginationBox +
    size +
    "/" +
    Nopage +
    "?col=" +
    orderby +
    "&order=" +
    order;
  const body = JSON.stringify(bodyContent);
  const response = await postAppJsonRequest(token, url, body);
  return getMsgResult(response);
}

export async function getAllBoxExecutuion(token) {
  const url = env2.api + endPoint.getAllBox;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function GetEmulationScriptById(token, id) {
  const url = env2.api + endPoint.getEmulationScriptById + id;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function deleteBoxByIdAsync(token, id) {
  const url = env2.api + endPoint.deleteBox + id;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, "Delete Execution");
}
export async function GetBoxByIdAsync(token, id) {
  const url = env2.api + endPoint.getDetailsBox + id;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}
export async function AddNewScriptEmulation(token, bodyContent) {
  const url = env2.api + endPoint.addNewScriptEmulation;
  const body = JSON.stringify(bodyContent);

  const response = await postAppJsonRequest(token, url, body);
  return getResultAndShowSuccess(response, "Emulation Script Created Success");
}

export async function UpdatedScriptEmulation(token, id, body) {
  const url = env2.api + endPoint.updateScriptEmulation + id;
  const response = await postAppJsonRequest(token, url, body);
  return getResultAndShowSuccess(response, "Emulation Script Created Success");
}

export async function GetResultsEmulationBox(token) {
  const url = env2.api + endPoint.getAllBoxEmulationResults;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}
export async function GetEmulationBoxById(token, id) {
  const url = env2.api + endPoint.getBoxEmulationByID + id;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}
export async function DonwloadZipbox(token, Name, data) {
  const url = env2.api + endPoint.getLogScriptZip;
  await downloadScriptZip(token, url, Name, data);
}
export async function getPreviewLog(token,body) {
  const url = env2.api + endPoint.previewlog;
  const response = await postAppJsonRequest(token, url, body);
  return getMsgResult(response);
}