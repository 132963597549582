import React from 'react';
import { myColor} from  "../../../../BaseCss";
import { Typography, Modal } from "@mui/material";
import { SecondaryButton, ActionButton } from "../../../../Helpers/Themes";

const modalStyle = {
    width: "25%",
    backgroundColor: myColor.BgSecundary,
    border: "1px solid " + myColor.BorderSecundary,
    boxShadow: "18px 4px 35px rgba(0, 0, 0, 0.15)",
    borderRadius: "14px",
    padding: "40px",
    textAlign: "center",
}
class DeleteWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openWindow: true,
    };
  }
  render() {
    return (
   
      <Modal open={this.props.open} onClose={this.props.onCancel}>
                <div className="Section"  style={modalStyle}>
                    <Typography variant="h6">{this.props.message}</Typography>
                    <ActionButton variant="contained" onClick={this.props.onDelete}> Delete </ActionButton>
                    <SecondaryButton variant="contained" onClick={this.props.onCancel}>Cancel</SecondaryButton>
                </div>
      </Modal>
    );
  }
}

export default DeleteWindow;
