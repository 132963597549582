import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import ExecutionCountIcon from "../../../assets/images/execution-icon.svg";

export default function ModalGeneralReport(props) {
    const { AgentGlobalData } = props;
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <Box
          className="Section"
          style={{
            backgroundColor: "#242933",
            border: "1px solid #090f1a",
            boxShadow: "18px 4px 35px rgb(0 0 0 / 15%)",
            borderRadius: "14px",
            padding: "40px",
            width: "600px",
          }}
        >
          <Typography>Summary:</Typography>
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              display: "inline-block",
              margin: "7px",
              width: "30%",
            }}
          >
            <Typography>Total Files Sent</Typography>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "34px",
                display: "block",
                color: "#fff",
              }}
            >
              {AgentGlobalData.Success + AgentGlobalData.Faild}
            </Typography>
          </Box>
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              display: "inline-block",
              margin: "7px",
              width: "30%",
            }}
          >
            <Typography>Total Error</Typography>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "34px",
                display: "block",
                color: "#ff4747",
              }}
            >
              {AgentGlobalData.Faild}
            </Typography>
          </Box>
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              display: "inline-block",
              margin: "7px",
              width: "30%",
            }}
          >
            <Typography>Total Success</Typography>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "34px",
                display: "block",
                color: "#38c135",
              }}
            >
              {AgentGlobalData.Success}
            </Typography>
          </Box>
          <Typography
              style={{
                fontWeight: "700",
                fontSize: "14px",
                display: "block",
                color: "#fff",
              }}
            >
              <Typography>Notes:</Typography>
              {AgentGlobalData?.Message}
            </Typography>
        </Box>

    );

    return (
        <Box style={{ display: "inline-block", cursor: "pointer" }}>
            <Button
                style={{
                    backgroundColor: "#b69141",
                    borderRadius: "14px",
                    fontWeight: "600",
                    fontSize: "15px",
                    lineHeight: "22px",
                    color: "#fff",
                    textAlign: "center",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "14px 20px",
                    border: "none",
                }}
                onClick={handleOpen}
            >
                <img
                    src={ExecutionCountIcon}
                    alt=""
                    style={{
                        marginRight: "7px",
                        verticalAlign: "middle",
                    }}
                />
                Summary
            </Button>
            <Modal open={open} onClose={handleClose}>
                {body}
            </Modal>
        </Box>
    );
}