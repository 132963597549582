/* eslint-disable no-undef */
export default function Top10MostusedAgentsScript(
  top10AgentsData,
  disposed,
  exported
) {
  var objecImageExporting;
  am4core.ready(function () {
    // Themes begin
    am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create("Top10MostUsedAgentsDiv", am4charts.XYChart);
    chart.logo.height = -15000;
    am4core.options.autoDispose = true;

    chart.data = top10AgentsData;

    chart.padding(40, 40, 40, 40);
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "agentname";
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.fontSize = "10px";

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;

    chart.colors.list = [
      am4core.color("#5ECFFF"),
      am4core.color("#E328AF"),
      am4core.color("#FDC501"),
      am4core.color("#9743FF"),
      am4core.color("#FF4A55"),
      am4core.color("#2BC155"),
      am4core.color("#5E6EFF"),
      am4core.color("#CAA521"),
      am4core.color("#8200A3"),
      am4core.color("#CFF000"),
    ];

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "agentname";

    series.dataFields.valueY = "visits";
    series.tooltipText = "{valueY.value}";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.width = "10px";
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.cornerRadiusTopLeft = 10;

    let label = categoryAxis.renderer.labels.template;
    // label.wrap = true;
    label.truncate = true;
    label.maxWidth = 80;
    label.tooltipText = "{agentname}";

    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.verticalCenter = "bottom";
    labelBullet.label.dy = -10;
    labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
    // labelBullet.label.fontSize="10px";

    chart.zoomOutButton.disabled = true;

    series.columns.template.adapter.add("fill", function (fill, target) {
      return target.dataItem._dataContext.color;
    });

    var legend = new am4charts.Legend();
    legend.parent = chart.chartContainer;
    legend.itemContainers.template.togglable = false;
    legend.marginTop = 20;

    series.events.on("ready", function (ev) {
      let legenddata = [];
      series.columns.each(function (column) {
        legenddata.push({
          name: column.dataItem.categoryX,
          fill: column.fill,
        });
      });

      legend.data = legenddata;
    });    
    
    if (disposed === true)
    {
      chart.dispose();
    }
    if(exported === true)
    {
      objecImageExporting = chart.exporting.getImage('png');
    }    
  }); // end am4core.ready()
  return objecImageExporting;
}
