import { styled } from "@mui/material/styles";
import { List, Button } from "@mui/material";


export const ListItems = styled(List)({
  "& .Mui-selected": {
    backgroundColor: "#c59d48 !important",
  },
  "& .Mui-selected:hover": {
    backgroundColor: "#544628",
  },
  "& .Mui-selected:focus": {
    backgroundColor: "#c59d48 ",
  },
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "#544628",
  },
  "& .MuiListItemButton-root:focus": {
    backgroundColor: "#c59d48",
  },
  });
  

export const ButtonRowsExecutions = styled(Button)(({ theme }) => ({
  margin: "5px",
  padding: "10px",
  color: theme.palette.getContrastText("#15132b"),
  backgroundColor: "#15132b",
  border: "1px solid #1e1c3a",
  "&:hover": {
    backgroundColor: "#15132b",
  },
  }));