import { Box, Divider, Grid, Typography } from "@mui/material";
import "./StepLaunchScript.scss";

export default function StepLaunchScript(props) {
  const { badCombination, scriptSelection, agentSelection, dataEmulation } =
    props;
  return (
    <Grid
      container
      style={{
        backgroundColor: "rgb(21, 19, 43)",
        borderRadius: "8px",
        padding: "20px",
        width: "100%",
        margin: "auto",
        maxHeight: "400px",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Grid item md={12}>
        <Typography variant="h6" style={{ margin: "7px" }} textAlign="center">
          {"Summary"}
        </Typography>
        <Divider />
        <Typography style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}>
          {"Name"}
        </Typography>
        <Typography style={{ margin: "0px 8px" }}>
          {dataEmulation.name}
        </Typography>
        <Typography style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}>
          {"Description:"}
        </Typography>
        <Typography style={{ margin: "0px 8px" }}>
          {dataEmulation.description}
        </Typography>
        <Divider />
        <Typography style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}>
          {"Endpoint(s):"}
        </Typography>
        {agentSelection.map((agent) => {
          return (
            <Typography
              style={{
                margin: "0px 8px",
                fontSize: "14px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                  display: "inline-flex",
                  marginRight: "7px",
                }}
              />{" "}
              {agent.hostname}
            </Typography>
          );
        })}
        <Typography style={{ color: "#c59d48", margin: "12px 7px 0px 7px" }}>
          {"Script(s):"}
        </Typography>
        {scriptSelection.map((script) => {
          return (
            <Typography
              style={{
                margin: "0px 8px",
                fontSize: "14px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "50px",
                  backgroundColor: "#fff",
                  display: "inline-flex",
                  marginRight: "7px",
                }}
              />{" "}
              {script.name}
            </Typography>
          );
        })}{" "}
        {badCombination && (
          <Typography>
            <Box
              style={{
                border: "1px solid #c59d48",
                backgroundColor: "#c59d4866",
                padding: "15px",
                marginTop: "15px",
              }}
            >
              "Some scripts are not compatible with the selected endpoints, do
              you want to continue with data saving, some scripts may not run."
            </Box>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
