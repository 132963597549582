import { Box } from "@mui/system";
import "./Top10MostUsedAgents.scss";

export default function Top10MosUsedAgents(props) {
 
  return (
    <Box className="dash-cmn-box">
      <h2
        style={{
          padding: "10px",
          borderRadius: "12px 12px 0px 0px",
          backgroundColor: "#090f1a",
        }}
        className="dashbord_main_title"
      >
       Top 10 Targeted Endpoints
         </h2>

      <Box
        style={{
          margin: "0px",
          width: "100%",
          height: "550px",
          fontSize: "14px",
        }}
      >
        <Box id="Top10MostUsedAgentsDiv"></Box>
      </Box>
 
      </Box>

  );
}
