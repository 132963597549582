/* eslint-disable no-undef */
export default function FullMapRotationScript(agentsConnected,disposed, invoke) {
  if(invoke){
    var array = [];

    am4core.ready(function () {
      // Themes begin
      am4core.useTheme(am4themes_animated);
      am4core.options.autoDispose = true;
      // Themes end
  
      // Create map instance
      let chart = am4core.create("full-map-chart", am4maps.MapChart);
      am4core.options.autoDispose = true;
  
      chart.logo.height = -15000;
  
      try {
        chart.geodata = am4geodata_worldLow;
      } catch (e) {
        chart.raiseCriticalError(
          new Error(
            'Map geodata could not be loaded. Please download the latest <a href="https://www.amcharts.com/download/download-v4/">amcharts geodata</a> and extract its contents into the same directory as your amCharts files.'
          )
        );
      }
  
      // Set projection
      chart.projection = new am4maps.projections.Orthographic();
      chart.panBehavior = "rotateLongLat";
      chart.deltaLatitude = -20;
      chart.padding(20, 20, 20, 20);
      // limits vertical rotation
      chart.adapter.add("deltaLatitude", function (delatLatitude) {
        return am4core.math.fitToRange(delatLatitude, -90, 90);
      });
  
      // Set projection
      chart.projection = new am4maps.projections.Orthographic();
      chart.panBehavior = "rotateLongLat";
      chart.padding(20, 20, 20, 20);
  
      // Add zoom control
      chart.zoomControl = new am4maps.ZoomControl();
  
      var homeButton = new am4core.Button();
      homeButton.events.on("hit", function () {
        chart.goHome();
      });
  
      homeButton.icon = new am4core.Sprite();
      homeButton.padding(7, 5, 7, 5);
      homeButton.width = 30;
      homeButton.icon.path =
        "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
      homeButton.marginBottom = 10;
      homeButton.parent = chart.zoomControl;
      homeButton.insertBefore(chart.zoomControl.plusButton);
  
      chart.backgroundSeries.mapPolygons.template.polygon.fill =
        am4core.color("#353A48");
      chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;
      chart.deltaLongitude = 20;
      chart.deltaLatitude = -20;
  
      // limits vertical rotation
      chart.adapter.add("deltaLatitude", function (delatLatitude) {
        return am4core.math.fitToRange(delatLatitude, -90, 90);
      });
  
      // Create map polygon series
  
      var shadowPolygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      shadowPolygonSeries.geodata = am4geodata_continentsLow;
  
      try {
        shadowPolygonSeries.geodata = am4geodata_continentsLow;
      } catch (e) {
        shadowPolygonSeries.raiseCriticalError(
          new Error(
            'Map geodata could not be loaded. Please download the latest <a href="https://www.amcharts.com/download/download-v4/">amcharts geodata</a> and extract its contents into the same directory as your amCharts files.'
          )
        );
      }
  
      shadowPolygonSeries.useGeodata = true;
      shadowPolygonSeries.dx = 2;
      shadowPolygonSeries.dy = 2;
      shadowPolygonSeries.mapPolygons.template.fill = am4core.color("#000");
      shadowPolygonSeries.mapPolygons.template.fillOpacity = 0.2;
      shadowPolygonSeries.mapPolygons.template.strokeOpacity = 0;
      shadowPolygonSeries.fillOpacity = 0.1;
      shadowPolygonSeries.fill = am4core.color("#000");
  
      // Create map polygon series
      var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
  
      polygonSeries.calculateVisualCenter = true;
      polygonSeries.tooltip.background.fillOpacity = 0.2;
      polygonSeries.tooltip.background.cornerRadius = 20;
  
      var template = polygonSeries.mapPolygons.template;
      template.nonScalingStroke = true;
      template.fill = am4core.color("#454958");
      template.stroke = am4core.color("#667A90"); //listo
  
      polygonSeries.calculateVisualCenter = true;
      template.propertyFields.id = "id";
      template.tooltipPosition = "fixed";
      template.fillOpacity = 1;
  
      template.events.on("over", function (event) {
        if (event.target.dummyData) {
          event.target.dummyData.isHover = true;
        }
      });
      template.events.on("out", function (event) {
        if (event.target.dummyData) {
          event.target.dummyData.isHover = false;
        }
      });
  
      var hs = polygonSeries.mapPolygons.template.states.create("hover");
      hs.properties.fillOpacity = 1;
      hs.properties.fill = am4core.color("#deb7ad");
  
      var graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
      graticuleSeries.mapLines.template.stroke = am4core.color("#fff");
      graticuleSeries.fitExtent = false;
      graticuleSeries.mapLines.template.strokeOpacity = 0.2;
      graticuleSeries.mapLines.template.stroke = am4core.color("#fff");
  
      var measelsSeries = chart.series.push(new am4maps.MapPolygonSeries());
      measelsSeries.tooltip.background.fillOpacity = 0;
      measelsSeries.tooltip.background.cornerRadius = 20;
      measelsSeries.tooltip.autoTextColor = false;
      measelsSeries.tooltip.label.fill = am4core.color("#000");
      measelsSeries.tooltip.dy = -5;
  
      var measelTemplate = measelsSeries.mapPolygons.template;
      measelTemplate.fill = am4core.color("#bf7569");
      measelTemplate.strokeOpacity = 0;
      measelTemplate.fillOpacity = 0.75;
      measelTemplate.tooltipPosition = "fixed";
  
      var hs2 = measelsSeries.mapPolygons.template.states.create("hover");
      hs2.properties.fillOpacity = 1;
      hs2.properties.fill = am4core.color("#86240c");
  
      var imageSeries = chart.series.push(new am4maps.MapImageSeries());
      imageSeries.mapImages.template.propertyFields.longitude = "longitude";
      imageSeries.mapImages.template.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipText = "{title}";
      imageSeries.mapImages.template.propertyFields.url = "url";
  
      var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.radius = 3;
      circle.propertyFields.fill = "color";
  
      var circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.radius = 3;
      circle2.propertyFields.fill = "color";
  
      circle2.events.on("inited", function (event) {
        animateBullet(event.target);
      });
  
      function animateBullet(circle) {
        var animation = circle.animate(
          [
            { property: "scale", from: 1, to: 5 },
            { property: "opacity", from: 1, to: 0 },
          ],
          1000,
          am4core.ease.circleOut
        );
        animation.events.on("animationended", function (event) {
          animateBullet(event.target.object);
        });
      }
      let colorSet = new am4core.ColorSet();
      if (agentsConnected.length > 0) {
        agentsConnected.forEach((element) => {      
          var agents = {
            title: element.hostname,
            latitude: element.coordinates[0],
            longitude: element.coordinates[1],
            color: colorSet.next(),
          }
          if(element){
            array.push(agents);
          }
      });
      }
  
    
    
  
      //var colorSet = new am4core.ColorSet();
  
  // imageSeries.data = [ {
  //   "title": "Brussels",
  //   "latitude": 50,
  //   "longitude": 4,
  //   "color":colorSet.next()
  // }, {
  //   "title": "Copenhagen",
  //   "latitude": 55,
  //   "longitude": 12,
  //   "color":colorSet.next()
  // },]
  
      imageSeries.data = array;
  
      let animation;
      setTimeout(function () {
        animation = chart.animate(
          { property: "deltaLongitude", to: 100000 },
          20000000
        );
      }, 3000);
  
      chart.seriesContainer.events.on("down", function () {
        if (animation) {
          animation.stop();
        }
      });
      if(disposed === true)
      {chart.dispose();}
    }); // end am4core.ready()
  }  
}
