import { useState } from "react";
import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import { ActionButton, pageTheme, SecondaryButton } from "../../Helpers/Themes";
import PasswordInput from "../../Helpers/CommonComponents/PasswordInput";
import { updatePassword } from "../User/services/Users";
import { NotifyError, NotifyInfo } from "../../Helpers/Scripts";

export default function Change(props) {
  const { DataUser } = props;

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setrepasword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let flag = true;

    if (oldPassword === "") {
      flag = false;
    }
    if (password === "") {
      flag = false;
    }
    if (password !== repassword) {
      flag = false;
    }
    if (repassword === "") {
      flag = false;
    }

    if (flag) {
      const userData = {
        id: DataUser.user.result._id,
        oldPassword: oldPassword,
        password: password,
        repassword: repassword,
        rol: DataUser.user.result.rol,
      };
      const response = await updatePassword(DataUser.token, userData);
      if (response) {
        NotifyInfo(response.msg);

        setOldPassword("");
        setPassword("");
        setrepasword("");
        setTimeout(() => {
          props.Redirect();
        }, 3000);
      }
    } else {
      NotifyError("Passwords not match our empty");
    }
  };
  const ChangeOldPasword = (Value) => {
    setOldPassword(Value);
  };
  const ChangePasword = (Value) => {
    setPassword(Value);
  };
  const ChangeRePasword = (Value) => {
    setrepasword(Value);
  };

  return (
    <ThemeProvider theme={pageTheme}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box
            className="dash-top-tlt"
            sx={{ display: { xs: "none", md: "inline-block" } }}
          >
            <Box className="contenido" style={{ maxWidth: "500px" }}>
              <Box className="header">
                <Typography className="headerTitle">Change Password</Typography>
              </Box>
              <Grid container>
                <Grid item md={12}>
                  <Typography>Old Password</Typography>

                  <PasswordInput
                    Value={ChangeOldPasword}
                    LabelInput="Old Password"
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography>New Password</Typography>
                  <PasswordInput Value={ChangePasword} LabelInput="Password" />
                </Grid>

                <Grid item md={12}>
                  <Typography>Repeat Password</Typography>

                  <PasswordInput
                    Value={ChangeRePasword}
                    LabelInput="Confirm password"
                  />
                </Grid>
                <Grid item md={12} style={{ textAlign: "center" }}>
                  <SecondaryButton
                    onClick={() => {
                      props.Redirect();
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <ActionButton onClick={handleSubmit}>Save</ActionButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
