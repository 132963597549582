import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, styled, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import launchIcon from "../../../../../../assets/images/launch-icon.svg";
import { ThemeProvider } from "@mui/material/styles";
import {
  pageTheme,
  PrimaryButton,
  SecondaryButton,
} from "../../../../../../Helpers/Themes";
import {
  NotifyError,
  NotifySuccess,
  NotifyWarning,
} from "../../../../../../Helpers/Scripts";
import { GetBoxByIdAsync } from "../../../../Services/ServiceScriptPage";

const styleModal = {
  boxShadow: "18px 4px 35px #00000026",
  borderRadius: "14px",
  maxWidth: "700px",
  margin: "0 auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "#0a0818",
  color: "white",
  p: 2,
};

const ButtonLauncher = styled(Button)({
  padding: "10px",
  color: "#fff",
  backgroundColor: "#b69141",
});

export default function LaunchBox(props) {
  const { ClientOfExecution, openModalGet, DataUser,AgentConnect } = props;
  const [openModal, setOpenModal] = React.useState(openModalGet);
  const [clientOfExecution, setClientOfExecution] = useState(ClientOfExecution);
  const [agentscon, setAgentsCon] = useState(AgentConnect);
  const [agents, setAgents] = useState([]);

    useEffect(() => {
      setAgentsCon(AgentConnect)
    }, [AgentConnect])

  useEffect(() => {
    setClientOfExecution(ClientOfExecution);
  }, [ClientOfExecution]);

  const handleOpenModal = async () => {
    const getBoxEmulation = await GetBoxByIdAsync(
      DataUser.token,
      ClientOfExecution.id
    );
    // console.log(getBoxEmulation);
    setAgents(getBoxEmulation.fk_client);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    props.Reload();
  };
  const startExecute = () => {
    if (openModal === true) {
      if (agents.length !== 0) {
        ValidateAgentsConExecution();
        props.Execute(clientOfExecution.id);

      } else {
        NotifyError("No Endpoints connected ");
      }

      handleCloseModal();
    }
  };

  const ValidateAgentsConExecution = () => {
    //let valueFlag = true;
    let agentCon = agentscon.map((element) => element.hostname);
    let agentsInExecution = agents?.map(
      (element) => element.workUser[0].hostname
    );
    if (agentsInExecution.length > 0) {
      agentsInExecution.forEach((element) => {
        let validate = agentCon.indexOf(element);
        if (validate !== -1) {
          NotifySuccess("Send to emulation: " + element);
        } else {
          NotifyWarning(`Warning Endpoint ${element} disconnected.`);
        }
      });
    }
    if (agentCon.length > 0) {
      NotifySuccess("Send to emulation");
    } else {
      NotifyWarning("Warning not Endpoints Connected.");
    }

    //setbooflag(valueFlag)
  };
  return (
    <>
      <ButtonLauncher onClick={handleOpenModal}>
        <img style={{ marginRight: "8px" }} src={launchIcon} alt="" />
        Launch
      </ButtonLauncher>
      <ThemeProvider theme={pageTheme}>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Box sx={styleModal}>
              <Box
                style={{
                  textAlign: "center",
                  backgroundColor: "#242933",
                  border: "1px solid #090f1a",
                  boxShadow: "18px 4px 35px #00000026",
                  borderRadius: "14px",
                  padding: "15px",
                  minWidth:"350px",
                  maxWidth: "700px",
                  margin: "0 auto",
                }}
              >
                <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                <Typography id="keep-mounted-modal-title" variant="h6">
                  Are you sure to start?
                </Typography>
                  <Box style={{ textAlign: "center", fontSize: "35px" }}>
                    <img
                      style={{ width: "100px" }}
                      src={launchIcon}
                      alt=""
                    ></img>
                  </Box>
                </Typography>
                <Box
                  style={{
                    textAlign: "center",
                  }}
                >
                  <SecondaryButton onClick={handleCloseModal}>
                    No
                  </SecondaryButton>
                  <PrimaryButton onClick={startExecute}>Yes</PrimaryButton>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </ThemeProvider>
    </>
  );
}
