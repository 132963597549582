import React from "react";
import { Box, Grid, Typography } from "@mui/material";

class ExecutionsResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalSuccess: this.props.TotalSuccess,
      TotalResult: this.props.TotalResult,
      TotalNoSend: this.props.TotalNoSend,
      TotalFailed: this.props.TotalFailed,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.TotalSuccess !== this.props.TotalSuccess) {
      this.setState({ TotalSuccess: this.props.TotalSuccess });
    }
    if (prevProps.TotalResult !== this.props.TotalResult) {
      this.setState({ TotalResult: this.props.TotalResult });
    }
    if (prevProps.TotalNoSend !== this.props.TotalNoSend) {
      this.setState({ TotalNoSend: this.props.TotalNoSend });
    }
    if (prevProps.TotalFailed !== this.props.TotalFailed) {
      this.setState({ TotalFailed: this.props.TotalFailed });
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            padding:"10px",
            backgroundColor: "#090f1a",
          }}
          className="dash-cmn-box"
        >
          <h2 className="dashbord_main_title">Total Emulations</h2>
          <Grid style={{ marginTop: "5px" }} container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Box className="dashboardTable">
                <Box className="CountLabel">
                  <Typography style={{ margintop: "-15px" }}>
                    {this.state.TotalSuccess}
                  </Typography>
                </Box>
                <Box className="Barra Success"></Box>
                <Box className="CountLabel">
                  <Typography>Successful</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box className="dashboardTable">
                <Box className="CountLabel">
                  <Typography style={{ margintop: "-15px" }}>
                    {this.state.TotalNoSend}
                  </Typography>
                </Box>
                <Box className="Barra NotSend"></Box>
                <Box className="CountLabel">
                  <Typography>Not Sent</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box className="dashboardTable">
                <Box className="CountLabel">
                  <Typography style={{ margintop: "-15px" }}>
                    {this.state.TotalFailed}
                  </Typography>
                </Box>
                <Box className="Barra Fail"></Box>
                <Box className="CountLabel">
                  <Typography>Failed</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default ExecutionsResults;
