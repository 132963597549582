import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Modal, Chip, Alert, Stack } from "@mui/material";
import "../../BodyScriptPageStyles.scss";

export default function DetailsErrors(props) {
  const { DataError } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    // console.log(DataError);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box
      className="Section"
      style={{
        width: "auto",
        backgroundcolor: "#66638f",
        borderBottom: "1px solid #1e1c3a",
        maxHeight: "700px",
        overflowY: "auto",
        overflowX: "hidden",
        // minWidth: "300px",
      }}
    >
      <Typography>{}</Typography>
      <Stack sx={{ width: "100%" }} spacing={2}>
        {DataError !== "-" ? (
          <>
            <Alert severity="error">{DataError}</Alert>
          </>
        ) : (
          <>
            <Alert severity="error">
              Error in emulation script retry to emulation
            </Alert>
            <Alert severity="warning">
              This is emulation script without result retry please to launch
              emulation script
            </Alert>
          </>
        )}
      </Stack>
    </Box>
  );

  return (
    <>
      <Chip
        style={{ cursor: "pointer" }}
        variant="outlined"
        label="With Errors"
        color="error"
        onClick={handleOpen}
      />
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
}
