import { useState } from "react";
import validator from "validator";
import { lockPassword } from "../../User/services/Users";
import Box from "@mui/material/Box";
import "../../../Pages/Login/GeneralStyles.scss";

import { NotifySuccess } from "../../../Helpers/Scripts";
import { Button, Grid, InputAdornment } from "@mui/material";
import { FormInputTextField, SecondaryButton } from "../../../Helpers/Themes";
import { AccountCircle } from "@mui/icons-material";
import Logo from "../../../assets/logo.png";
import { Link } from "react-router-dom";

export default function ForgotPassword(props) {
  const [emailAddress, setEmailAddress] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isValid, setIsValid] = useState(false);

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailAddress(email);
    setEmailError("");

    if (email.length > 0) {
      if (validator.isEmail(email)) {
        setIsValid(true);
        setEmailError("");
      } else {
        setIsValid(false);
        setEmailError("enter valid email!");
      }
    } else {
      setIsValid(false);
      setEmailError("Email is required");
    }
  };

  const forgotPassword = async () => {
    if (isValid) {
      const response = await lockPassword(emailAddress);
      if (response === true) {
        NotifySuccess("Check you email");
      }
    } else {
      setEmailError("Enter valid email!");
    }
  };

  const getSendButtonClass = () => {
    return isValid ? "rt-enable-button" : "rt-disable-button";
  };

  return (
    <Box className="container-login">
      <Box className="container-login-box">
        <Box className="container-section-logos">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Link to="/">
                <img
                  src={Logo}
                  alt="logo-EVE"
                  className="logo-rthreat-modern"
                />
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Box className="container-section-modern">
          <Grid container spacing={5}>
            <Grid item xs={12} lg={12}>
              <h1>Forgot Password</h1>
              <p>
                Enter your email address, and we’ll send you a link to change
                your password.
              </p>
              <FormInputTextField
                id="input-with-icon-textfield"
                label="User Name"
                onChange={(e) => validateEmail(e)}
                value={emailAddress}
                placeholder="email"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle style={{ color: "#fff" }} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <span
                style={{ color: "red" }}
                className={"error text-danger " + (isValid ? "d-none" : "")}
              >
                {emailError}
              </span>
            </Grid>
            <Grid item sx={7} xs={12} lg={7}>
              <SecondaryButton
                className={getSendButtonClass()}
                onClick={() => forgotPassword()}
                variant="contained"
                type="submit"
                fullWidth
              >
                Send
              </SecondaryButton>
            </Grid>
            <Grid item sx={5} xs={12} lg={5}>
              <Button variant="text" type="submit" fullWidth>
                <Link className="link" to="/">
                  Back To Log in
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
