import React, { useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import { makeStyles } from '@mui/styles';
import { FormInputTextField } from "../../../../Helpers/Themes";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#c59d48',
    fontSize: 16,
    color: 'white',
    height: 'auto',
    padding: '0 30px',
    "& .PrivatePickersSlideTransition-root":{
     borderRadius: 30,
     backgroundColor: '#090f1a', 
     

    },
    "& .css-18fxmqw-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected":{
      backgroundColor: '#e328af',
    },
    "& .css-pgdzhj-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected":{
      backgroundColor: '#e328af',

    },
    "& .MuiTypography-root":{
      color:'#ffffff'
    },
    
  },
}));

export default function FilterFromToExecutions(props) {
  const {HandleChangeDate, HandleChange} = props;
  const [value, setValue] = useState(HandleChangeDate);
  const today= new Date();
  const classes = useStyles();

  useEffect(() => {
    setValue(value)
    HandleChange(value);   
  }, [HandleChange, value])

  return (
    <Box
      style={{
        color: "white",
        padding: "20px",
        margin: "20px",
        backgroundColor: "#1e1c3a",
        borderRadius: "10px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <MobileDateRangePicker    
            className={classes.root}
            startText="Start Date"
            value={value}
            maxDate={today}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(startProps, endProps) => (
              
              <React.Fragment>
                <FormInputTextField value={value} {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <FormInputTextField value={value} {...endProps} />
              </React.Fragment>
            )}           
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}
