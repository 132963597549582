import React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import dashtable from "../../../../assets/images/dashtable-arrow-svg.svg";
import FullMapRotationScript from "../../../../Scripts/FullMapRotationScript";
import "./AgentLocationMapRotating.scss";
import { PrimaryButton } from "../../../../Helpers/Themes";

const style = {
  position: "absolute",
  backgroundColor: "transparent",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "#090f1a",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

class AgentLocationMapRotating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AgentConnect: this.props.AgentConnect,
      open: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.AgentConnect !== this.props.AgentConnect) {
      this.setState({ AgentConnect: this.props.AgentConnect });
      if (this.state.open === true) {
        FullMapRotationScript(this.props.AgentConnect,false, true);
      }
    }
  }

  componentWillUnmount() {
    FullMapRotationScript([], true,false);
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  RenderMapFull = () => {
    this.handleOpen();

    setTimeout(() => {
      if (this.state.open === true) {
        FullMapRotationScript(this.state.AgentConnect,false,true);
      }
    }, 800);
  };
  render() {
    return (
      <Box>
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "10px",
            borderRadius: "12px 12px 0px 0px",
            backgroundColor: "#090f1a",
          }}
        >
          <h2 className="dashbord_main_title">Endpoint Locations</h2>
          <PrimaryButton
            onClick={this.RenderMapFull.bind()}
            style={{ marginLeft: "auto" }}
          >
            <img src={dashtable} alt="" />
            View full map
          </PrimaryButton>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box sx={style}>
              <Box
                style={{
                  width: "100%",
                  height: "500px",
                  color: "white",
                  background: "#090f1a",
                  borderRadius: "15px",
                }}
              >
                <Box id="full-map-chart"></Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
    );
  }
}

export default AgentLocationMapRotating;
