import React from "react";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import "../../../../General.scss";
import "../../styles/User.scss";
import { deleteUser } from "../../services/Users";
import GeneralModalDelete from "../../../../Helpers/CommonComponents/GeneralDeleteModal";

export default function Deletemodal(props) {
  const { id, DataUser } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deletelogic = async (id) => {
    handleClose();
    await deleteUser(DataUser.token, id);
    props.Reload();
  };

  return (
    <div style={{ display: "inline-block" }}>
      {/* <Tooltip title="Delete"> */}
        <IconButton
          className="iconButtonSize"
          style={{ color: "#66638f", display: "inline-block" }}
          onClick={handleOpen}
        >
          <Delete style={{ color: "#fff" }} />
        </IconButton>
      {/* </Tooltip> */}
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText=""
        BodyText=" Do you want to delete this user?"
        CloseAction={handleClose}
        PrimaryAction={deletelogic.bind(this, id)}
        CancelAction={handleClose}
      />
    </div>
  );
}
