import React from 'react';
import { Box, Typography, Input} from "@mui/material";
import {PageButton} from "../../PackageTheme";
import "../../Packages.scss";



class NameForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
        };     
    } 
    
    onKeyDown = (event) => {
        if( event.keyCode === 32) {      
          return false;      
        }
      }
    

    render(){
        return (
            <Box>
                <Box className="rt-TextAreaContent">
                    <Typography className="TexTTitles"> {this.props.title} </Typography>
                    <Input 
                        rows="1"
                        multiline fullWidth
                        className="TextAreaResolution"
                        value={this.props.valueInput}
                        onChange={(e) => {
                            let x = e.target.value.substring(0,1);
                               if(x !== " "){    
                                    if (e.target.value !== "") {

                                   this.props.handleChange(e.target.value);
                                  }else{                                       
                                    this.props.handleChange("");

                               }
                            }
                            }}>
                    </Input>
                </Box>
                <PageButton 
                    onClick={this.props.handleNext}
                    disabled={this.props.valueInput === ""}>
                    Next
                </PageButton>
            </Box>
        )
    }
}

export default NameForm;