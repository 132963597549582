import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Modal } from "@mui/material";
import { ActionButton, SecondaryButton } from "../../../Helpers/Themes";
import { Download } from "@mui/icons-material";
import { downloadArtifactAsync } from "../../../Services/Artifacts";
import "../Artefacts.scss"; 

export default function DowloadModalArtifact(props) {
  const { artefact, DataUser } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const download = async (e) => {
    await downloadfile(artefact.id);
  };
  async function downloadfile() {
    const artifactData = {
      id: artefact._id,
      name: artefact.name,
    };
    setOpen(false);
    await downloadArtifactAsync(DataUser.token, artifactData);
  }
  const body = (
    <Box
      className="Section"
      style={{
        width:"25%",
        backgroundColor: "#242933",
        border: "1px solid #090f1a",
        boxShadow: "18px 4px 35px rgba(0, 0, 0, 0.15)",
        borderRadius: "14px",
        padding: "40px",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" style={{ marginBottom: "30px" }}>
        Do you want to download this file?
      </Typography>
      <Typography style={{ marginBottom: "30px" }}>
        The file could be dangerous for your computer
      </Typography>
      <ActionButton onClick={download}>Yes</ActionButton>
      <SecondaryButton onClick={handleClose}>No</SecondaryButton>
    </Box>
  );

  return (
    <Box style={{ display: "inline-block" }}>
      <SecondaryButton style={{ display:DataUser.user.result.rol !== "admin"? "none":"", }} variant="contained" color="info" className="ArtifactButton"
        onClick={handleOpen}
      >
        <Download color="inherit"/>
        <Typography className="labelcontent"> File</Typography>
      </SecondaryButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}
