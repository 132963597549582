import { useEffect, useState } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import Greenchart from "../../../../assets/images/graphGreen.svg";
import Redchart from "../../../../assets/images/graphRed.svg";
import "../StyleReport.scss";

const styleIconChart = { width: "120px" };

export default function ExecutionDashboard(props) {
  const {
    clientsCount,
    countExecutionsLength,
    indexExecution,
    succesResultExecution,
    failedResultExecution,
    countSendArtifacts,
    succesResultArtifacts,
    failedResultArtifacts,
    tabledata1,
    typeStatus,
  } = props;
  const [index, setIndex] = useState(indexExecution);
  useEffect(() => {
    setIndex(indexExecution);
  }, [indexExecution]);

  return (
    <Box
      className={
        typeStatus ? "DashboardReport Attack" : "DashboardReport Defend"
      }
      style={{ backgroundColor: "#090f1a" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography align="center" style={{width:"100%"}}>Threat File Results Summary</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box
            style={{
              display: "inline-block",
              float: "right",
              fontWeight: "600",
              fontSize: "15px",
              color: "#ffffff",
            }}
          >
            No. Hosts:
            <Box
              style={{
                display: "inline-block",
                fontWeight: "600",
                fontSize: "18px",
                color: "#ffffff",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >              
              {clientsCount}
            </Box>
            <Box
              style={{
                display: "inline-block",
                fontWeight: "600",

                fontSize: "15px",
                color: "#ffffff",
              }}
            >
              |
            </Box>
            <Box
              style={{
                display: "inline-block",
                fontWeight: "600",
                fontSize: "15px",
                color: "#ffffff",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              {index === 0 ? "No. of Executions" : "No. of Files"}
            </Box>
            <Box
              style={{
                display: "inline-block",
                fontWeight: "600",
                fontSize: "18px",
                marginRight: "7px",
                color: "#ffffff",
              }}
            >
             
              {index === 0 ? countExecutionsLength : countSendArtifacts}
            </Box>
            <Box
              style={{
                display: "inline-block",
                fontWeight: "600",
                fontSize: "15px",
                color: "#ffffff",
              }}
            >
              |
            </Box>
            <Box
              style={{
                display: "inline-block",
                marginLeft: "15px",
                marginRight: "15px",
                fontWeight: "600",
                fontSize: "15px",
                color: "#ffffff",
              }}
            >
              Vector:
              {tabledata1[0].vector === "RealExecution" ? (
                <Chip
                  style={{
                    marginLeft: "15px",
                    width: "150px",
                  }}
                  variant="outlined"
                  label="Execution"
                  color="success"
                />
              ) : tabledata1[0].vector === "EPP" ? (
                <Chip
                  style={{
                    marginLeft: "15px",
                    width: "150px",
                  }}
                  variant="outlined"
                  label="Endpoint"
                  color="warning"
                />
              ) : (
                <Chip
                  style={{
                    marginLeft: "15px",
                    width: "150px",
                  }}
                  variant="outlined"
                  label="Network"
                  color="secondary"
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: "40px" }}>
                {typeStatus
                  ? `${succesResultExecution}%`
                  : `${failedResultExecution}%`}
              </Typography>
              <Typography>Successful</Typography>
            </Box>
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <img src={Greenchart} alt="" style={styleIconChart} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              height: "100%",
            }}
          >
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <Typography style={{ fontSize: "40px" }}>
                {typeStatus
                  ? `${failedResultExecution}%`
                  : `${succesResultExecution}%`}
              </Typography>
              <Typography>Failed</Typography>
            </Box>
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <img src={Redchart} alt="" style={styleIconChart} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              height: "100%",
            }}
          >
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <Typography>No. of Files in Emulation</Typography>
              <Typography style={{ fontSize: "40px" }}>
                {typeStatus
                  ? `${Math.round(succesResultArtifacts)}`
                  : `${Math.round(failedResultArtifacts)}`}
              </Typography>
              <Typography>Successful</Typography>
            </Box>
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <img src={Greenchart} alt="" style={styleIconChart} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              height: "100%",
            }}
          >
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <Typography></Typography>
              <Typography style={{ fontSize: "40px" }}>
                {typeStatus
                  ? `${Math.round(failedResultArtifacts)}`
                  : `${Math.round(succesResultArtifacts)}`}
              </Typography>
              <Typography>Failed</Typography>
            </Box>
            <Box
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "center",
              }}
            >
              <img src={Redchart} alt="" style={styleIconChart} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
