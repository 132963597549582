import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function SuccessandFail(props) {
    const {Data, Title} = props;
    const [dataTree, setdataTree] = useState(Data);

    useEffect(() => {
        setdataTree(Data);
    }, [Data, dataTree])

    return (
      <Box
      style={{
        minHeight: "200px",
        flex: "0 0 50%",
        maxWidth: "100%",
      }}
    >
      <Box style={{ marginRight: "0!important", height: "100%!important" }}>
        <Box
          style={{
            paddingRight: "0!important",
            marginbottom: "1rem!important",
            flex: "0 0 100%",
            maxWidth: "100%",
          }}
        >
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              height: "100%",
            }}
          >
              <Typography>
              {Title?Title:"Results"}
              </Typography>
            <Box
              style={{ width: "100%", height: "100%", fontSize:"14px" }}id="SuccessandFail"></Box>
              </Box>
            </Box>
          </Box>
        </Box>
    );

}