import jsPDF from "jspdf";
import { CoverPageScript, NewPage } from "./HelperDataPdf/DesignPdfs";

export const CreatePdfScript = async (Report = [], selectedTypes) => {
  var lMargin = 15; //left margin in mm
  var rMargin = 15; //right margin in mm
  var pdfInMM = 210; // width of A4 in mm

  const doc = new jsPDF();
  CoverPageScript(
    doc,
    Report.Name,
    "Custom Threat Report",
    Report.UpdateAt,
    Report.NoAgents,
    Report.NoScripts
  );
  if (selectedTypes[0]) {
    NewPage(doc); //SUMARY
    doc.setFontSize(20);
    doc.setTextColor("#214a90");
    doc.setFont("Helvetica-Bold", "normal", "bold"); //
    doc.text("SUMMARY", 80, 25);

    doc.setLineWidth(2);
    doc.setDrawColor("#0d0b1f");
    doc.roundedRect(160, 40, 40, 40, 4, 4, "S");
    doc.setFontSize(40);
    doc.setTextColor("#0d0b1f");
    doc.text("" + Report.NoScripts, 178, 60);
    doc.setFontSize(20);
    doc.text(Report.NoScripts > 1 ? "Scripts" : "Script", 170, 70);

    doc.setTextColor("#191f2c"); // Datos de Emulacion
    doc.setFontSize(16);
    doc.text("Emulation Name:", 20, 45);
    doc.text(Report.Name, 65, 45);
    doc.setFontSize(14);
    doc.text("Description:", 20, 55);
    doc.text(
      Report.Description.length > 40
        ? Report.Description.substring(0, 40) + "..."
        : Report.Description,
      50,
      55
    );
    doc.text("Execute Date:", 20, 65);
    doc.text(Report.UpdateAt, 50, 65);
    doc.text("Execute By:", 20, 75);
    doc.text(Report.ExecuteBy, 50, 75);

    doc.setFontSize(20);
    doc.setTextColor("#214a90");
    doc.text("Custom Threats File Results Summary", 40, 100);

    doc.setFillColor("#66638f"); //azul
    doc.setLineWidth(3);
    doc.circle(40, 140, 22, "F");
    doc.setDrawColor("#021f58"); //azul
    doc.circle(40, 140, 22, "S");
    doc.setTextColor("#fff");
    doc.setFontSize(50);
    doc.text("" + Report.NoAgents, 35, 140);
    doc.setFontSize(20);
    doc.text("Endpoints", 25, 150);

    doc.setFillColor("#84f8a1"); //verde
    doc.circle(105, 140, 22, "F");
    doc.setDrawColor("#00c732"); //verde
    doc.circle(105, 140, 22, "S");
    doc.setFontSize(50);
    doc.text("" + Report.Success, 100, 140);
    doc.setFontSize(20);
    doc.text("Success", 95, 150);

    doc.setFillColor("#e05353"); //rojo
    doc.circle(175, 140, 22, "F");
    doc.setDrawColor("#d32f2f"); //rojo
    doc.circle(175, 140, 22, "S");
    doc.setFontSize(50);
    doc.text("" + Report.Fail, 170, 140);
    doc.setFontSize(20);
    doc.text("Failed", 165, 150);

    let cien = Report.Success + Report.Fail;
    let value = (2 * Math.PI * Report.Success) / cien;
    let porcentSuccess = Math.round((Report.Success * 100) / cien);
    let porcentFaild = Math.round(100 - porcentSuccess);

    doc.setFontSize(20);
    doc.setTextColor("#214a90");
    doc.text("Effectiveness", 80, 190);

    doc.setTextColor("#191f2c");
    doc.setFillColor("#00ff00");
    doc.roundedRect(120, 220, 15, 15, 4, 4, "F");
    doc.setFontSize(14);
    doc.text("Success : " + porcentSuccess + "%", 140, 230);
    doc.setFillColor("#ff4747");
    doc.roundedRect(120, 240, 15, 15, 4, 4, "F");
    doc.text("Failed : " + porcentFaild + "%", 140, 250);

    doc.setLineWidth(25);
    doc.setDrawColor("#00ff00"); //verde success
    doc.circle(70, 235, 20, "S");

    var context = doc.context2d;
    context.lineWidth = 25;
    if (porcentFaild > 0) {
      context.beginPath();
      context.arc(70, 235, 20, 0, value, true);
      context.strokeStyle = "#ff4747";
      context.stroke();
    }
  }
  if (selectedTypes[1]) {
    NewPage(doc); // Table of Scripts

    doc.setFontSize(20);
    doc.setTextColor("#214a90");
    doc.setFont("Helvetica-Bold", "normal", "bold"); //
    doc.text("SCRIPTS TABLE", 80, 25);
    let scripts = [];
    Report.Scripts.forEach((element) => {
      scripts.push([
        element.name,
        element.language,
        element.platform,
        element.source,
        element.tagTest,
      ]);
    });
    doc.autoTable({
      startY: 40,
      columnStyles: { 0: { cellWidth: 100 } },
      headStyles: { fillColor: [30, 28, 58] },
      head: [["Name", "Language", "Platform", "Source", "Tag Test"]],
      body: scripts,
    });
  }
  if (selectedTypes[2]) {
    NewPage(doc); // Table of Scripts

    doc.setFontSize(20);
    doc.setTextColor("#214a90");
    doc.setFont("Helvetica-Bold", "normal", "bold"); //
    doc.text("ENDPOINTS SCRIPTS EMULATION", 40, 25);
    let Emualtion = [];
    Report.TableResults.forEach((host) => {
      host.scripts.forEach((element) => {
        Emualtion.push([
          host.hostname,
          element.name,
          element.endDate,
          element.status,
          element.errors === "" ? "-" : "Y",
          element.isLogs,
        ]);
      });
    });
    doc.autoTable({
      startY: 40,
      headStyles: { fillColor: [30, 28, 58] },
      head: [["Hostname", "Name", "End DAte", "Status", "Error", "Logs"]],
      body: Emualtion,
    });
  }
  if (selectedTypes[3]) {
    doc.setTextColor("#0d0b1f");
    Report.Previews.forEach((element) => {
      NewPage(doc);
      doc.setFontSize(20);
      doc.setTextColor("#214a90");
      doc.setFont("Helvetica-Bold", "normal", "bold"); //
      doc.text("PREVIEW", 80, 25);
      doc.setTextColor("#0d0b1f");
      doc.setFontSize(10);
      doc.setFont("Helvetica", "normal", 100);
      doc.text(
        "* This is a preview of the result for all log please donwload in the site",
        5,
        295
      );
      var paragraph3 = element;

      var textLines = doc.splitTextToSize(
        paragraph3,
        pdfInMM - lMargin - rMargin
      ); // Split the text into lines
      const pageHeight = doc.internal.pageSize.height - 10; // Get page height, we'll use this for auto-paging. TRANSLATE this line if using units other than `pt`
      let cursorY = 45;
      textLines.forEach((lineText) => {
        if (lineText === undefined || lineText === null || lineText === "undefined" || lineText === "null") {
          doc.setFontSize(10);
          doc.setFont("Helvetica", "normal", 100);
          doc.text("This consult return empty data", 50, 60);
        } else {
          if (cursorY > pageHeight) {
            // Auto-paging
            NewPage(doc);
            doc.setFontSize(10);
            doc.setFont("Helvetica", "normal", 100);
            doc.setTextColor("#0d0b1f");
            cursorY = 25;
          }
          doc.setTextColor("#0d0b1f");
          doc.text(lineText, 10, cursorY);
          doc.text(
            "* This is a preview of the result for all log please donwload in the site",
            5,
            295
          );
          cursorY += 7;
        }
      });
    });
  }
  doc.save(Report.Name.toString() + "_Report"); //opens the data uri in new window
};
