export const ListIOCs = [
    {
      id: 1, name: "Process creation"
    }, 
    {
      id: 2, name: "A process changed a file creation time"
    }, 
    {
      id: 3, name: "Network connection"
    }, 
    {
      id: 4, name: "Disable firewall"
    }, 
    {
      id: 5, name: "Process terminated"
    }, 
    {
      id: 6, name: "Driver loaded"
    }, 
    {
      id: 7, name: "Image loaded"
    }, 
    {
      id: 8, name: "Create Remote Thread"
    }, 
    {
      id: 9, name: "Raw Access Read"
    }, 
    {
      id: 10, name: "Process Access"
    }, 
    {
      id: 11, name: "File Create"
    }, 
    {
      id: 12, name: "RegistryEvent - Object create and delete"
    }, 
    {
      id: 13, name: "RegistryEvent - Value Set"
    }, 
    {
      id: 14, name: "RegistryEvent - Key and Value Rename"
    }, 
    {
      id: 15, name: "File Create StreamHash"
    }, 
    {
      id: 16, name: "Service Configuration Change"
    }, 
    {
      id: 17, name: "PipeEvent - Pipe Created"
    }, 
    {
      id: 18, name: "PipeEvent - Pipe Connected"
    }, 
    {
      id: 19, name: "WmiEvent - WmiEventFilter activity detected"
    }, 
    {
      id: 20, name: "WmiEvent - WmiEventConsumer activity detected"
    }, 
    {
      id: 21, name: "WmiEvent - WmiEventConsumerToFilter activity detected"
    }, 
    {
      id: 22, name: "DNSEvent - DNS query"
    }, 
    {
      id: 23, name: "File Deletion Event"
    },
    {
      id: 24, name: "ClipboardChange - New content in the clipboard"
    }, 
    {
      id: 25, name: "ProcessTampering - Process image change"
    }, 
    {
      id: 26, name: "FileDeleteDetected - File Delete logged"
    }
  ];
  