import { env } from "../env";

export const env2 = {
  api:
    document.location.protocol === "https:"
      ? env.REACT_APP_PROTOCOL_HTTPS +
        env.REACT_APP_INSTANCE +
        env.REACT_APP_PORT_HTTPS_API
      : env.REACT_APP_PROTOCOL_HTTP +
        env.REACT_APP_INSTANCE +
        env.REACT_APP_PORT_HTTP_API,

  socketio:
    document.location.protocol === "https:"
      ? env.REACT_APP_PROTOCOL_WEBSOCKET_WSS +
        env.REACT_APP_INSTANCE +
        env.REACT_APP_PORT_WSS
      : env.REACT_APP_PROTOCOL_WEBSOCKET_WS +
        env.REACT_APP_INSTANCE +
        env.REACT_APP_PORT_WS,
  health: env.REACT_APP_HEALTH_URL,
  support: env.REACT_APP_SUPPORT_EVE_URL,
};
