export const defaultData = [
  {
    label: "Resume",
    checked: true,
  },
  {
    label: "FileLevelResults",
    checked: true,
  },
  {
    label: "EndPoints",
    checked: false,
  },
  {
    label: "Packages",
    checked: false,
  },
  {
    label: "ListofArtifacts",
    checked: false,
  },
  {
    label: "ArtifactsDetails",
    checked: false,
  },
  {
    label: "Interpretation",
    checked: false,
  },
  {
    label: "MITREDescription",
    checked: false,
  },
  {
    label: "AttackLifeCycle",
    checked: false,
  },
  {
    label: "Actions",
    checked: false,
  },
  {
    label: "Referents",
    checked: false,
  },
];
export const excelExecutiveData = [
  {
    label: "Resume",
    checked: true,
  },
  {
    label: "FileLevelResults",
    checked: true,
  },
  {
    label: "EndPoints",
    checked: false,
  },
  {
    label: "Packages",
    checked: false,
  },
  {
    label: "ListofArtifacts",
    checked: false,
  },
  {
    label: "ArtifactsDetails",
    checked: false,
  },
  {
    label: "Interpretation",
    checked: false,
  },
  {
    label: "MITREDescription",
    checked: false,
  },
  {
    label: "AttackLifeCycle",
    checked: false,
  },
  {
    label: "Actions",
    checked: false,
  },
  {
    label: "Referents",
    checked: false,
  },
];
export const excelDetailData = [
  {
    label: "Resume",
    checked: true,
  },
  {
    label: "FileLevelResults",
    checked: true,
  },
  {
    label: "EndPoints",
    checked: false,
  },
  {
    label: "Packages",
    checked: false,
  },
  {
    label: "ListofArtifacts",
    checked: false,
  },
  {
    label: "ArtifactsDetails",
    checked: false,
  },
  {
    label: "Interpretation",
    checked: false,
  },
  {
    label: "MITREDescription",
    checked: false,
  },
  {
    label: "AttackLifeCycle",
    checked: false,
  },
  {
    label: "Actions",
    checked: true,
  },
  {
    label: "Referents",
    checked: false,
  },
];
export const pdfExecutiveData = [
  {
    label: "Resume",
    checked: true,
  },
  {
    label: "FileLevelResults",
    checked: true,
  },
  {
    label: "EndPoints",
    checked: false,
  },
  {
    label: "Packages",
    checked: false,
  },
  {
    label: "ListofArtifacts",
    checked: true,
  },
  {
    label: "ArtifactsDetails",
    checked: false,
  },
  {
    label: "Interpretation",
    checked: false,
  },
  {
    label: "MITREDescription",
    checked: false,
  },
  {
    label: "AttackLifeCycle",
    checked: true,
  },
  {
    label: "Actions",
    checked: false,
  },
  {
    label: "Referents",
    checked: false,
  },
];
export const pdfDetailData = [
  {
    label: "Resume",
    checked: true,
  },
  {
    label: "FileLevelResults",
    checked: true,
  },
  {
    label: "EndPoints",
    checked: true,
  },
  {
    label: "Packages",
    checked: true,
  },
  {
    label: "ListofArtifacts",
    checked: true,
  },
  {
    label: "ArtifactsDetails",
    checked: true,
  },
  {
    label: "Interpretation",
    checked: true,
  },
  {
    label: "MITREDescription",
    checked: true,
  },
  {
    label: "AttackLifeCycle",
    checked: true,
  },
  {
    label: "Actions",
    checked: true,
  },
  {
    label: "Referents",
    checked: false,
  },
];
