/* eslint-disable no-undef */

export default function GaugeCounterSuccessAndFail(success, fail, total,totalendpoints) {
  am4core.ready(function () {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    /**
     * Source data
     */
    var data = [];
    if (success >= fail) {
      data = [
        {
          category: "Success",
          value: success,
          color: am4core.color("#68ad5c"),
          breakdown: [
            {
              category: "Success",
              value: success,
              color: am4core.color("#68ad5c"),
            },
            {
              category: "Fail",
              value: fail,
              color: am4core.color("#5f0000"),
            },
            {
              category: "Total Emulation Scripts",
              value: total,
              color: am4core.color("#750e49"),
            },
            {
              category: "Endpoints",
              value: totalendpoints,
              color: am4core.color("#0073FF"),
            },
          ],
        },
        {
          category: "Fail",
          value: fail,
          color: am4core.color("#5f0000"),
          breakdown: [
            {
              category: "Success",
              value: success,
              color: am4core.color("#68ad5c"),
            },
            {
              category: "Fail",
              value: fail,
              color: am4core.color("#5f0000"),
            },
            {
              category: "Total Emulation Scripts",
              value: total,
              color: am4core.color("#750e49"),
            },
            {
              category: "Endpoints",
              value: totalendpoints,
              color: am4core.color("#0073FF"),
            },
          ],
        },
      ];
    } else {
      data = [ 
        {
          category: "Fail",
          value: fail,
          color: am4core.color("#5f0000"),
          breakdown: [
            {
              category: "Success",
              value: success,
              color: am4core.color("#68ad5c"),
            },
            {
              category: "Fail",
              value: fail,
              color: am4core.color("#5f0000"),
            },
            {
              category: "Total Emulation Scripts",
              value: total,
              color: am4core.color("#750e49"),
            },
            {
              category: "Endpoints",
              value: totalendpoints,
              color: am4core.color("#0073FF"),
            },
          ],
        },
        {
          category: "Success",
          value: success,
          color: am4core.color("#68ad5c"),
          breakdown: [
            {
              category: "Success",
              value: success,
              color: am4core.color("#68ad5c"),
            },
            {
              category: "Fail",
              value: fail,
              color: am4core.color("#5f0000"),
            },
            {
              category: "Total Emulation Scripts",
              value: total,
              color: am4core.color("#750e49"),
            },
            {
              category: "Endpoints",
              value: totalendpoints,
              color: am4core.color("#0073FF"),
            },
          ],
        },       
      ];
    }
    

    /**
     * Chart container
     */

    // Create chart instance
    var chart = am4core.create("GaugeCounterSuccessAndFail", am4core.Container);
    chart.logo.height = -15000;
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);
    chart.layout = "horizontal";

    /**
     * Column chart
     */

    // Create chart instance
    var columnChart = chart.createChild(am4charts.XYChart);

    // Create axes
    var categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    var valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = total + 6;
    valueAxis.strictMinMax = true; 

    // Create series
    var columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = "value";
    columnSeries.dataFields.categoryY = "category";
    columnSeries.columns.template.strokeWidth = 0;
    // columnSeries.columns.template.fill = am4core.color("#5f0000")
    columnSeries.columns.template.propertyFields.fill = "color";
 
    /**
     * Pie chart
     */

    // Create chart instance
    var pieChart = chart.createChild(am4charts.PieChart);
    pieChart.data = data;
    pieChart.innerRadius = am4core.percent(50);

    // Add and configure Series
    var pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.labels.template.disabled = true;

    // Set up labels
    var label1 = pieChart.seriesContainer.createChild(am4core.Label);
    label1.text = "";
    label1.horizontalCenter = "middle";
    label1.fontSize = 25;
    label1.fontWeight = 600;
    label1.dy = -30;

    var label2 = pieChart.seriesContainer.createChild(am4core.Label);
    label2.text = "";
    label2.horizontalCenter = "middle";
    label2.fontSize = 12;
    label2.dy = 20;

    // Auto-select first slice on load
    pieChart.events.on("ready", function (ev) {
      pieSeries.slices.getIndex(0).isActive = true;
    });

    // Set up toggling events
    pieSeries.slices.template.events.on("toggled", function (ev) {
      if (ev.target.isActive) {
        // Untoggle other slices
        pieSeries.slices.each(function (slice) {
          if (slice !== ev.target) {
            slice.isActive = false;
          }
        });

        // Update column chart
        columnSeries.appeared = false;
        columnChart.data = ev.target.dataItem.dataContext.breakdown;
        columnSeries.fill = ev.target.fill;
        columnSeries.reinit();

        // Update labels
        label1.text = 

        pieChart.numberFormatter.format(          
          ev.target.dataItem.values.value.percent,
          "#. '%'"
        );
        label1.fill = ev.target.fill;
        label2.text = ev.target.dataItem.category;
      }
    });
  }); // end am4core.ready()
}
