import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { myColor } from "../../../BaseCss";
import { Box } from "@mui/material";
import dashtable from "../../../assets/images/dashtable-arrow-svg.svg";
import { PrimaryButton } from "../../../Helpers/Themes";
import "./OnlineAgents.scss";


export default function OnlineAgents(props) {
  const { DataAgentsConnects } = props;
  const [rows, setrows] = useState(DataAgentsConnects);

  useEffect(() => {
    setrows(DataAgentsConnects);
  }, [DataAgentsConnects]);

   const GotoAgents = () => {
    props.Redirect();
  };
  return (
    <div className="dash-cmn-box">
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "10px",
          borderRadius: "12px 12px 0px 0px",
          backgroundColor: "#090f1a",
        }}
      >
        <h2 className="dashbord_main_title d-inline-block">Online Endpoints</h2>

        <PrimaryButton style={{ marginLeft: "auto" }} onClick={GotoAgents}>
          <img src={dashtable} className="mr-2" alt="" />
          View all Endpoints
        </PrimaryButton>
      </div>

      <div className="tableContent">
        <Table>
          <TableHead
            style={{ backgroundColor: "#0d0b21", borderRadius: "12px" }}
          >
            <TableRow>
              <TableCell style={{ borderBottom: "1px solid #1e1c3a" }}>
                <Typography style={{ color: myColor.VeryLight }}>
                  Host
                </Typography>
              </TableCell>
              <TableCell style={{ borderBottom: "1px solid #1e1c3a" }}>
                <Typography style={{ color: myColor.VeryLight }}>
                  IP Address
                </Typography>
              </TableCell>

              <TableCell style={{ borderBottom: "1px solid #1e1c3a" }}>
                <Typography style={{ color: myColor.VeryLight }}>
                  Port
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid #1e1c3a",
                }}
              >
                <Typography style={{ color: myColor.VeryLight }}>
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0
              ? rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      style={{
                        width: "50%",
                        color: "#66638f",
                        borderBottom: "1px solid #1e1c3a",
                      }}
                    >
                      {row.hostname}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "50%",

                        color: "#66638f",
                        borderBottom: "1px solid #1e1c3a",
                      }}
                    >
                      {row.private}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "50%",
                        color: "#66638f",
                        borderBottom: "1px solid #1e1c3a",
                      }}
                    >
                      {row.port}
                    </TableCell>

                    <TableCell
                      style={{
                        width: "50%",
                        margin: "auto",
                        color: "#66638f",
                        borderBottom: "1px solid #1e1c3a",
                      }}
                    >
                      <Box style={{ display: "inline-block" }}>
                        {row.online !== null ? (
                          <Box
                            style={{
                              backgroundColor: "#182830",
                              borderRadius: "14px",
                              fontWeight: "600",
                              fontSize: "13px",
                              lineHeight: "22px",
                              color: "#38e25d",
                              textAlign: "center",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "14px 10px",
                              width: "125px",
                            }}
                          >
                            <img alt="" style={{ marginRight: ".5rem" }} />
                            Connected
                          </Box>
                        ) : (
                          <Box
                            style={{
                              width: "200px",
                              backgroundColor: "rgba(255, 71, 71, 0.2)",
                              borderRadius: "14px",
                              fontWeight: "600",
                              fontSize: "13px",
                              lineHeight: "22px",
                              color: "#ff4747",
                              textAlign: "center",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "14px 10px",
                            }}
                          >
                            <img alt="" style={{ marginRight: ".5rem" }} />
                            Not Connected
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
