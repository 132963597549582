import { createTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import "../../BaseCss";
import { myColor, myFontFamily } from '../../BaseCss';

export const colorTheme = {
    backgroundColor: "#0d0b21",
  };

export const packageTheme = createTheme({
  typography: {
    pageTitle: {
      fontSize: "22px",
      color: "#fff",
      fontWeight: "600",
      marginBottom: 0,
      fontFamily: myFontFamily.PrimaryFont,
      letterSpacing:"1px",
      textShadow: "1px 1px #000",
    },
    tableTitle: {
      fontfamily: "Roboto,Helvetica,Arial,sans-serif",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      color: myColor.VeryLight,
      fontFamily: myFontFamily.PrimaryFont,
    },
    footTable: {
      fontSize: "14px",
      color: myColor.PrimaryFont,
      fontFamily: myFontFamily.PrimaryFont,
      marginLeft:"7px",
    },
    secundaryText: {
      fontSize: "12px",
      fontStyle: "italic",
      color: myColor.PrimaryFont,
      fontFamily: myFontFamily.PrimaryFont,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        h6 {
          font-size: 22px;
        }
      `,
    },
    MuiTableHead: {
        styleOverrides: {
            root: {
                backgroundColor: colorTheme.backgroundColor,
                borderRadius: "12px",
            }
        }
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
              fontSize: '14px',
              borderBottom:"1px solid #1e1c3a",
              color: "#66638f",
            },
          },  
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
              color: 'white',
            },
            outlined :{
              marginLeft: "8px",
              backgroundColor: "#e328af",
              boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
              borderRadius: "12px",
              fontWeight: "600",
              fontSize: "16px",
              textAlign: "center",
              color: "#ffffff!",
              outline: "none",
              display: "inline-block",
              padding: "10px 30px 12px 30px",
              border: "1px solid #e328af",
            }
          }, 
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                backgroundColor: "#c59d48",
                border: "1px solid #1e1c3",
                borderRadius: "8px",
                height: "38px",
                fontSize: "12px",
                fontStyle: "italic",
                color: "white",
                width: "100%",
                minWidth: "100px",
                padding: "3px 15px",
            },
        }
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                backgroundColor: "#c59d48",
                border: "1px solid #1e1c3a",
                boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                borderRadius: "12px",
              }
        }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: myColor.PrimaryFont,
          //backgroundColor: "#6c757d",
          borderColor: "#6c757d",
          textAlign: "center",
          padding: ".375rem .75rem",
          borderRadius: ".25rem",
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: myColor.Icon,
          },
        },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: myColor.BgSecundary,
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "white"
        }
      }
    }
    
  },
});

export const PrimaryButton = styled(Button)({
    margin: "8px",
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '10px 30px',
    borderRadius: "12px",
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: myColor.PrimaryButton,
    color: myColor.PrimaryFont,
    borderColor: myColor.PrimaryButton,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: myColor.PrimaryButton,
      borderColor: myColor.Icon,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: myColor.PrimaryButton,
      borderColor: myColor.PrimaryButton,
    },
    '&:focus': {
      boxShadow: myColor.Icon,
    },
});

export const ArrowButton = styled(Button)({
  color: myColor.PrimaryFont, 
  backgroundColor: myColor.BgSecundary,
  float: "right",
  marginTop: "12px",
  height: "38px",
  borderRadius: "15px",
  '&:hover': {
    backgroundColor: myColor.PrimaryButton,
    borderColor: myColor.Icon,
  }
});

export const PageButton = styled(Button)({
  color: myColor.PrimaryFont,
  borderRadius: "15px",
  height: "38px",
  backgroundColor: myColor.PrimaryButton,
  float: "right",
  marginTop: "12px",
});