import React from 'react';
import { Box, Typography, Select, MenuItem} from "@mui/material";
import FlowButtons from "./FlowButtons";
import "../../Packages.scss";

class TypeForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
        };     
    }

    handleChange =(typeName, typeId)=> {
        this.props.handleChange(typeName, typeId);
    }

    render(){
        return (
            <Box>
                <Box className="rt-SelectContent">
                    <Typography className="TexTTitles">{this.props.title}</Typography>
                    <Select
                    sx={{
                        //"& .MuiSelect-select": {backgroundColor:"red"},
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                        className="SelectInput"
                        value={this.props.valueInput}
                        onChange={(e, index) => this.handleChange(index.props.children, index.props.value)}>
                        {this.props.packageTypes.map((type) => (
                            <MenuItem key= {type.id} value= {type.id} className="SelectItem">
                                 {type.name==="Interactiva"?"Interactive":type.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <FlowButtons
                    disableNext= {this.props.valueInput === ""}
                    labelNext= "Next" 
                    handleNext= {this.props.handleNext} 
                    handleBack={this.props.handleBack}>
                </FlowButtons>
            </Box>
        )
    }
}

export default TypeForm;