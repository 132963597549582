import React from "react";
import "./Top5MostThreatenedAgents.scss";

import { Box } from "@mui/material";
import reximg from "../../../assets/images/comingdata.gif";
export default function Top5MostThreatenedAgents(props) {
  const { DataTop5MostThreatenedAgents } = props;

  return (
    <div className="dash-cmn-box">
      <h2
        style={{
          padding: "10px",
          borderRadius: "12px 12px 0px 0px",
          backgroundColor: "#090f1a",
        }}
        className="dashbord_main_title"
      > Most Vulnerable Endpoints  </h2>

      <div
        style={{
          margin: "0px",
          width: "100%",
          height: "550px",
          fontSize: "14px",
        }}
      >
        {DataTop5MostThreatenedAgents ? (
          <>
            <div id="threatenedAgentsChart"></div>
          </>
        ) : (
          <Box style={{ textAlign: "center" }}>
            <img style={{ width: "50%" }} src={reximg} alt="" />
          </Box>
        )}
      </div>
    </div>
  );
}
