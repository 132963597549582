import Perl from "./perl.png";
import Bash from "./bash.png";
import Power from "./powershell.png";
import Python from "./python.png";
import Shell from "./shell.png";
import Ruby from "./ruby.png";
import { Avatar, Chip, Tooltip } from "@mui/material";
const style = {
  width: "30px",
};
const styleChip = {
  minWidth: "120px",
};
const styleAvatar={
    borderRadius:"0px",
    backgroundColor:"transparent",
}
export const IconsLanguages = {
  perl: {
    icon: (
      <Tooltip placement="right" title="Perl">
        <img src={Perl} alt="icon-pearl" style={style} />
      </Tooltip>
    ),
    chip: (
      <Chip
        avatar={<Avatar alt="Perl" src={Perl} style={styleAvatar}/>}
        label="Perl"
        variant="outlined"
        color="secondary"
        style={styleChip}
      />
    ),
  },
  bash: {
    icon: (
      <Tooltip placement="right" title="Batch">
        <img src={Bash} alt="icon-bash" style={style} />
      </Tooltip>
    ),
    chip: (
      <Chip
        avatar={<Avatar alt="Batch" src={Bash} style={styleAvatar}/>}
        label="Batch"
        variant="outlined"
        color="success"
        style={styleChip}
      />
    ),
  },
  powershell: {
    icon: (
      <Tooltip placement="right" title="Power Shell">
        <img src={Power} alt="icon-powershell" style={style} />
      </Tooltip>
    ),
    chip: (
      <Chip
        avatar={<Avatar alt="Power Shell" src={Power} style={styleAvatar} />}
        label="Power Shell"
        variant="outlined"
        color="info"
        style={styleChip}
      />
    ),
  },
  python: {
    icon: (
      <Tooltip placement="right" title="Python">
        <img src={Python} alt="icon-python" style={style} />
      </Tooltip>
    ),
    chip: (
      <Chip
        avatar={<Avatar alt="Python" src={Python} style={styleAvatar}/>}
        label="Python"
        variant="outlined"
        color="warning"
        style={styleChip}
      />
    ),
  },
  shell: {
    icon: (
      <Tooltip placement="right" title="Shell">
        <img src={Shell} alt="icon-shell" style={style} />
      </Tooltip>
    ),
    chip: (
      <Chip
        avatar={<Avatar alt="Shell" src={Shell} style={styleAvatar}/>}
        label="Shell"
        variant="outlined"
        color="default"
        style={styleChip}
      />
    ),
  },
  ruby: {
    icon: (
      <Tooltip placement="right" title="Ruby">
        <img src={Ruby} alt="icon-ruby" style={style} />
      </Tooltip>
    ),
    chip: (
      <Chip
        avatar={<Avatar alt="Ruby" src={Ruby} style={{borderRadius:"0px"}}/>}
        label="Ruby"
        variant="outlined"
        color="error"
        style={styleChip}
      />
    ),
  },
};
