/* eslint-disable no-undef */


export default function TimeLineOfExecutionsScript(data,disposed){
    am4core.ready(function () {
        // Themes begin
        am4core.useTheme(am4themes_dark);
        am4core.useTheme(am4themes_animated);
    var chart = am4core.create("timelineChartdiv", am4plugins_timeline.SerpentineChart);
    chart.levelCount = 5;
    chart.orientation = "vertical"
    chart.logo.height = -15000;
    am4core.options.autoDispose = true;
   
    chart.curveContainer.padding(20, 20, 20, 20);
   
    chart.data = data;
   
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
   
    dateAxis.renderer.line.disabled = true;
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.minZoomCount = 5;
   
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.innerRadius = -50;
    valueAxis.renderer.radius = -50;
    chart.seriesContainer.zIndex = -1;
    valueAxis.fillOpacity = 0;

   
    var series = chart.series.push(new am4plugins_timeline.CurveStepLineSeries());
    series.fillOpacity = 0.3;
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "total";
    series.tooltipText = "{htmlValue}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.background.fillOpacity = 0.7;
    series.fill = chart.colors.getIndex(3);
    series.stroke =  "#c59d48";
    series.strokeWidth = 2;
   
    chart.cursor = new am4plugins_timeline.CurveCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.yAxis = valueAxis;
    chart.cursor.lineY.disabled = true;
    if(disposed === true)
  {chart.dispose();}
  }); // end am4core.ready()

}
 