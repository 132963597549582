import { Box, Typography } from "@mui/material";

import SucessIcon from "../../../../assets/images/success-table-icon.svg";
import FailIcon from "../../../../assets/images/fail-table-icon.svg";

import ArrowLifeIconFail from "../../../../assets/images/red-arrow.svg";
import ArrowLifeIconSuccess from "../../../../assets/images/green-arrow.svg";

export default function Cyclelife(props) {
  const { MtreLong, MitreArray } = props;
  return (
    <Box>
      <Box
        style={{
          backgroundColor: "#090f1a",
          border: "1px solid #1e1c3a",
          padding: "15px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
          borderRadius: "12px",
          fontWeight: "600",
          fontSize: "18px",
          color: "#ffffff",
        }}
      >
        <Typography
          style={{
            fontWeight: "600",
            fontSize: "18px",
            color: "#ffffff",
          }}
        >
          Attack Life Cycle
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginRight: "-15px",
          marginLeft: "-15px",
        }}
      >
        <Box
          id="life-cycle"
          style={{
            marginBottom: "1.5rem !important",
            flex: "0 0 100%",
            maxWidth: "100%",
            backgroundColor:"#0a0818"
          }}
        >
          <Box>
            <ul className="life-cycle-box-main">
              <li
                className={
                  MtreLong > 0
                    ? "green-bg green-bg"
                    : "green-bg green-bg"
                }
                style={{
                  filter: MtreLong > 0 ? "none" : "blur(3px)",
                }}
              >
                <Box
                  className={
                    MtreLong > 0
                      ? "bdr-row bdr-row-color-1"
                      : "bdr-row bdr-row-color-1"
                  }
                >
                  <Box className="life-cycle-box green-box">
                    <Box className="box-title green-title">
                      <span>Initial Recon</span>
                    </Box>
                    <Box className="life-cycle-list">
                      <ul>
                        <li>Identify</li>
                        <li>Exploitable</li>
                        <li>Vulnerabilities</li>
                      </ul>
                    </Box>
                    {MtreLong > 0 &&
                      MitreArray.InitialRecon !== 0 ? (
                      <>
                        {MitreArray.InitialRecon === 1 ? (
                          <img
                            style={{ display: "flex" }}
                            alt=""
                            src={SucessIcon}
                          />
                        ) : (
                          <img
                            style={{
                              float: "right",
                              marginTop: "15px",
                            }}
                            alt=""
                            src={FailIcon}
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              </li>
              <li
                className={
                  MtreLong > 1
                    ? "green-bg red-bg"
                    : "green-bg red-bg"
                }
                style={{
                  filter: MtreLong > 1 ? "none" : "blur(3px)",
                }}
              >
                <Box
                  className={
                    MtreLong > 1
                      ? "bdr-row bdr-row-color-2"
                      : "bdr-row bdr-row-color-2"
                  }
                >
                  <Box className="life-cycle-box red-box">
                    <Box className="box-title red-title">
                      <span>Compromise</span>
                    </Box>
                    <Box className="life-cycle-list">
                      <ul>
                        <li>Gain Initial Access</li>
                        <li>Into Target</li>
                      </ul>
                    </Box>

                    {MtreLong > 1 &&
                      MitreArray.InitialCompromise !== 0 ? (
                      <>
                        {MitreArray.InitialCompromise === 1 ? (
                          <img
                            style={{ display: "flex" }}
                            alt=""
                            src={SucessIcon}
                          />
                        ) : (
                          <img
                            style={{
                              float: "right",
                              marginTop: "15px",
                            }}
                            alt=""
                            src={FailIcon}
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              </li>
              <li
                className={
                  MtreLong > 2
                    ? "green-bg blue-bg"
                    : "green-bg blue-bg"
                }
                style={{
                  filter: MtreLong > 2 ? "none" : "blur(3px)",
                }}
              >
                <Box
                  className={
                    MtreLong > 2
                      ? "bdr-row bdr-row-color-3"
                      : "bdr-row bdr-row-color-3"
                  }
                >
                  <Box className="life-cycle-box blue-box">
                    <Box className="box-title blue-title">
                      <span>Establish Foothold</span>
                    </Box>
                    <Box className="life-cycle-list">
                      <ul>
                        <li>Strengthen</li>
                        <li>Position Within Target</li>
                      </ul>
                    </Box>
                    {MtreLong > 2 &&
                      MitreArray.EstabishFoothold !== 0 ? (
                      <>
                        {MitreArray.EstabishFoothold === 1 ? (
                          <img
                            style={{ display: "flex" }}
                            alt=""
                            src={SucessIcon}
                          />
                        ) : (
                          <img
                            style={{
                              float: "right",
                              marginTop: "15px",
                            }}
                            alt=""
                            src={FailIcon}
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              </li>
              <li
                className={
                  MtreLong > 3
                    ? "green-bg yellow-bg position-relative"
                    : "green-bg yellow-bg position-relative"
                }
                style={{
                  filter: MtreLong > 3 ? "none" : "blur(3px)",
                }}
              >
                <Box className="top-line-life-cycle">
                  <img
                    src={
                      MitreArray.EscaletePrivileges === 1 &&
                        MitreArray.InternalRecon === 1
                        ? ArrowLifeIconSuccess
                        : ArrowLifeIconFail
                    }
                    alt=""
                  />
                </Box>
                <Box className="box-bubble sb1">
                  Maintain Presence
                </Box>
                <Box
                  className={
                    MtreLong > 3
                      ? "bdr-row bdr-row-color-4"
                      : "bdr-row bdr-row-color-4"
                  }
                >
                  <Box className="life-cycle-box yellow-box">
                    <Box className="box-title yellow-title">
                      <span>Escalate Privileges</span>
                    </Box>
                    <Box className="life-cycle-list">
                      <ul>
                        <li>Steal Valid User Credentials</li>
                        <li>Defense Evation</li>
                        <li>Command &amp; Control</li>
                      </ul>
                    </Box>
                    {MtreLong > 3 &&
                      MitreArray.EscaletePrivileges !== 0 ? (
                      <>
                        {MitreArray.EscaletePrivileges === 1 ? (
                          <img
                            style={{ display: "flex" }}
                            alt=""
                            src={SucessIcon}
                          />
                        ) : (
                          <img
                            style={{
                              float: "right",
                              marginTop: "15px",
                            }}
                            alt=""
                            src={FailIcon}
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              </li>

              <li
                className={
                  MtreLong > 4
                    ? "green-bg purple-bg position-relative"
                    : "green-bg purple-bg position-relative"
                }
                style={{
                  filter: MtreLong > 4 ? "none" : "blur(3px)",
                }}
              >
                <Box className="box-bubble sb1">
                  Lateral Movement
                </Box>
                <Box
                  className={
                    MtreLong > 4
                      ? "bdr-row bdr-row-color-5"
                      : "bdr-row bdr-row-color-5"
                  }
                >
                  <Box className="life-cycle-box purple-box">
                    <Box className="box-title purple-title">
                      <span>Internal Recon</span>
                    </Box>
                    <Box className="life-cycle-list">
                      <ul>
                        <li>Collection</li>
                        <li>Identify Target Data</li>
                      </ul>
                    </Box>
                    {MtreLong > 4 &&
                      MitreArray.InternalRecon !== 0 ? (
                      <>
                        {MitreArray.InternalRecon === 1 ? (
                          <img
                            style={{ display: "flex" }}
                            alt=""
                            src={SucessIcon}
                          />
                        ) : (
                          <img
                            style={{
                              float: "right",
                              marginTop: "15px",
                            }}
                            alt=""
                            src={FailIcon}
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              </li>
              <li
                className={
                  MtreLong > 5
                    ? "green-bg orange-bg"
                    : "green-bg orange-bg"
                }
                style={{
                  filter: MtreLong > 5 ? "none" : "blur(3px)",
                }}
              >
                <Box
                  className={
                    MtreLong > 5
                      ? "bdr-row bdr-row-color-6"
                      : "bdr-row bdr-row-color-6"
                  }
                >
                  <Box className="life-cycle-box orange-box">
                    <Box className="box-title orange-title">
                      <span>Complete Mission</span>
                    </Box>
                    <Box className="life-cycle-list">
                      <ul>
                        <li>Package &amp; Steal TargetData</li>
                        <li>Exfiltration</li>
                      </ul>
                    </Box>
                    {MtreLong > 5 &&
                      MitreArray.CompleteMission !== 0 ? (
                      <>
                        {MitreArray.CompleteMission === 1 ? (
                          <img
                            style={{ display: "flex" }}
                            alt=""
                            src={SucessIcon}
                          />
                        ) : (
                          <img
                            style={{
                              float: "right",
                              marginTop: "15px",
                            }}
                            alt=""
                            src={FailIcon}
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              </li>
            </ul>
            <Box
              className="mb-4 mt-5 life-cycle-suggest-main"
              style={{ marginTop: "30px" }}
            >
              <ul className="d-flex life-cycle-suggest">
                <li className="line-thumb">
                  <Box className="yellow-line"></Box>
                  <Box className="text-center mt-3">
                    <span>Prevention</span>
                  </Box>
                </li>
                <li className="line-thumb yellow-thumb">
                  <Box className="yellow-line green-line"></Box>
                  <Box className="text-center mt-3 green-txt-response">
                    <span>Response</span>
                  </Box>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}