import { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import GeneralModalDelete from "../../../../../../Helpers/CommonComponents/GeneralDeleteModal";
import { deleteScriptByIdAsync } from "../../../../Services/ServiceScriptPage";

export default function DeleteModalScript(props) {
  const { ScriptData, DataUser } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const DeleteFucntion = async (e) => {
    await deleteScriptByIdAsync(DataUser.token, ScriptData.id);
    props.Reload();
    setOpen(false);
  };

  return (
    <Box
      style={{ display: "inline-block",  }}
    >
      <Tooltip title="Delete">
        <IconButton className="iconButtonSize">
          <Delete
            color="inherit"
            onClick={handleOpen}
            style={{ cursor: "pointer" }}
          />
        </IconButton>
      </Tooltip>
      <GeneralModalDelete
        HeaderText="Do you want to delete this script?"
        BodyText=""
        ExternalOpen={open}
        CloseAction={handleClose}
        CancelAction={handleClose}
        PrimaryAction={DeleteFucntion}
      />
    </Box>
  );
}
