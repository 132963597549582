export const myColor = {
  VeryDark: "#280c27",
  Dark: "#5e1d5b",
  Normal: "#872A83",
  Light: "#C184BE",
  VeryLight: "#E6CDE5",
  Info: "#368DEF",
  Error: "#EF3636",
  Success: "#00B04A",
  BgGeneral: "#11172E",
  BgSecundary: "#2e343d",
  PaperColor: "#21273F",
  BorderSecundary: "#090f1a",
  Icon: "#c59d48",
  PrimaryFont: "#ffff",
  PrimaryButton: "#c59d48",
  SecondaryButton:"#2e343d",
  Critical: "#6a0000",
  High: "#ff0000",
  Midd: "#ff5830",
  Low: "#ffffa2",
  None: "#c039ff",
  Disable: "#6E6E6E",
  white: "#ffff",
  gold:"#c59d48",
  goldsecondary:"#544628"
  };

  export const myFontFamily = {
    PrimaryFont: "Roboto",
  }