
import {
    DesktopAccessDisabledTwoTone,
    HistoryEduTwoTone,
    Inventory2TwoTone,
    PestControlTwoTone,
} from "@mui/icons-material";

const styleIcon = { fontSize: "160px" }

export const WarningImage = {
    1: <HistoryEduTwoTone className="Icon" style={styleIcon} />,
    2: (
        <DesktopAccessDisabledTwoTone
            className="Icon"
            style={styleIcon}
        />
    ),
    3: <Inventory2TwoTone className="Icon" style={styleIcon} />,
    4: <PestControlTwoTone className="Icon" style={styleIcon} />,
};
export const WarningText = {
    1: "Sorry, but this report doesn't contain history emulation results.",
    2: "Sorry, this report cannot display because the Endpoint Is not reachable, or it was turned off during the test.",
    3: "Sorry, this report does not contain information about their one or many package processing.",
    4: "Sorry, this report does not contain information about their one or many artifacts processing.",
};
export const MitreDataInitial = {
    InitialRecon: 0,
    InitialCompromise: 0,
    EstabishFoothold: 0,
    EscaletePrivileges: 0,
    InternalRecon: 0,
    CompleteMission: 0,
  };