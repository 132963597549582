import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { unlockPassword } from "../../User/services/Users";
import Box from "@mui/material/Box";
import "toastr/build/toastr.min.css";
import eyeCloseIcon from "../../../assets/images/eye-icon-close.svg";
import eyeOpenIcon from "../../../assets/images/eye-icon.svg";
import { NotifyWarning } from "../../../Helpers/Scripts";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      win: null,
      showNewPwd: false,
      showConfmPwd: false,
      refId: this.props.match.params.ref,
    };
  }

  hideShowPassword = (pwdType) => {
    if (pwdType === "new") {
      this.setState({
        showNewPwd: !this.state.showNewPwd,
      });
    } else if (pwdType === "confirm") {
      this.setState({
        showConfmPwd: !this.state.showConfmPwd,
      });
    }
  };

  formAttr = (form, field) => ({
    onBlur: form.handleBlur,
    onChange: form.handleChange,
    value: form.values[field],
  });

  errorContainer = (form, field) => {
    return form.touched[field] && form.errors[field] ? (
      <bdi className="rt-text-danger">{form.errors[field]}</bdi>
    ) : null;
  };

  resetPassword = (formData, resetForm) => {
    var resetPwdData = {
      key: formData.key,
      ref: formData.refcode,
      password: formData.password,
      repassword: formData.confirmpwd,
    };
    const response = unlockPassword(resetPwdData);
      if (response === "Account activated") {
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
      else{
        NotifyWarning("The passwords are different")
      }
  };

  getResetButtonClass = (form) => {
    let isInvalidForm =
      form.errors.password || form.errors.confirmpwd || form.errors.key;
    let isFilledFilds =
      form.values.password && form.values.confirmpwd && form.values.key;
    return !isInvalidForm && isFilledFilds
      ? "rt-enable-button"
      : "rt-disable-button";
  };

  render() {
    return (
      <Box className="bg-section-main">
        <Box className="rt-allign-items-center">
          <Box className="rt-col-12">
            <Box className="rt-log-box-scroll">
              <Box className="rt-log-box">
                <Box className="rt-log-box-logo"></Box>
                <Box className="rt-log-box-inr">
                  <h1>Activate Account</h1>
                  <p>
                    You have to create a new password to activate the account
                  </p>
                  <Formik
                    innerRef={this.formikRef}
                    enableReinitialize
                    initialValues={{
                      password: "",
                      confirmpwd: "",
                      key: "",
                      refcode: this.props.match.params.ref,
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string().required("Password is required."),
                      confirmpwd: Yup.string().oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      ),
                      key: Yup.string().required("Key is required."),
                      refcode: Yup.string().required("Ref Code is required."),
                    })}
                    onSubmit={(
                      formData,
                      { setSubmitting, setErrors, resetForm }
                    ) => {
                      this.resetPassword(formData, resetForm);
                      setSubmitting = true;
                    }}
                  >
                    {(runform) => (
                      <form className="row" onSubmit={runform.handleSubmit}>
                        <div
                          className={
                            "rt-form-group-col-12 pass-main-click position-relative " +
                            (this.state.showNewPwd ? "active" : "")
                          }
                        >
                          <label>Password</label>
                          <input
                            type={this.state.showNewPwd ? "text" : "password"}
                            className="rt-text-box rt-form-control"
                            id="password"
                            name="password"
                            {...this.formAttr(runform, "password")}
                          />
                          <div
                            className="pass-show-hide"
                            onClick={() => this.hideShowPassword("new")}
                          >
                            {this.state.showNewPwd ? (
                              <img
                                src={eyeCloseIcon}
                                className="pass-show-image"
                                alt=""
                              />
                            ) : (
                              <img
                                src={eyeOpenIcon}
                                className="pass-close-image"
                                alt=""
                              />
                            )}
                          </div>
                          {this.errorContainer(runform, "password")}
                        </div>
                        <div
                          className={
                            "rt-form-group-col-12 pass-main-click position-relative " +
                            (this.state.showConfmPwd ? "active" : "")
                          }
                        >
                          <label>Confirm Password</label>
                          <input
                            type={this.state.showConfmPwd ? "text" : "password"}
                            className="rt-text-box rt-form-control"
                            id="confirmpwd"
                            name="confirmpwd"
                            {...this.formAttr(runform, "confirmpwd")}
                          />
                          <div
                            className="pass-show-hide"
                            onClick={() => this.hideShowPassword("confirm")}
                          >
                            {this.state.showConfmPwd ? (
                              <img
                                src={eyeCloseIcon}
                                className="pass-show-image"
                                alt=""
                              />
                            ) : (
                              <img src={eyeOpenIcon} alt="" />
                            )}
                          </div>
                          {this.errorContainer(runform, "confirmpwd")}
                        </div>
                        <Box className="rt-form-group-col-12">
                          <label>Key (which is sent in mail)</label>
                          <input
                            type="text"
                            className="rt-text-box rt-form-control"
                            placeholder="key"
                            name="key"
                            {...this.formAttr(runform, "key")}
                          />
                          {this.errorContainer(runform, "key")}
                        </Box>
                        <Box className="rt-form-group-col-12 rt-py-3">
                          <button
                            type="submit"
                            className={this.getResetButtonClass(runform)}
                          >
                            Reset password
                          </button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withRouter(ResetPassword);
