import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  // Tooltip,
  Typography,
} from "@mui/material";
import { VpnKey } from "@mui/icons-material";
import "../../../../General.scss";
import "../../styles/User.scss";
import { ActionButton, SecondaryButton } from "../../../../Helpers/Themes";
import PasswordInput from "../../../../Helpers/CommonComponents/PasswordInput";
import { updatePassword } from "../../services/Users";
import { NotifyError, NotifyInfo } from "../../../../Helpers/Scripts";
import { clearStorage } from "../../../../Helpers/GlobalData";

export default function Changemodal(props) {
  const { User, DataUser } = props;

  const [open, setOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [repassword, setrepasword] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let flag = true;

    if (oldPassword === "") {
      flag = false;
    }
    if (password === "") {
      flag = false;
    }
    if (password !== repassword) {
      flag = false;
    }
    if (repassword === "") {
      flag = false;
    }

    if (flag) {
      const userData = {
        id: User.id,
        oldPassword: oldPassword,
        password: password,
        repassword: repassword,
        rol: DataUser.user.result.rol,
      };
      let response = await updatePassword(DataUser.token, userData);

      if (response) {
        NotifyInfo(response.msg);

        if (User.row.email === DataUser.user.result.email) {
          setTimeout(() => {
            clearStorage();
            window.location.reload();
          }, 2000);
        }
      }
      setOldPassword("");
      setPassword("");
      setrepasword("");
      handleClose();
    } else {
      NotifyError("Passwords not match our empty");
    }
  };

  const ChangeOldPasword = (Value) => {
    setOldPassword(Value);
  };
  const ChangePasword = (Value) => {
    setPassword(Value);
  };
  const ChangeRePasword = (Value) => {
    setrepasword(Value);
  };
  const body = (
    <Box className="contenido" style={{ maxWidth: "500px" }}>
      <Box className="header">
        <Typography className="headerTitle">Change Password</Typography>
      </Box>
      <Grid container>
        <Grid
          item
          md={12}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
            color: "white",
          }}
        >
          <Box>
            <Box>
              <Typography>Current Password</Typography>
              <PasswordInput
                Value={ChangeOldPasword}
                LabelInput="Current Password"
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
            color: "white",
          }}
        >
          <Box>
            <Box>
              <Typography>New Password</Typography>
              <PasswordInput Value={ChangePasword} LabelInput="Password" />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={12}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
            color: "white",
          }}
        >
          <Box>
            <Box>
              <Typography>Repeat Password</Typography>

              <PasswordInput
                Value={ChangeRePasword}
                LabelInput="Confirm Password"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} textAlign="center">
          <Box>
            <SecondaryButton
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </SecondaryButton>
            <ActionButton onClick={handleSubmit}>Save</ActionButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div>
      {/* <Tooltip title="Change password"> */}
      <IconButton
        className="iconButtonSize"
        style={{ color: "#fff", display: "inline-block" }}
        onClick={handleOpen}
      >
        <VpnKey />
      </IconButton>
      {/* </Tooltip> */}
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
