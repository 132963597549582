import React from 'react';
import { Box, Typography, Input} from '@mui/material';
import FlowButtons from "./FlowButtons";
import "../../Packages.scss";

class DescriptionForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
        };    
    }

    render(){
        return (
            <Box>
                <Box className="rt-TextAreaContent">
                <Typography className="TexTTitles">{this.props.title}</Typography>
                <Input
                    style={{height:"400px"}}
                    sx={{
                        ".MuiInput-input":{
                            height:"100% !important",
                            overflow:"auto",
                        }
                    }}
                    rows="6" 
                    multiline 
                    fullWidth
                    className="TextAreaResolution"
                    value={this.props.valueInput}
                    onChange={(e) => {this.props.handleChange(e.target.value);}}>
                </Input>
                </Box>
                <FlowButtons 
                    labelNext= "Next" 
                    handleNext= {this.props.handleNext} 
                    handleBack={this.props.handleBack}
                    disableNext={this.props.valueInput === ""}>
                </FlowButtons>
            </Box>
        )
    }
}

export default DescriptionForm;