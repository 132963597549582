import React, { useEffect, useState } from "react";
import "./Top5SuccessfullyExecutedArtifacts.scss";
import { Box } from "@mui/material";

import reximg from "../../../assets/images/comingdata.gif";

export default function Top5SuccessfullyExecutedArtifacts(props) {
  const { DataTop5ArtifactsSuccessfull } = props;
  const [top5ArtifactsRealExecution, settop5ArtifactsRealExecution] = useState(
    DataTop5ArtifactsSuccessfull
  );
  useEffect(() => {
    settop5ArtifactsRealExecution(DataTop5ArtifactsSuccessfull);
  }, [DataTop5ArtifactsSuccessfull]);

  return (
    <Box className="dash-cmn-box">
      <h2
        style={{
          padding: "10px",
          borderRadius: "12px 12px 0px 0px",
          backgroundColor: "#090f1a",
        }}
        className="dashbord_main_title"
      >
Most Successful Threats   </h2>
      <Box
        style={{
          margin: "0px",
          width: "100%",
          height: "550px",

          fontSize: "14px",
        }}
      >
        {top5ArtifactsRealExecution ? (
          <>
            <Box id="Top5SuccessfullyExecutedArtifactsChart"></Box>
          </>
        ) : (
          <>
            <Box style={{ textAlign: "center" }}>
              <img style={{ width: "50%" }} src={reximg} alt="" />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
