import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './General.scss';
import './Global.scss';
import PageNotFound from './Pages/Login/ResetPassword/DefaultPageNotFound';
import ForgotPassword from './Pages/Login/ForgotPassword/ForgotPassword';
import ResetPassword from './Pages/Login/ResetPassword/ResetPassword';
import { Home } from './Pages/Home/Home';


function App() {
//rutas relativas
  return (
   <>
    <Router>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/forgot-password" component={ForgotPassword}/>
            <Route exact path='/reset-password?ref=:ref&key=:key' component={ResetPassword} />
            <Route to="/Default" component={PageNotFound}/>
            <Redirect to="/"/>
          </Switch>
        </Router>
   </>
  );
}

export default App;
