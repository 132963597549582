import { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import GeneralModalDelete from "../../../../../../Helpers/CommonComponents/GeneralDeleteModal";
import { deleteBoxByIdAsync } from "../../../../Services/ServiceScriptPage";

export default function DeleteModalBox(props) {
  const { BoxData, DataUser } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const DeleteFucntion = async (e) => {
    await deleteBoxByIdAsync(DataUser.token, BoxData.id);
    props.Reload();
    setOpen(false);
  };

  return (
    <Box style={{ display: "inline-block" }}>
      <Tooltip title="Delete">
        <IconButton className="iconButtonSize">
          <Delete
            onClick={handleOpen}
            style={{ cursor: "pointer", color: "white" }}
          />
        </IconButton>
      </Tooltip>
      <GeneralModalDelete
        HeaderText="Do you want to delete this execution?"
        BodyText=""
        ExternalOpen={open}
        CloseAction={handleClose}
        CancelAction={handleClose}
        PrimaryAction={DeleteFucntion}
      />
    </Box>
  );
}
