/* eslint-disable no-undef */

export default function Top5SuccessfullyExecutedArtifactScript(
  maxNoArtifacts,
  executedArtifacts,
  disposed,
  exported
) {
  var objecImageExporting;
  am4core.ready(function () {
    am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_dark);
    
    var chart = am4core.create("Top5SuccessfullyExecutedArtifactsChart", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;
    chart.logo.height = -15000;
    am4core.options.autoDispose = true;

    chart.data = executedArtifacts;

    chart.radius = am4core.percent(70);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;

    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.slices.template.tooltipText = "{name}: {neto}";
    
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;
    series.alignLabels = false;
    series.fontSize = "12px";
    series.labels.template.text = "{value}%";
    series.legendSettings.itemValueText = " ";

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.slices.template.propertyFields.fill = "color";

    chart.legend = new am4charts.Legend()
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.fontSize = "16px";

    if (disposed === true)
    { 
      chart.dispose(); 
    }
    if(exported)
    {
      objecImageExporting = chart.exporting.getImage('png');
    }
  }); // end am4core.ready()
  return objecImageExporting;
}
