export const LevelColor = (level) => {
  let Color = "";
  switch (level) {
    case "Critical":
      Color = "#6a0000";
      break;
    case "High":
      Color = "#ff0000";
      break;

    case "Midd":
      Color = "#ff5830";
      break;

    case "Low":
      Color = "#ffffa2";
      break;

    case "None":
      Color = "#c039ff";
      break;
    default:
      Color = "#FFF";
      break;
  }
  return Color;
};
export const getType = (ext) => {
  let val = "";
  switch (ext) {
    case "bat":
      val = "Batch File";
      break;
    case "com":
      val = "Script File";
      break;
    case "exe":
      val = "Executable";
      break;
    case "xlsx":
      val = "Office Excel";
      break;
    case "docx":
      val = "Office Word";
      break;
    case "doc":
      val = "Office Word";
      break;

    default:
      val = "Unknown Extension"
      break;
  }
  return val;
};

export const tags=[
  {id:"tagOne", description:"untested"},
  {id:"tagTwo",description:"tested"},
  {id:"tagThree",description:"PartiallyTested"}
]