import { useState, useEffect } from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExporExcel(props) {
  const { importData, fileName } = props;
  const [data, setData] = useState([]);
  const [colums, setColums] = useState([]);

  useEffect(() => {
    if (
      importData !== null &&
      importData !== undefined &&
      importData.length !== 0
    ) {
      setData(importData.data);
      setColums(importData.colums);
    }
  }, [importData]);

  useEffect(() => {
    if (data === undefined) {
      setData([]);
    }
  }, [data]);
  return (
    <ExcelFile
      element={
        <div
          style={{
            width: "0px",
            height: "0px",
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
          id="exportedButton"
        ></div>
      }
      filename={fileName}
    >
      {data?.length > 0 ? (
        <ExcelSheet data={data} name="Sheet1">
          {colums.map((colum) => (
            <ExcelColumn label={colum.label} value={colum.value} />
          ))}
        </ExcelSheet>
      ) : null}
    </ExcelFile>
  );
}
ExporExcel.defaultProps = {
  PB: true,
  fileName: "Reveald Data Export",
  floatStyle: "none",
};
