import React from "react";
import "./TimeLineOfExecutions.scss";
export default function TimeLineOfExecutions(props) {
  return (
    <div
      style={{
        margin: "0px",
        width: "100%",
        height: "550px",
        fontSize: "14px",
      }}
    >
      <div id="timelineChartdiv" className="no_history"></div>
    </div>
  );
}
