export const GetDataClientAll = (response) => {
  let array = [];
  response.forEach((element) => {
    array.push({
      hostname: element.hostname
        ? element.hostname
        : element.workUser[0].hostname,
      ip:
        element.IPInformation.length > 0
          ? element.IPInformation[0].public.IP
          : "N/A",
      private:
        element.IPInformation.length > 0
          ? element.IPInformation[0].private.IP
          : "N/A",
      port:
        element.IPInformation.length > 0
          ? element.IPInformation[0].port
          : "N/A",
    });
  });
  return array;
};
