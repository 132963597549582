import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import { FormInputTextField, SecondaryButton } from "../../../Helpers/Themes";
import Logo from "../../../assets/logo.png";
import { unlockPassword } from "../../User/services/Users";
import { NotifyWarning } from "../../../Helpers/Scripts";

export default function DefaultPageNotDound() {
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [key, setKey] = useState("");
  const [ref, setRef] = useState("");

  useEffect(() => {
    const host = window.location.search;
    const urlParams = new URLSearchParams(host);

    let reference = urlParams.get("ref");
    let key = urlParams.get("key");
    setRef(reference);
    setKey(key);
  }, []);

  const handleSubmit = async (e) => {
    if (password === repassword) {
      let data = {
        password: password,
        repassword: repassword,
        key: key,
        ref: ref,
      };

      const response = await unlockPassword(data);
      if (response === "Account activated") {
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
    }
    else{
      NotifyWarning("The passwords are different")
    }
  };

  return (
    <Box className="container-login">
      <Box className="container-login-box">
        <Box className="container-section-logos">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Link to="/">
                <img
                  src={Logo}
                  alt="logo-EVE"
                  className="logo-rthreat-modern"
                />
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Box className="container-section-modern">
          <Grid container spacing={5}>
            <Grid item xs={12} lg={12}>
              <h3>Activate Account</h3>
              <p>You have to create a new password to activate the account</p>
              <FormInputTextField
                id="input-with-icon-textfield"
                label="Password"
                onInput={(value) => setPassword(value.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormInputTextField
                id="input-with-icon-textfield"
                label="Confirm Password"
                onInput={(value) => setRepassword(value.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            {key === "" && (
              <Grid item xs={12} lg={12}>
                <FormInputTextField
                  id="input-with-icon-textfield"
                  label="Key (which is sent in mail)"
                  value={key}
                  onChange={(e) => {
                    setKey(e.target.value);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item sx={7} xs={12} lg={7}>
              <SecondaryButton
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
                type="submit"
                fullWidth
              >
                Active account
              </SecondaryButton>
            </Grid>
            <Grid item sx={5} xs={12} lg={5}>
              <Button variant="text" type="submit" fullWidth>
                <Link className="link" to="/">
                  Back To Log in
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
