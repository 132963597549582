import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, ThemeProvider } from "@mui/material";
import "../../Packages/Packages.scss";
import { getAnexoPackage } from "../../../Services/Package";
import PackageDetails from "../../Packages/Sections/Details/PackageDetails";
import { packageTheme } from "../../Packages/PackageTheme";
import { env2 } from "../../../Helpers/Instance";
import { endPoint } from "../../../Services/EndPoint";
import AxiosFetch from "../../../Services/FetchServices";
import axios from "axios";

export default function PackModel(props) {
  const { Data } = props;
  const [open, setOpen] = useState(false);
  const [Row, setRow] = useState();

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const DownloadPDFFile = () => {
    getAnexoPackage(localStorage.getItem("token"), Row._id, Row.anexo);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (open) {
      const url = env2.api + endPoint.package + Data.row.PackageData.id;

      async function GetData() {
        const response = await AxiosFetch("GET", url, source);
        if (response.msg) {
          setRow(response.msg.result);
        }
      }
      GetData();
    }
    return () => {
      if (open) {
        source.cancel();
      }
    };
  }, [open, Data.row.PackageData.id]);

  return (
    <ThemeProvider theme={packageTheme}>
      <Box>
        <Typography style={{ cursor: "pointer" }} onClick={handleOpen}>
          {Data.row.Package}
        </Typography>
        <PackageDetails
          open={open}
          packageDetails={Row}
          downloadPdfFile={DownloadPDFFile}
          onClose={handleClose}
        ></PackageDetails>
      </Box>
    </ThemeProvider>
  );
}
