import axios from "axios";
import { NotifyInfo } from "../Helpers/Scripts";
import { expiredSession } from "./ResponseValidation";

const FILE_DOWNLOADING = "The file is downloading";

export async function updateFile(token, fileData) {
  var headersRequest = new Headers();
  headersRequest.append("Access-Control-Allow-Origin", window.location.origin);
  headersRequest.append("Authorization", "Bearer " + token);
  var formData = new FormData();
  formData.append("anexo", fileData.anexo);

  var requestOptions = {
    method: "PUT",
    headers: headersRequest,
    redirect: "follow",
    body: formData,
  };

  const response = await fetch(fileData.url, requestOptions)
    .then((data) => data.json())
    .catch((error) => console.log("error", error));
  return response;
}

export async function downloadScriptZip(token, url, name, data) {
  const headers = { Authorization: `Bearer ${token}` };
  const config = {
    url: url,
    headers,
    method: "POST",
    responseType: "blob",
    data: data,
  };
  try {
    await axios(config).then((response) => {
      if (response.status === 200) {
        NotifyInfo(FILE_DOWNLOADING);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download", name);
        document.body.appendChild(anchor);
        anchor.click();
      }
    });
  } catch (error) {
    console.log(error);
  }
}
export async function downloadFile(token, url, name) {
  const headers = { Authorization: `Bearer ${token}` };
  const config = {
    url: url,
    headers,
    method: "GET",
    responseType: "blob",
  };
  try {
    await axios(config).then((response) => {
      if (response.status === 200) {
        NotifyInfo(FILE_DOWNLOADING);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download", name);
        document.body.appendChild(anchor);
        anchor.click();
      }
    });
  } catch (error) {
    console.log(error);
  }
}
export async function downloadFileInsider(token, url, name, body) {
  const headers = {
    Authorization: `Bearer ${token}`,
    ContentType: "application/json",
  };
  const config = {
    url: url,
    headers,
    method: "POST",
    responseType: "blob",
    data: body,
  };
  try {
    await axios(config).then((response) => {
      if (response.status === 200) {
        NotifyInfo(FILE_DOWNLOADING);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.setAttribute("download", name);
        document.body.appendChild(anchor);
        anchor.click();
      }
    });
  } catch (error) {
    console.log(error);
  }
}
export async function getRequest(token, url) {
  var headersRequest = new Headers();
  headersRequest.append("Access-Control-Allow-Origin", window.location.origin);
  headersRequest.append("Authorization", "Bearer " + token);
  var requestOptions = {
    method: "GET",
    headers: headersRequest,
    redirect: "follow",
  };
  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

  return response;
}
export async function getRequestText(token, url) {
  var headersRequest = new Headers();
  headersRequest.append("Access-Control-Allow-Origin", window.location.origin);
  headersRequest.append("Authorization", "Bearer " + token);
  var requestOptions = {
    method: "GET",
    headers: headersRequest,
    redirect: "follow",
  };
  const response = await fetch(url, requestOptions)
    .then(async (response) => {
      return { status: response.status, value: await response.text() };
    })
    .catch((error) => console.log("error", error));
  return response;
}

export async function postRequest(token, url, body) {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: "POST",
    headers: requestHeaders,
    body: body,
    redirect: "follow",
  };

  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
  return response;
}

export async function postAppJsonRequest(token, url, body) {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", "Bearer " + token);
  requestHeaders.append("Access-Control-Allow-Origin", window.location.origin);
  requestHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: requestHeaders,
    body: body,
    redirect: "follow",
  };

  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
  return response;
}

export async function postAppJsonRequestWithoutToken(url, body) {
  var requestHeaders = new Headers();
  requestHeaders.append("Access-Control-Allow-Origin", window.location.origin);
  requestHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: requestHeaders,
    body: body,
    redirect: "follow",
  };

  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => {
      console.log("error", error);
    });
  return response;
}

export async function updateRequest(token, url, body) {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: "PUT",
    headers: requestHeaders,
    body: body,
  };

  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
  return response;
}

export async function updateJsonRequest(token, url, jsonBody) {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", "Bearer " + token);
  requestHeaders.append("Access-Control-Allow-Origin", window.location.origin);
  requestHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "PUT",
    headers: requestHeaders,
    body: jsonBody,
  };

  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
  return response;
}

export async function updateRequestForm(token, url, jsonBody) {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", "Bearer " + token);
  var formData = new FormData();
  formData.append("description", jsonBody.description);
  formData.append("language", jsonBody.platformLanguaje);
  formData.append("platform", jsonBody.platform);
  if (jsonBody?.alias || jsonBody?.alias !== "") {
    formData.append("scriptName", jsonBody.alias);
  }
  if (jsonBody?.executionInstruccion || jsonBody?.executionInstruccion !== "") {
    formData.append("run", jsonBody.executionInstruccion);
  }
  if (jsonBody?.executionParameters || jsonBody?.executionParameters !== "") {
    formData.append("parameters", jsonBody.executionParameters);
  }
  if (jsonBody?.executionPermissions || jsonBody?.executionPermissions !== "") {
    formData.append("preBoot", jsonBody.executionPermissions);
  }
  if (jsonBody?.file) {
    formData.append("file", jsonBody.file);
  }
  if (jsonBody?.code) {
    formData.append("code", jsonBody.code);
  }

  if (jsonBody?.name) {
    formData.append("name", jsonBody.name);
  }

  if (jsonBody?.extension) {
    formData.append("extension", jsonBody.extension);
  }
  var requestOptions = {
    method: "PUT",
    headers: requestHeaders,
    body: formData,
  };
  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
  return response;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      expiredSession();
    }
    return error;
  }
);
