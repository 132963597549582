import jsPDF from "jspdf";
import {
  ArtifactsListDetailsModule,
  ArtifactsListModule,
  CoverPage,
  EndpointsModule,
  FileLevelModule,
  InterpretacionModule,
  lifeCycleModule,
  MitigationModule,
  MITREDEscriptionModule,
  PackageModule,
  ReferentsModule,
  ResumeModule,
} from "./HelperDataPdf/DesignPdfs";

export const openPDFResume = (Report) => {
  const doc = new jsPDF();
  CoverPage(doc, Report.data.name.toString(), "Summary Report",Report);
  doc.setTextColor("#fff");

  Report.checks.forEach((element) => {
    if (element.checked) {
      if (element.label === "Resume") {
        ResumeModule(doc, Report);
      }
      if (element.label === "FileLevelResults") {
        FileLevelModule(doc, Report);
      }

      if (element.label === "EndPoints") {
        EndpointsModule(doc, Report);
      }

      if (element.label === "Packages") {
        PackageModule(doc, Report);
      }

      if (element.label === "ListofArtifacts") {
        ArtifactsListModule(doc, Report);
      }

      if (element.label === "ArtifactsDetails") {
        ArtifactsListDetailsModule(doc, Report);
      }

      if (element.label === "Interpretation") {
        InterpretacionModule(doc, Report);
      }

      if (element.label === "MITREDescription") {
        MITREDEscriptionModule(doc, Report);
      }

      if (element.label === "Actions") {
        MitigationModule(doc, Report);
      }

      if (element.label === "AttackLifeCycle") {
        lifeCycleModule(doc,Report.image)
      }
      if (element.label === "Referents") {
        ReferentsModule(doc,Report)
      }
    }
  });
 
  doc.save(Report.data.name.toString()+"_Summary"); //opens the data uri in new window
};
