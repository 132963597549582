import React from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  THREAT_TYPE_TAG,
  artifactBigIcon,
  SecondaryButton,
} from "../../../../Helpers/Themes";
import { ContentPaste } from "@mui/icons-material";
import { NotifySuccess } from "../../../../Helpers/Scripts";

const styleCellName = {
  maxWidth: "150px",
  color: "#fff",
  borderColor: "transparent",
};
const styleCellDescript = { borderColor: "transparent", color:"#fff" };

const gridLeftItem = {
  backgroundColor: "rgb(9, 15, 26)",
  textAlign: "center",
  borderRadius: "14px 0 0 0",
  padding: "20px",
};

const gridRightItem = {
  backgroundColor: "rgb(9, 15, 26)",
  borderRadius: "0 14px 0 0",
  padding: "20px",
};

const artifactIconContainer = {
  padding: "20px",
};

const fontDataStyle = {
  color: "rgb(102, 99, 143)",
};

class ArtifactDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderPresentation = () => {
    return (
      <div
        style={{
          backgroundColor: "rgb(9, 15, 26)",
        }}
      >
        <Box style={artifactIconContainer}>
          {artifactBigIcon(
            THREAT_TYPE_TAG[this.props.artifactData.threatType.replace("-", "")]
              .color
          )}
        </Box>
        <Typography
          align="center"
          variant="h6"
          style={{
            color:
              THREAT_TYPE_TAG[
                this.props.artifactData.threatType.replace("-", "")
              ].color,
            fontFamily: "Roboto",
            fontWeight: "bold",
            textShadow: "1px 1px #000",
          }}
        >
          Challenge Level
          {": " +
            THREAT_TYPE_TAG[this.props.artifactData.threatType.replace("-", "")]
              .label}
        </Typography>
        <Typography align="center" variant="h6" style={fontDataStyle}>
          {this.props.artifactData.name}
        </Typography>
      </div>
    );
  };
  copyToClipboard = () => {
    navigator.clipboard.writeText(this.props.artifactData.md5);
    NotifySuccess("Copied to Clipboard");
  };

  MitreGenerate = (array) => {
    let concat = "";
    array.forEach((element, index) => {
      if (index === array.length - 1) {
        concat += element;
      } else if (index === array.length - 2) {
        concat += element + " & ";
      } else {
        concat += element + ", ";
      }
    });
    return concat;
  };
  renderData = () => {
    return (
      <Table>
        <TableRow>
          <TableCell align="right" style={styleCellName}>
            <Typography>File Name:</Typography>
          </TableCell>
          <TableCell style={styleCellDescript}>
            <Typography>{this.props.artifactData.name}</Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="right" style={styleCellName}>
            <Typography>Id:</Typography>
          </TableCell>

          <TableCell style={styleCellDescript}>
            <Typography>{this.props.artifactData.id}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" style={styleCellName}>
            <Typography>Size:</Typography>
          </TableCell>
          <TableCell style={styleCellDescript}>
            <Typography>{this.props.artifactData.file.size + " Kb"}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" style={styleCellName}>
            <Typography style={{ color: "#fff" }}>MITRE Attacks:</Typography>
          </TableCell>
          <TableCell style={styleCellDescript}>
            <Typography>
              {this.MitreGenerate(this.props.artifactData.phaseAttacks)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" style={styleCellName}>
            <Typography>MD5:</Typography>
          </TableCell>
          <TableCell style={styleCellDescript}>
            <Typography style={{ display: "inline-flex" }}>
              {this.props.artifactData.md5}
            </Typography>
            <SecondaryButton
              style={{ display: "inline-flex" }}
              sx={{ width: "50px", height: "25px" }}
              onClick={this.copyToClipboard.bind()}
            >
              <ContentPaste
                style={{ color: "rgb(102, 99, 143)", fontSize: "small" }}
              />
              <Typography style={{ color: "rgb(102, 99, 143)" }}>
                Copy
              </Typography>
            </SecondaryButton>
          </TableCell>
        </TableRow>
      </Table>
    );
  };

  render() {
    return (
      <Box
        style={{
          backgroundcolor: "#66638f",
          borderBottom: "1px solid #1e1c3a",
        }}
      >
        <Grid container>
          <Grid item xs={4} style={gridLeftItem}>
            {this.renderPresentation()}
          </Grid>

          <Grid item xs={8} style={gridRightItem}>
            {this.renderData()}
          </Grid>
        </Grid>

        {/* <Grid item xs={12} style={gridBottomItem}>
          <Typography style={{ color: "#fff" }}>Description:</Typography>
          <Typography style={{ color: "#fff",
                whiteSpace: "pre-wrap", }}>
            {this.props.artifactData.description}
          </Typography>
        </Grid> */}
      </Box>
    );
  }
}

export default ArtifactDetails;
