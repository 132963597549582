import autoTable from "jspdf-autotable";
import Image64b from "./dataUrlImageConverted64";
import logoImage from "./dataLogo";
import { scriptBackground } from "./scriptBackgorund";
import { logoScript } from "./logoScript";

var lMargin = 15; //left margin in mm
var rMargin = 20; //right margin in mm
var pdfInMM = 200; // width of A4 in mm

export function CoverPage(doc, Name, Title, Report) {
  doc.addImage(Image64b, "PNG", 0, 0, 210, 297);
  doc.addImage(logoImage, "PNG", 90, 130, 110, 18);
  doc.setTextColor("#191f2c");
  doc.setFontSize(40);
  doc.text(Title, 90, 80); //Pagina inicial
  doc.setFontSize(18);
  doc.setTextColor("#191f2c");

  doc.text("Date : " + Report.data.RESUME.date, 90, 90);
  doc.setFontSize(16);
  var stringName = Name;
  if (stringName.length < 30) {
    doc.setTextColor("#191f2c");
    doc.text("Event : " + stringName, 90, 190);
  } else {
    let posY = 190;
    doc.setTextColor("#191f2c");
    doc.text("Event : " + stringName.substring(0, 30) + " -", 20, posY);
    do {
      posY = posY + 8;
      doc.text(stringName.substring(30, 90), 20, posY);
      stringName = stringName.substring(90, stringName.length);
    } while (stringName.length > 0 || posY < 186);
  }
}
export function CoverPageInfo(doc, Name, Title, date) {
  doc.addImage(Image64b, "PNG", 0, 0, 210, 297);
  doc.addImage(logoImage, "PNG", 90, 130, 110, 18);
  doc.setTextColor("#191f2c");
  doc.setFontSize(40);
  doc.text(Title, 90, 80); //Pagina inicial
  doc.setFontSize(18);
  doc.setTextColor("#191f2c");

  doc.text("Date : " + date, 90, 90);
  doc.setFontSize(16);
  var stringName = Name;
  if (stringName.length < 30) {
    doc.setTextColor("#191f2c");
    doc.text("Event : " + stringName, 90, 190);
  } else {
    let posY = 190;
    doc.setTextColor("#191f2c");
    doc.text("Event : " + stringName.substring(0, 30) + " -", 20, posY);
    do {
      posY = posY + 8;
      doc.text(stringName.substring(30, 90), 20, posY);
      stringName = stringName.substring(90, stringName.length);
    } while (stringName.length > 0 || posY < 186);
  }
}
export function CoverPageScript(doc, Name, Title, date, noClients, noScripts) {
  doc.addImage(scriptBackground, "PNG", 0, 0, 210, 297);
  doc.addImage(logoScript, "PNG", 90, 15, 110, 18);
  doc.setTextColor("#fff");
  doc.setFontSize(40);
  doc.text(Title, 10, 100); //Pagina inicial
  doc.setFontSize(18);
  doc.setTextColor("#fff");
  doc.text("Event : " + Name, 30, 120);
  doc.setFontSize(16);
  doc.text("Clients : " + noClients, 30, 130);
  doc.text("Scripts : " + noScripts, 30, 140);
  doc.text("Execution Date : " + date, 110, 290);
}
export function NewPage(doc) {
  doc.addPage();
  doc.setFillColor("#191f2c");
  doc.rect(0, 0, 5, 150, "F");
  doc.setFillColor("#c39038");
  doc.rect(110, 292, 100, 5, "F"); //Headers

  doc.addImage(logoImage, "PNG", 137, 1, 70, 12);
  doc.setTextColor("#fff");
}

export function ResumeModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#191f2c");
  doc.setFontSize(14);
  doc.text("Perspective:", 20, 35);
  if (Report.data.perspective === true) {
    doc.text("Attacker", 50, 35);
  } else {
    doc.text("Defender", 50, 35);
  }
  doc.setTextColor("#214a90");

  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("SUMMARY", 80, 45);
  doc.setFontSize(14);

  doc.setTextColor("#191f2c");
  doc.setFont("Helvetica", "normal", 100);

  doc.text("Name:", 20, 55);
  if (Report.data.RESUME.name.length > 60) {
    doc.text(Report.data.RESUME.name.substring(0, 60) + "...", 65, 55);
  } else {
    doc.text(Report.data.RESUME.name, 65, 55);
  }

  doc.text("Date:", 20, 65);
  doc.text(Report.data.RESUME.date, 65, 65);

  doc.text("Vector:", 20, 75);
  if (Report.data.RESUME.vector === "NetworkSecurity") {
    doc.setDrawColor(184, 99, 199);
    doc.roundedRect(65, 69, 30, 10, 4, 4, "S");
    doc.setTextColor("#b863c7");
    doc.text("Network", 72, 75);
  }
  if (Report.data.RESUME.vector === "EPP") {
    doc.setDrawColor(238, 184, 84);
    doc.roundedRect(65, 69, 30, 10, 4, 4, "S");
    doc.setTextColor("#eeb854");
    doc.text("Endpoint", 71, 75);
  }
  if (Report.data.RESUME.vector === "RealExecution") {
    doc.setDrawColor(92, 199, 98);
    doc.roundedRect(65, 69, 30, 10, 4, 4, "S");
    doc.setTextColor("#5cc762");
    doc.text("Execution", 70, 75);
  }

  // doc.setTextColor("#191f2c");
  // doc.setFontSize(30);
  // doc.setFont("Helvetica-Bold", "normal", "bold"); //

  // doc.text(Report.data.ENDPOINTSDETAILS.length.toString(), 30, 110);
  doc.setTextColor("#191f2c");
  // doc.setFontSize(16);
  // doc.text("Target Hosts ", 60, 110);
  // doc.setFontSize(12);
  // doc.setFont("Helvetica", "normal", 110);
  // doc.text(lines, 100, 105);

  doc.autoTable({
    margin: 20,
    startY: 90,
    body: [
      [
        Report.data.ENDPOINTSDETAILS.length.toString(),
        "Target Hosts",
        Report.data.RESUME.hosts.toString(),
      ],
    ],
    theme: "plain",
    columnStyles: {
      0: {
        fontStyle: "bold",
        fontSize: "12",
        halign: "center",
        valign: "center",
        minCellWidth: 20,
      },
      1: {
        fontStyle: "bold",
        fontSize: "12",
        halign: "center",
        valign: "center",
        minCellWidth: 40,
      },
      2: { fontSize: "12", halign: "center", minCellWidth: 100 },
    }, // Cells in first column centered and green
  });

  let cien =
    Report.data.RESUME.emulations.success +
    Report.data.RESUME.emulations.failed;
  let value = (2 * Math.PI * Report.data.RESUME.emulations.success) / cien;
  let porcentSuccess = Math.round(
    (Report.data.RESUME.emulations.success * 100) / cien
  );
  let porcentFaild = Math.round(100 - porcentSuccess);

  let value2 =
    (2 * Math.PI * Report.data.RESUME.sendArtifacts.sucessful) / cien;
  let porcentSuc = Math.round(
    (Report.data.RESUME.sendArtifacts.sucessful * 100) / cien
  );
  let porcentFai = Math.round(100 - porcentSuc);

  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.setFontSize(14);
  doc.setDrawColor(0, 0, 0); // draw red lines

  // doc.line(10, 95, 190, 95);
  doc.line(10, 115, 190, 115);
  doc.line(10, 170, 190, 170);
  doc.line(10, 115, 10, 170);
  doc.line(190, 115, 190, 170);

  doc.setLineWidth(2);
  doc.setDrawColor(46, 125, 30); // draw red lines
  doc.line(25, 140, 33, 140);
  doc.line(32, 140, 35, 131);
  doc.line(34, 131, 39, 150);
  doc.setDrawColor(55, 255, 55); // draw red lines
  doc.line(35, 136, 39, 150);
  doc.line(39, 150, 41, 140);
  doc.line(40, 140, 48, 140);

  doc.setDrawColor(110, 0, 0); // draw red lines
  doc.line(70, 140, 78, 140);
  doc.line(77, 140, 80, 131);
  doc.line(79, 131, 84, 150);
  doc.setDrawColor(255, 0, 0); // draw red lines
  doc.line(80, 136, 84, 150);
  doc.line(84, 150, 86, 140);
  doc.line(85, 140, 93, 140);

  doc.setDrawColor(46, 125, 30); // draw red lines
  doc.line(115, 140, 123, 140);
  doc.line(122, 140, 125, 131);
  doc.line(124, 131, 129, 150);
  doc.setDrawColor(55, 255, 55); // draw red lines
  doc.line(125, 136, 129, 150);
  doc.line(129, 150, 131, 140);
  doc.line(130, 140, 138, 140);

  doc.setDrawColor(110, 0, 0); // draw red lines
  doc.line(155, 140, 163, 140);
  doc.line(162, 140, 165, 131);
  doc.line(164, 131, 169, 150);
  doc.setDrawColor(255, 0, 0); // draw red lines
  doc.line(165, 136, 169, 150);
  doc.line(169, 150, 171, 140);
  doc.line(170, 140, 178, 140);

  doc.text(porcentSuccess.toString() + "%", 30, 125);
  doc.text("Successful", 25, 160);

  doc.text(porcentFaild.toString() + "%", 77, 125);
  doc.text("Failed", 75, 160);

  doc.text(Report.data.RESUME.emulations.success.toString(), 123, 125);
  doc.text(Report.data.RESUME.emulations.failed.toString(), 163, 125);
  doc.setFontSize(8);
  doc.text("No. of Files in emulation", 110, 160);
  doc.text("Successful", 110, 165);
  doc.text("No. of Files in emulation", 150, 160);
  doc.text("Failed", 150, 165);

  doc.setFont("Helvetica", "normal", 100);

  doc.setTextColor("#111");
  doc.setFontSize(20);
  doc.text("Emulations", 26, 195);

  doc.setFillColor("#00ff00");
  doc.circle(25, 201, 2, "F");
  doc.setFontSize(10);
  doc.text("Successful", 28, 202);

  doc.setFillColor("#ff4747"); //Rojo faild
  doc.circle(50, 201, 2, "F");
  doc.setFontSize(10);
  doc.text("Failed", 53, 202);

  doc.setLineWidth(6);
  doc.setDrawColor("#00ff00"); //verde success
  doc.circle(45, 230, 20, "S");

  var context = doc.context2d;
  context.lineWidth = 6;
  if (porcentFaild > 0) {
    context.beginPath();
    context.arc(45, 230, 20, 0, value, true);
    context.strokeStyle = "#ff4747";
    context.stroke();
  }
  if (
    Report.data.RESUME.emulations.success !== cien &&
    Report.data.RESUME.emulations.success !== 0
  ) {
    doc.setTextColor("#111");
    doc.setFontSize(10);
    doc.text(porcentFaild.toString() + "%", 70, 228);
    doc.text(porcentSuccess.toString() + "%", 70, 234);
  } else {
    doc.setTextColor("#111");
    doc.setFontSize(10);
    doc.text("100%", 70, 228);
  }

  doc.setTextColor("#111");
  doc.setFontSize(20);
  doc.text("Send artifacts", 105, 195);

  doc.setFillColor("#00ff00");
  doc.circle(105, 200, 2, "F");
  doc.setFontSize(10);
  doc.text("Successful", 108, 201);

  doc.setFillColor("#ff4747");
  doc.circle(130, 200, 2, "F");
  doc.setFontSize(10);
  doc.text("Failed", 133, 201);

  doc.setLineWidth(6);
  doc.setDrawColor("#00ff00");
  doc.circle(125, 230, 20, "S");

  if (porcentFai > 0) {
    context.beginPath();
    context.arc(125, 230, 20, 0, value2, true);
    context.strokeStyle = "#ff4747";
    context.stroke();
  }
  if (
    Report.data.RESUME.sendArtifacts.sucessful !== cien &&
    Report.data.RESUME.sendArtifacts.sucessful !== 0
  ) {
    doc.setTextColor("#111");
    doc.setFontSize(10);
    doc.text(porcentFai.toString() + "%", 150, 228);
    doc.text(porcentSuc.toString() + "%", 150, 234);
  } else {
    doc.setTextColor("#111");
    doc.setFontSize(10);
    doc.text("100%", 150, 228);
  }
}

export function FileLevelModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("FILE LEVEL RESULTS TABLE", 60, 35);
  doc.autoTable({
    startY: 40,
    headStyles: { fillColor: [30, 28, 58] },
    head: [["Host Name", "Name", "Network ", "Endpoint", "Execution", "C2"]],
    body: Report.data.FILELEVELRESULTS,
  });
  doc.setTextColor("#111");
}

export function EndpointsModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("ENDPOINTS DETAILS", 60, 35);
  doc.autoTable({
    startY: 40,
    headStyles: { fillColor: [30, 28, 58] },
    head: [
      [
        "Host Name",
        "Private Ip Address",
        "Public Ip Address  ",
        "OS Information",
        "EVE Software Version",
      ],
    ],
    body: Report.data.ENDPOINTSDETAILS,
  });
  doc.setTextColor("#111");
}
export function PackageModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("PACKAGE DETAILS", 60, 35);
  doc.autoTable({
    startY: 40,
    head: [["PACKAGE", "DESCRIPTION", "# SAMPLES", "MITRE"]],
    body: Report.data.PACKAGEDETAILS,
    headStyles: { fillColor: [30, 28, 58] },
    columnStyles: {
      0: { minCellWidth: 30 },
      1: { minCellWidth: 70 },
      2: { halign: "center", minCellWidth: 20 },
      3: { minCellWidth: 70 },
    }, // Cells in first column centered and green
  });
  doc.setTextColor("#111");
}
export function ArtifactsListModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("LIST OF ARTIFACTS", 60, 35);

  doc.addImage(Report.imagetypes, "PNG", 25, 45, 170, 90);
  doc.autoTable({
    startY: 150,
    headStyles: { fillColor: [30, 28, 58] },
    head: [["NAME", "MD5"]],
    body: Report.data.LISTOFARTIFACTS,
  });
  doc.setTextColor("#111");
}
export function ArtifactsListDetailsModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("ARTIFACTS DETAILS", 60, 35);
  autoTable(doc, {
    startY: 40,
    body: Report.data.ARTIFACTSDETAILS,
  });
  doc.setTextColor("#111");
}
export function InterpretacionModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("INTERPRETATION", 60, 35);
  doc.autoTable({
    startY: 40,
    headStyles: { fillColor: [30, 28, 58] },

    head: [["PACKAGE", "DESCRIPTION"]],
    body: Report.data.INTERPRETATION,
  });
  doc.setTextColor("#111");
}
export function MITREDEscriptionModule(doc, Report) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("MITRE DESCRIPTION", 60, 35);
  autoTable(doc, {
    startY: 40,
    theme: "plain",
    body: Report.data.MITREDESCRIPTION,
  });
  doc.setTextColor("#111");
}
export function MitigationModule(doc, Report) {
  for (let index = 0; index < Report.data.MITIGATIONS.length; index++) {
    const element1 = Report.data.MITIGATIONS[index];
    NewPage(doc);
    doc.setTextColor("#214a90");
    doc.setFontSize(20);
    doc.setFont("Helvetica-Bold", "normal", "bold"); //
    doc.text("MITIGATION", 60, 35);
    doc.setTextColor("#111");
    doc.setFontSize(18);
    doc.text(element1.Name, 14, 50);
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal", 100);
    var paragraph3 = element1.Description.toString();

    var textLines = doc.splitTextToSize(
      paragraph3,
      pdfInMM - lMargin - rMargin
    ); // Split the text into lines
    const pageHeight = doc.internal.pageSize.height - 10; // Get page height, we'll use this for auto-paging. TRANSLATE this line if using units other than `pt`
    let cursorY = 65;

    textLines.forEach((lineText) => {
      if (lineText === "undefined" || lineText === "null") {
        doc.setFontSize(10);
        doc.setFont("Helvetica", "normal", 100);
        doc.text(lineText, 10, 60);
      } else {
        if (cursorY > pageHeight) {
          // Auto-paging
          NewPage(doc);
          doc.setTextColor("#111");
          doc.setFontSize(10);
          doc.setFont("Helvetica", "normal", 100);

          cursorY = 25;
        }
        doc.text(10, cursorY, lineText);
        cursorY += 7;
      }
    });
  }
}

export function lifeCycleModule(doc, img) {
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(20);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  doc.text("ATTACK LIFE CYCLE", 60, 35);
  doc.addImage(img, "PNG", 7, 60, 195, 65);
}

export function ReferentsModule(doc, Report) {
  if (Report.data.REFERENTS.length > 0) {
    NewPage(doc);
    doc.setTextColor("#214a90");
    doc.setFontSize(20);
    doc.setFont("Helvetica-Bold", "normal", "bold"); //
    doc.text("REFERENTS", 60, 35);
    doc.autoTable({
      startY: 40,
      headStyles: { fillColor: [30, 28, 58] },
      head: [["Threat Name", "URL Referent"]],
      body: Report.data.REFERENTS,
    });
    doc.setTextColor("#111");
  }
}
