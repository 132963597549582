/* eslint-disable no-undef */

export default function DataListTypes(data){
  let datalist = getListArtifactsType(data);
  let dataChart = [ 
    {
      "name": 'Adware',
      "value": datalist.adware
    },{
      "name": "Backdoor",
      "value": datalist.backdoor
    },{
      "name": "Cryptominer",
      "value": datalist.cryptominer
    },{
      "name": "Hacktool",
      "value": datalist.hacktool
    }, {
      "name": "keylogger",
      "value": datalist.keylogger
    },{
      "name": "Puppua",
      "value": datalist.puppua
    },{
      "name": "Ransomware",
      "value": datalist.ransomware
    },{
      "name": "Trojan",
      "value": datalist.trojan
    },{
      "name": "Spyware",
      "value": datalist.spyware
    },{
    "name": "Virus",
    "value": datalist.virus
   },{
    "name": "Worm",
    "value": datalist.worm
   } ,{
    "name": "Uncategorized",
    "value": datalist.other
   }
  ];

let x =  dataChart.filter(item=> item.value > 0);
am4core.ready(function() {

    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    
    // Create chart instance
    var chart = am4core.create("DataListTypes", am4charts.PieChart);
    chart.logo.height = -15000;
    
    // Add data
    chart.data = x;
    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "name";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    
    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    
    pieSeries.labels.template.fill = am4core.color("#1e1c3a");
    }); // end am4core.ready()
}

function getListArtifactsType(ReportData){
  const totalArtifacts = [];
  ReportData.ReportData.Reports.historys.forEach(host=>{
    host.packages.forEach((pack) => {
      pack.artifacts.forEach(item=>{
        totalArtifacts.push({name:item.name})
      })
    })
  });

  let Va1 = 0,
    Va2 = 0,
    Va3 = 0,
    Va4 = 0,
    Va5 = 0,
    Va6 = 0,
    Va7 = 0,
    Va8 = 0,
    Va9 = 0,
    Va10 = 0,
    Va11 = 0,
    Va12 = 0;

  for (let index = 0; index < totalArtifacts.length; index++) {
    if (totalArtifacts[index].name.includes("ADWARE")) {
      Va1++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("BACKDOOR")) {
      Va2++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("HACKTOOL")) {
      Va3++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("KEYLOGGER")) {
      Va4++;
    } else if (
      totalArtifacts[index].name.toUpperCase().includes("CRYPTOMINER")
    ) {
      Va5++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("PUP")) {
      Va6++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("PUA")) {
      Va6++;
    } else if (
      totalArtifacts[index].name.toUpperCase().includes("RANSOMWARE")
    ) {
      Va7++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("SPYWARE")) {
      Va8++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("TROJAN")) {
      Va9++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("VIRUS")) {
      Va10++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("WORM")) {
      Va11++;
    } else {
      Va12++;
    }
  }
  let totalSum = Va1 + Va2 + Va3 + Va4 + Va5 + Va6 + Va7 + Va8 + Va9 + Va10 + Va11 + Va12;
  return {
    adware: Va1,
    backdoor: Va2,
    hacktool: Va3,
    keylogger: Va4,
    cryptominer: Va5,
    puppua: Va6,
    ransomware: Va7,
    spyware: Va8,
    trojan: Va9,
    virus: Va10,
    worm: Va11,
    other: Va12,
    total:totalSum
  } 
}