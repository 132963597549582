import React, { useEffect, useState } from "react";
import "./Top5ArtifactsSuccessfullyEvadedNetworkSecurity.scss";
import { Box } from "@mui/material";
import reximg from "../../../assets/images/comingdata.gif";
export default function Top5ArtifactsSuccessfullyEvadedNetworkSecurity(props) {
  const { Top5MostArtifactsEvadedData } = props;
  const [Top5MostArtifactEvaded, setTop5MostArtifactEvaded] = useState(
    Top5MostArtifactsEvadedData
  );

  useEffect(() => {
    setTop5MostArtifactEvaded(Top5MostArtifactsEvadedData);
  }, [Top5MostArtifactsEvadedData]);

  return (
    <div className="dash-cmn-box">
      <h2
        style={{
          padding: "10px",
          borderRadius: "12px 12px 0px 0px",
          backgroundColor: "#090f1a",
        }}
        className="dashbord_main_title"
      >
Threats Evading Network   </h2>

      {Top5MostArtifactEvaded ? (
        <div
          style={{
            margin: "0px",
            width: "100%",
            height: "550px",

            fontSize: "14px",
          }}
        >
          <div id="Top5ArtifactsSuccessfullyEvadedNetworkSecurityChart"></div>
        </div>
      ) : (
        <Box style={{ textAlign: "center" }}>
          <img style={{ width: "50%" }} src={reximg} alt="" />
        </Box>
      )}
    </div>
  );
}
