import { useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Modal,
  Tooltip,
  
} from "@mui/material";
import {
  VisibilityOutlined,
} from "@mui/icons-material";
import "../../BodyScriptPageStyles.scss";
import {
  getPreviewLog,
} from "../../../../Services/ServiceScriptPage";

export default function DetailsPreview(props) {
  const { DataUser, DataScript, disabled } = props;
  const [open, setOpen] = useState(false);
  const [ScriptDataDetails, setScriptDataDetails] = useState(null);

  const handleOpen = async () => {
    let body = JSON.stringify(DataScript);
    const response = await getPreviewLog(DataUser.token, body);
    if (response) {
      setScriptDataDetails(response);
      // console.log(response);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box
      className="Section"
      style={{
        width: "auto",
        backgroundcolor: "#66638f",
        borderBottom: "1px solid #1e1c3a",
        maxWidth:"600px",
        maxHeight: "700px",
        overflowY: "auto",
        overflowX: "hidden",
        // minWidth: "300px",
      }}
    >
      <Typography
        style={{
          wordWrap: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {ScriptDataDetails}
      </Typography>
    </Box>
  );

  return (
    <>
      <Tooltip title="Preview">
        <IconButton disabled={disabled}> 

        
        <VisibilityOutlined
          style={{ cursor: "pointer" }}
          onClick={handleOpen}
        ></VisibilityOutlined>
        </IconButton>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
}
