/* eslint-disable no-undef */

export default function PieChartAgentNetwork(
  data, disposed
) {
  am4core.ready(function () {
    // Themes begin
    am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);
    var chart = am4core.create("NetworkSecurityEvation", am4charts.PieChart);
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "status";
    pieSeries.legendSettings.labelText = '{status}';
    pieSeries.legendSettings.valueText = '{value}';
    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(30);
    var colorSet = new am4core.ColorSet();
    colorSet.list = ["#38c135", "#ff4747"].map(function (color) {
      return new am4core.color(color);
    });
    pieSeries.colors = colorSet;
    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.tooltipText = "{status}: {value}";
    pieSeries.slices.template
      // change the cursor on hover to make it apparent the object can be interacted with
      .cursorOverStyle = [
        {
          "property": "cursor",
          "value": "pointer"
        }
      ];

    // pieSeries.alignLabels = false;
    // pieSeries.labels.template.bent = true;
    // pieSeries.labels.template.radius = 3;
    // pieSeries.labels.template.padding(0, 0, 0, 0);
    // pieSeries.labels.template.fill = am4core.color("white");
    pieSeries.labels.template.text = "No. {status}: {neto}";
    pieSeries.slices.template.tooltipText = "{status}: {value}%";

    //pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
    shadow.opacity = 0;

    // Create hover state
    let hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    // Add a legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "right";

    chart.data = data;
    chart.logo.height = -17000;
    if(disposed === true)
  {chart.dispose();}
  }); // end am4core.ready()
}
