import React from "react";
import { PictureAsPdf } from "@mui/icons-material";
import {
  THREAT_TYPE_TAG,
  STATUS_TAG,
  PACKAGE_TYPE_TAG,
  ActionButton,
} from "../../../../Helpers/Themes";
import {
  Box,
  Divider,
  Grid,
  Modal,
  Tooltip,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableRow,
  TableCell,
  Tab,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import packageChallengerImg from "../../../../assets/images/PackageChallenger2.png";
import packageInteractivaImg from "../../../../assets/images/packageInteractiva2.png";
import packageKnownImg from "../../../../assets/images/packageKnown.png";
import packageUnknownImg from "../../../../assets/images/packageUnknown.png";
import packageZeroImg from "../../../../assets/images/packageZero.png";
import ArtifactDetails from "../Details/ArtifactDetails";
import { BugReportTwoTone } from "@mui/icons-material";
import { getAnexoPackage } from "../../../../Services/Package";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const styleCellName = {
  maxWidth: "150px",
  color: "#fff",
  borderColor: "transparent",
};
const styleCellDescript = { borderColor: "transparent" };

const modalStyle = {
  backgroundcolor: "#66638f",
  borderBottom: "1px solid #1e1c3a",
  //Height:"700px",
  overflowY: "auto",
};

const firstGridItemStyle = {
  backgroundColor: "rgb(13, 11, 31)",
  textAlign: "center",
  borderRadius: "14px 0 0 0",
  padding: "20px",
};

const secondGridItemStyle = {
  backgroundColor: "rgb(13, 11, 31)",
  borderRadius: "0 14px 0 0",
  padding: "20px",
};

const descriptionGridStyle = {
  backgroundColor: "rgb(13, 11, 31)",
  borderRadius: "0 0 14px 14px ",
  padding: "20px",
};

const packageImgStyle = {
  padding: "20px",
  objectFit: "contain",
  objectPosition: "center center",
  width: "100%",
  height: "auto",
};

const packageImg = {
  backgroundColor: "#242933",
  border: "4px solid lightBlue",
  borderRadius: "16px",
  width: "70%",
};

const nameStyle = {
  color: "rgb(102, 99, 143)",
};

const primaryFont = { color: "#fff", backgroundColor: "rgb(9, 15, 26)" };

const subTitleFont = { width: "50%", flexShrink: 0 };

class PackageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "1",
    };
  }
  handleChangeTab = (event, newValue) => {
    this.setState({ value: newValue });
  };

  DownloadPDFFile = () => {
    getAnexoPackage(
      localStorage.getItem("token"),
      this.props.packageDetails._id,
      this.props.packageDetails.anexo
    );
  };
  renderPresentation = () => {
    return (
      <div>
        {this.props.packageDetails?.status ? (
          <Box
            className={STATUS_TAG[this.props.packageDetails.status].className}
          >
            {STATUS_TAG[this.props.packageDetails.status].label}
          </Box>
        ) : null}

        <Box style={packageImgStyle}>
          {this.props.packageDetails.type === "Known" ? (
            <img src={packageKnownImg} alt="" style={packageImg} />
          ) : null}
          {this.props.packageDetails.type === "Uncategorized" ? (
            <img src={packageUnknownImg} alt="" style={packageImg} />
          ) : null}
          {this.props.packageDetails.type === "Challenger" ? (
            <img src={packageChallengerImg} alt="" style={packageImg} />
          ) : null}
          {this.props.packageDetails.type === "Interactiva" ? (
            <img src={packageInteractivaImg} alt="" style={packageImg} />
          ) : null}
          {this.props.packageDetails.type === "Zero" ? (
            <img src={packageZeroImg} alt="" style={packageImg} />
          ) : null}
          {this.props.packageDetails.type === "Undefined" ? (
            <img src={packageZeroImg} alt="" style={packageImg} />
          ) : null}
        </Box>

        <Typography
          align="center"
          variant="h6"
          style={{
            color: PACKAGE_TYPE_TAG[this.props.packageDetails.type].color,
          }}
        >
          Type: {PACKAGE_TYPE_TAG[this.props.packageDetails.type].label}
        </Typography>

        <Typography align="center" variant="h6" style={nameStyle}>
          {this.props.packageDetails.name}
        </Typography>

        <Tooltip title="Download PDF">
          <ActionButton
            onClick={this.props.downloadPdfFile}
            disabled={!this.props.packageDetails.anexo}
          >
            <PictureAsPdf color="inherit" style={{ marginRight: "7px" }} />
            Download
          </ActionButton>
        </Tooltip>
      </div>
    );
  };

  renderPackageData = () => {
    return (
      <div>
        <Table container>
          <TableRow>
            <TableCell align="right" style={styleCellName}>
              <Typography>Package Name:</Typography>
            </TableCell>
            <TableCell style={styleCellDescript}>
              <Typography>{this.props.packageDetails.name}</Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="right" style={styleCellName}>
              <Typography>ID:</Typography>
            </TableCell>

            <TableCell style={styleCellDescript}>
              <Typography>{this.props.packageDetails.id}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={styleCellName}>
              <Typography>Created:</Typography>
            </TableCell>
            <TableCell style={styleCellDescript}>
              <Typography>
                {this.props.packageDetails.createdAt + this.getCreatedBy()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={styleCellName}>
              <Typography>Updated:</Typography>
            </TableCell>
            <TableCell style={styleCellDescript}>
              <Typography>
                {this.props.packageDetails.updatedAt + this.getUpdatedBy()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={styleCellName}>
              <Typography>PDF:</Typography>
            </TableCell>
            <TableCell style={styleCellDescript}>
              <Typography>
                {this.props.packageDetails.anexo
                  ? this.props.packageDetails.anexo
                  : "No file"}
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    );
  };

  getCreatedBy = () => {
    let label = "";
    if (
      this.props.packageDetails.createdByUser !== undefined &&
      this.props.packageDetails.createdByEmail !== undefined
    ) {
      label =
        " by " +
        this.props.packageDetails.createdByUser +
        ", contact: " +
        this.props.packageDetails.createdByEmail;
    }
    return label;
  };

  getUpdatedBy = () => {
    let label = "";
    if (
      this.props.packageDetails.lastModificationByUser !== undefined &&
      this.props.packageDetails.lastModificationByUser !== undefined
    ) {
      label =
        " by " +
        this.props.packageDetails.lastModificationByUser +
        ", contact: " +
        this.props.packageDetails.lastModificationByEmail;
    }
    return label;
  };

  renderArtifacts = () => {
    return (
      <div>
        {this.props.packageDetails?.artifacts &&
        this.props.packageDetails.artifacts.length > 0 ? (
          this.props.packageDetails.artifacts.map((artifact, index) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={primaryFont} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={primaryFont}
                >
                  <BugReportTwoTone
                    style={{
                      fill: artifact?.threatType
                        ? THREAT_TYPE_TAG[artifact.threatType.replace("-", "")]
                            .color
                        : "white",
                      marginRight: "15px",
                    }}
                  />
                  <Typography sx={subTitleFont}>{artifact.name}</Typography>
                  <Typography
                    sx={{
                      color: artifact?.threatType
                        ? THREAT_TYPE_TAG[artifact.threatType.replace("-", "")]
                            .color
                        : "white",
                      objectPosition: "center center",
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      textShadow: "1px 1px #000",
                    }}
                  >
                    {artifact?.threatType
                      ? "Challenge Level : " +
                        THREAT_TYPE_TAG[artifact.threatType.replace("-", "")]
                          .label
                      : ""}
                  </Typography>
                </AccordionSummary>
                {artifact?.threatType ? (
                  <AccordionDetails
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                      backgroundColor: "rgb(9, 15, 26)",
                    }}
                  >
                    <Box>
                      <ArtifactDetails
                        artifactData={artifact}
                      ></ArtifactDetails>
                    </Box>
                  </AccordionDetails>
                ) : (
                  <></>
                )}
              </Accordion>
            );
          })
        ) : (
          <Typography
            style={{ width: "100%", textAlign: "center" }}
            sx={subTitleFont}
          >
            'Package without artifacts'
          </Typography>
        )}
      </div>
    );
  };

  handleChange = (panel) => (event, isExpanded) => {
    const expandedState = isExpanded ? panel : false;
    this.setState({ expanded: expandedState });
  };

  render() {
    return (
      <Modal open={this.props.open} onBackdropClick={this.props.onClose}>
        <Box className="SectionPackageDetails" style={modalStyle}>
          <TabContext value={this.state.value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={this.handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab style={{ color: "white" }} label="Package" value="1" />
                <Tab style={{ color: "white" }} label="Files" value="2" />
              </TabList>
            </Box>
            <TabPanel sx={{ padding: "10px" }} value="1">
              {this.props.packageDetails ? (
                <Grid container>
                  <Grid item xs={4} style={firstGridItemStyle}>
                    {this.renderPresentation()}
                  </Grid>

                  <Grid item xs={8} style={secondGridItemStyle}>
                    {this.renderPackageData()}
                    <Divider />
                  </Grid>

                  <Grid item xs={12} style={descriptionGridStyle}>
                    <Typography>Description:</Typography>
                    <Typography
                      style={{ whiteSpace: "pre-wrap", height: "auto" }}
                    >
                      {this.props.packageDetails.description}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </TabPanel>
            <TabPanel sx={{ padding: "10px" }} value="2">
              {this.props.packageDetails ? (
                <Grid container>
                  <Grid item xs={12} style={descriptionGridStyle}>
                    {/* <Typography align="center" variant="h5" color={{ color: "#fff", }}>
                                            Files:
                                        </Typography> */}
                    {this.renderArtifacts()}
                  </Grid>
                </Grid>
              ) : null}
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
    );
  }
}

export default PackageDetails;
