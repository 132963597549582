/* eslint-disable no-undef */
export default function ForceDirectedTree(data,disposed) {

  am4core.ready(function () {
  am4core.useTheme(am4themes_animated);
  am4core.options.autoDispose = true;
  var chart = am4core.create(
    "AgentTree",
    am4plugins_forceDirected.ForceDirectedTree
  );
  chart.logo.height = -15000;
  
  let series = chart.series.push(
    new am4plugins_forceDirected.ForceDirectedSeries()
  );
  series.data = data;
  // Set up data fields
  series.dataFields.value = "value";
  series.dataFields.name = "name";
  series.dataFields.children = "children";
  series.dataFields.color = "color";
  // series.dataFields.expandAll=false;

  // Add labels
  series.nodes.template.label.text = "{name}";
  series.nodes.template.tooltipText = "{tooltip}";
  series.nodes.template.outerCircle.fill="#21273F";
  series.nodes.template.label.fill='#21273F';
  series.fontSize = 12;
  series.minRadius = 20;
  series.maxRadius = 60;
  series.maxLevels = 2;
  series.color="#21273F";

  series.nodes.template.expandAll = false;
  if(disposed === true)
  {chart.dispose();}
});
  
}
