import React from "react";
import { Box } from "@mui/material";
import AgentLocationMapScript from "../../../Scripts/AgentLocationMapScript";
import "./AgentsLocationMap.scss";
import AgentLocationMapRotating from "./AgentLocationMapRotating/AgentLocationMapRotating";

class AgentsLocationMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AgentConnect: this.props.AgentConnect,
    };
  }
  componentDidMount() {
    AgentLocationMapScript(this.state.AgentConnect);
  }
  componentWillUnmount() {
    AgentLocationMapScript([], true);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.AgentConnect !== this.props.AgentConnect) {
      this.setState({ AgentConnect: this.props.AgentConnect });
      AgentLocationMapScript(this.props.AgentConnect);
    }
  }

  render() {
    return (
      <Box className="dash-cmn-box">
        <AgentLocationMapRotating AgentConnect={this.state.AgentConnect} />
        <Box
          style={{
            width: "100%",
            height: "522px",
            color: "white",
            fontSize: "14px",
          }}
        >
          <Box id="AgentLocationMap"></Box>
        </Box>
      </Box>
    );
  }
}
export default AgentsLocationMap;
