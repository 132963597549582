import * as React from "react";
import Box from "@mui/material/Box";
import { AccountCircle } from "@mui/icons-material";

export default function UserInput(props) {
  const { ErrorMessage } = props;
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    props.Value(event.target.value);
  };


  return (
    <Box className="inputEnvolt" style={{position: "relative"}}>
      <label className="shink-login" >User Name</label>
      <AccountCircle
       
        style={{margin:"1px",marginTop:"14px",marginRight:"5px",marginLeft:"5px",float:"left",display:"inline-block",top:"-55px",color:"white"}}
      ></AccountCircle>
      <input
        error={ErrorMessage}
        className="input-style-inputUser"
        value={values.password}
        onChange={handleChange("password")}
      />
      
    </Box>
  );
}
