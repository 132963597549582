const rememberValueTrue = 'true';

const storageKeys = {
    msg: 'msg',
    rememberStorage: 'remember',
}

export class StorageValues {
    constructor (msg) {
        this.msg = msg;
    }

    saveDataInLocalStorage() {
        localStorage.setItem(storageKeys.msg, this.msg);
    }

    saveDataInSessionStorage() {
        sessionStorage.setItem(storageKeys.msg, this.msg);
    }

    save() {
        let rememberMeEnable = getRememberMe();
        if (rememberMeEnable) {
            this.saveDataInLocalStorage();
        }
        else {
            this.saveDataInSessionStorage();
        }
    }
}

var StorageData = function () {
    this.storage = '';
};

StorageData.prototype = {
    setStorage: function ( storage ) {
        this.storage = storage;
    },

    getMsgData: function() {
        return this.storage.getMsgData();
    },
};

var sessionData = function() {
    this.getMsgData = function () {
        return new StorageValues(
            sessionStorage.getItem(storageKeys.msg),
        );
    }
};

var localData = function() {
    this.getMsgData = function () {
        return new StorageValues(
            localStorage.getItem(storageKeys.msg),
        );
    }
};

export function getStorageData() {
    const rememberMeEnable = getRememberMe();
    let storageData = new StorageData();
    if (rememberMeEnable) {
        storageData.setStorage(new localData());
    }
    else {
        storageData.setStorage(new sessionData());
    }

    const returnData = storageData.getMsgData();
    return returnData;
}

export function getToken(){
    const storageData = getStorageData();
    if (storageData.msg !== null)
    {
        const msgJson = JSON.parse(storageData.msg);
        return msgJson.token;
    }
}

function clearLocalStorage() {
    localStorage.removeItem(storageKeys.msg);
    // localStorage.removeItem('token-remember-me');
}

function clearSessionStorage() {
    // sessionStorage.removeItem('token-remember-me');
    sessionStorage.removeItem(storageKeys.msg);
}

export function setRememberMe(){
    sessionStorage.setItem(storageKeys.rememberStorage, rememberValueTrue);
}

export function clearRememberMe(){
    sessionStorage.removeItem(storageKeys.rememberStorage);
}

export function clearStorage(){
    clearLocalStorage();
    clearSessionStorage();
    localStorage.clear();
}

function getRememberMe(){
    const rememberValue = sessionStorage.getItem(storageKeys.rememberStorage);
    return rememberValue === rememberValueTrue;
}

export function ConvertDateUtc (date) {
    var newDate = new Date(date),
      month = "" + (newDate.getMonth() + 1),
      day = "" + newDate.getDate(),
      year = "" + newDate.getFullYear(),
      hh = "" + newDate.getHours(),
      mm = "" + newDate.getMinutes(),
      ss = "" + newDate.getSeconds();

    if (month.length < 2) 
    {
        month = "0" + month
    }   

    if (day.length < 2) 
    {
        day = "0" + day
    }

    if (mm.length < 2) 
    {
        mm = "0" + mm
    }
   
    if (ss.length < 2) 
    {
      ss = "0" + ss;
    }
    return [year, month, day].join("-") + " " + [hh, mm, ss].join(":");
  };

  export const PadNumbers =[
    '0','1','2','3','4','5','6','7','8','9','Backspace','Delete','ArrowLeft','ArrowRight'
  ]