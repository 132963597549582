import React from 'react';
import { Box } from "@mui/material";
import { ArrowButton, PageButton } from  '../../PackageTheme';

class FlowButtons extends React.Component{
    constructor(props){
        super(props);
        this.state={
            activeStep: 0,
        };     
    }
    
    render(){
        return (
            <Box>
                <PageButton onClick= {(e)=>{
                            e.target.disabled = true;
                            this.props.handleNext();                    
                            }} 
                            disabled= {this.props.disableNext}>
                    {this.props.labelNext}
                </PageButton>
                <ArrowButton onClick= {this.props.handleBack}>
                    Back
                </ArrowButton>
            </Box>
        )
    }
}

export default FlowButtons;