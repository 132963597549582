import { Box, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormCreateNewScript from "./FormNewScript";

export default function CreatedNewScript(props) {
  const { getOpenModal, DataUser } = props;
  const [openModal, setOpenModal] = useState(getOpenModal);
  const [windowsSizeHeight, setWindowsSizeHeight] = useState(window.innerHeight);

  useEffect(() => {
    setOpenModal(getOpenModal);
  }, [getOpenModal]);

  useEffect(() => {
    setWindowsSizeHeight(window.innerHeight);
  }, [windowsSizeHeight]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const HandleResult = (value) => {
    setOpenModal(!value);
  };
  
  const HandleReload = (value) => {
    props.HandleReload(value);
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        className="Section"
        style={{
          backgroundColor: "rgb(36,41,51)",
          border: "1px solid #090f1a",
          boxShadow: "18px 4px 35px rgb(0 0 0 / 15%)",
          borderRadius: "14px",
          padding: "0px !important",
          width: "50%",
          minHeight: "600px",
          maxHeight: "90%",
          overflowY: "auto",
          overflowX: "hidden",
          // maxHeight: windowsSizeHeight >= 2200 ? "50vh":"100vh",
          // overflow: "auto",          
        }}
      >       
          <FormCreateNewScript
            DataUser={DataUser}
            Result={HandleResult}
            HandleReload={HandleReload}
            handleClose={handleCloseModal}
          />
      </Box>
    </Modal>
  );
}
