import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./ExecutionsCommitted.scss";

export default function ExecutionsCommited(props) {
  const { ExecutionsCommitedData } = props;
  const [executionsCommited, setExecutionsCommited] = useState(
    ExecutionsCommitedData
  );

  useEffect(() => {
    setExecutionsCommited(ExecutionsCommitedData);
  }, [ExecutionsCommitedData]);

  return (
    <div className="dash-cmn-box">
      <h2
        style={{
          padding: "10px",
          borderRadius: "12px 12px 0px 0px",
          backgroundColor: "#090f1a",
        }}
        className="dashbord_main_title"
      >
        Percentage registered emulations
      </h2>
      <div style={{ display: "flex" }}>
        <div
          style={{
            margin: "0px",
            width: "100%",
            height: "550px",

            fontSize: "14px",
          }}
        >
          <div id="executionschart"></div>
        </div>
        <div
          style={{
            margin: "0px",
            width: "40%",
            height: "550px",

            fontSize: "14px",
            backgroundColor: "#090f1a",
            borderRadius: " 0px 0px 12px 0px",
          }}
        >
          {executionsCommited.length > 0 ? (
            executionsCommited.map((item, index) => {
              return (
                <div
                  key={"executionsCommited" + index}
                  className="online_agent_count"
                >
                  <span className={item.borderClass}>
                    <div style={{ marginLeft: "25px", marginTop: "3px" }}>
                      {item.value}
                    </div>
                  </span>
                  <p>{item.status}</p>
                </div>
              );
            })
          ) : (
            <Box>
              <Box
                style={{
                  margin: "0px",
                  width: "100%",
                  height: "480px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {/* <img style={{ width: "50%" }} src={reximg} alt="" /> */}
              </Box>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
}
