import moment from "moment";

export default function HelperDataPdfHistory(
  ReportData,
  attackMode,
  ExternalData
) {
  var dtoObject = {
    IdExecution: ReportData.idExecution,
    name: ReportData.name,
    vector: ReportData.vector,
    perspective: attackMode,
    RESUME: {
      name: ReportData.name,
      date: moment(ReportData.Reports.historys[0].general[0].createdAt).format(
        "YYYY-MM-DD hh:mm:ss a"
      ),
      vector: ReportData.vector,
      hosts: getDataHostnames(ReportData),
      emulations: dataReverse(ReportData, attackMode),
      sendArtifacts: getDataSendArtifacts(ReportData, attackMode),
    },
    FILELEVELRESULTS: getFileLevelsResults(
      ReportData,
      attackMode,
      ReportData.vector
    ),
    ENDPOINTSDETAILS: getEndpointsDetails(ReportData.Reports.historys),
    PACKAGEDETAILS: getDataPackages(ReportData.Reports.historys, ExternalData),
    LISTOFARTIFACTS: getListDataArtifact(ReportData.Reports.historys),
    DATARTIFACTSTYPE: getListArtifactsType(ReportData),
    ARTIFACTSDETAILS: getListDataArtifactsDetails(ReportData.Reports.historys),
    INTERPRETATION: getMitreInterpretation(ReportData),
    MITREDESCRIPTION: [
      [ExternalData?.attackName],
      [ExternalData?.attackDescription],
    ],
    ATTACKLIFECYCLE: attackLifeCicle(
      getMitreDescription(ReportData.Reports.historys)
    ),
    MITIGATIONS: getMitigations(ReportData, attackMode),
    REFERENTS: getReferencesLinks(ReportData.Reports.historys),
  };
  return dtoObject;
}
function dataReverse(ReportData, attackMode) {
  let myTrue = attackMode
    ? ReportData.Reports.statistics.successful
    : ReportData.Reports.statistics.failed;
  let myFalse = attackMode
    ? ReportData.Reports.statistics.failed
    : ReportData.Reports.statistics.successful;
  return {
    success: myTrue,
    failed: myFalse,
  };
}

function getListArtifactsType(ReportData) {
  const totalArtifacts = [];
  ReportData.Reports.historys.forEach((host) => {
    host.packages.forEach((pack) => {
      pack.artifacts.forEach((item) => {
        totalArtifacts.push({ name: item.name });
      });
    });
  });

  let Va1 = 0,
    Va2 = 0,
    Va3 = 0,
    Va4 = 0,
    Va5 = 0,
    Va6 = 0,
    Va7 = 0,
    Va8 = 0,
    Va9 = 0,
    Va10 = 0,
    Va11 = 0,
    Va12 = 0;

  for (let index = 0; index < totalArtifacts.length; index++) {
    if (totalArtifacts[index].name.includes("ADWARE")) {
      Va1++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("BACKDOOR")) {
      Va2++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("HACKTOOL")) {
      Va3++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("KEYLOGGER")) {
      Va4++;
    } else if (
      totalArtifacts[index].name.toUpperCase().includes("CRYPTOMINER")
    ) {
      Va5++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("PUP")) {
      Va6++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("PUA")) {
      Va6++;
    } else if (
      totalArtifacts[index].name.toUpperCase().includes("RANSOMWARE")
    ) {
      Va7++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("SPYWARE")) {
      Va8++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("TROJAN")) {
      Va9++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("VIRUS")) {
      Va10++;
    } else if (totalArtifacts[index].name.toUpperCase().includes("WORM")) {
      Va11++;
    } else {
      Va12++;
    }
  }
  let totalSum =
    Va1 + Va2 + Va3 + Va4 + Va5 + Va6 + Va7 + Va8 + Va9 + Va10 + Va11 + Va12;
  return {
    adware: Va1,
    backdoor: Va2,
    hacktool: Va3,
    keylogger: Va4,
    cryptominer: Va5,
    puppua: Va6,
    ransomware: Va7,
    spyware: Va8,
    trojan: Va9,
    virus: Va10,
    worm: Va11,
    other: Va12,
    total: totalSum,
  };
}

function getDataHostnames(ReportData) {
  let hostnames = [];
  ReportData.Reports.historys.forEach((item) => {
    hostnames.push(item.hostname);
  });
  return hostnames.toString().replace(/,/g, " & ");
}

function getDataSendArtifacts(ReportData, attackMode) {
  let counterSuccess = 0;
  let counterFailed = 0;

  ReportData.Reports.historys?.forEach((element) =>
    element.packages?.forEach((pack) => {
      pack.artifacts.forEach((artifact) => {
        artifact.history[0]?.event === "download" &&
        artifact.history[0]?.value === 1 &&
        artifact.history[1]?.event === "survivedowlandfile" &&
        artifact.history[1]?.value === 1
          ? counterSuccess++
          : counterFailed++;
      });
    })
  );
  let myTrue = attackMode ? counterSuccess : counterFailed;
  let myFalse = attackMode ? counterFailed : counterSuccess;
  return { sucessful: myTrue, failed: myFalse };
}
function getMitigations(ReportData, attackMode) {
  const data = [];
  let almostOne = 0;
  ReportData.Reports.historys[0].packages.forEach((pack) => {
    pack.artifacts.forEach((artifact) => {
      let vector = ReportData.vector;
      let satisfactory = artifact.history[0]?.value===1&&artifact.history[1]?.value === 1;
      let desc = "";
      if (satisfactory) {
        almostOne++;
        if (vector === "NetworkSecurity") {
          if (
            artifact.history[0]?.value === 1 &&
            artifact.history[1]?.value === 1
          ) {
            desc = Convertext(
              artifact.resolutionNetwork === undefined
                ? "No data content resolutionNetwork"
                : artifact.resolutionNetwork
            );
          }
        }
        if (vector === "EPP") {
          desc =
            Convertext(
              artifact.resolutionNetwork === undefined
                ? "No data content resolutionNetwork"
                : artifact.resolutionNetwork
            ) + "\n";
          if (
            artifact.history[2]?.value === 1 &&
            artifact.history[3]?.value === 1
          ) {
            desc =
              desc +
              Convertext(
                artifact.resolutionEndPoint === undefined
                  ? "No data content resolutionEndPoint"
                  : artifact.resolutionEndPoint
              );
          }
        }
        if (vector === "RealExecution") {
          desc =
            Convertext(
              artifact.resolutionNetwork === undefined
                ? "No data content resolutionNetwork"
                : artifact.resolutionNetwork
            ) + "\n";
          if (
            artifact.history[2]?.value === 1 &&
            artifact.history[3]?.value === 1
          ) {
            desc =
              desc +
              Convertext(
                artifact.resolutionEndPoint === undefined
                  ? "No data content resolutionEndPoint"
                  : artifact.resolutionEndPoint
              ) +
              "\n";
          }
          if (
            artifact.history[4]?.value === 1 &&
            artifact.history[5]?.value === 1
          ) {
            desc =
              desc +
              Convertext(
                artifact.resolutionCallback === undefined
                  ? "No data content resolutionCallback"
                  : artifact.resolutionCallback
              );
          }
        }

        data.push({
          Name: artifact.name,
          Description: desc,
        });
      }
    });
  });
  if (almostOne === 0) {
    data.push({
      Name: "NO MITIGATIONS",
      Description: "",
    });
  }
  return data;
}
function Convertext(text) {
  if (text) {
    let converttext = text.toString();
    converttext.replace(/\\t/g, "\\n");
    converttext.replace("$string", "carlos");

    return converttext;
  } else {
    return text;
  }
}
function getFileLevelsResults(ReportData, attackMode, vector) {
  const array = [];
  let myTrue = attackMode === true ? "True" : "False";
  let myFalse = !attackMode === false ? "False" : "True";
  ReportData.Reports.historys?.forEach((element) =>
    element.packages?.forEach((pack) => {
      pack.artifacts.forEach((artifact) => {
        const obj = {};

        for (let i = 0, len = artifact.history.length; i < len; i++) {
          obj[artifact.history[i]["event"]] = artifact.history[i];
        }
        let artifacts = [];

        for (let key in obj) {
          artifacts.push(obj[key]);
        }
        if (vector === "NetworkSecurity") {
          array.push([
            element.hostname,
            artifact.name,
            artifacts[0]?.event === "download" &&
            artifacts[0]?.value === 1 &&
            artifacts[1]?.event === "survivedowlandfile" &&
            artifacts[1]?.value === 1
              ? myTrue
              : myFalse,
            "N/A",
            "N/A",
            artifact.C2 === "Failed"
              ? artifact.C2 === "Failed" || artifact.C2 === "Needless"
                ? myFalse
                : myTrue
              : artifact.C2 === "Needless"
              ? "N/A"
              : myFalse,
          ]);
        } else if (vector === "EPP") {
          array.push([
            element.hostname,
            artifact.name,
            artifacts[0]?.event === "download" &&
            artifacts[0]?.value === 1 &&
            artifacts[1]?.event === "survivedowlandfile" &&
            artifacts[1]?.value === 1
              ? myTrue
              : myFalse,

            artifacts[2]?.event === "tmpcopy" &&
            artifacts[2]?.value === 1 &&
            artifacts[3]?.event === "survivedfile" &&
            artifacts[3]?.value === 1
              ? myTrue
              : myFalse,
            "N/A",
            artifact.C2 === "Failed"
              ? artifact.C2 === "Failed" || artifact.C2 === "Needless"
                ? myFalse
                : myTrue
              : artifact.C2 === "Needless"
              ? "N/A"
              : myFalse,
          ]);
        } else {
          array.push([
            element.hostname,
            artifact.name,
            artifacts[0]?.event === "download" &&
            artifacts[0]?.value === 1 &&
            (artifacts[1]?.event === "survivedowlandfile"
              ? artifacts[2]?.event
              : artifacts[1]?.event)
              ? myTrue
              : myFalse,

            (artifacts[2]?.event === "tmpcopy"
              ? artifacts[2]?.event
              : artifacts[1]?.event) &&
            artifacts[3]?.event === "survivedfile" &&
            artifacts[3]?.value === 1
              ? myTrue
              : myFalse,
            (artifacts[4]?.event === "execute"
              ? artifacts[5]?.event
              : artifacts[4]?.event) &&
            artifacts[4]?.value === 1 &&
            (artifacts[5]?.event === "surviveexec"
              ? artifacts[4]?.event
              : artifacts[5]?.event) &&
            artifacts[5]?.value === 1
              ? myTrue
              : myFalse,
            artifact.C2 === "Failed"
              ? artifact.C2 === "Failed" || artifact.C2 === "Needless"
                ? myFalse
                : myTrue
              : artifact.C2 === "Needless"
              ? "N/A"
              : myTrue,
          ]);
        }
      });
    })
  );
  return array;
}

function getEndpointsDetails(ReportData) {
  const array = [];
  ReportData?.forEach((host) => {
    array.push([
      host.hostname,
      host.endPoints.IPS.private.IP,
      host.endPoints.IPS.public.IP,
      host.endPoints.OS.name,
      host.endPoints.lastVersion,
    ]);
  });
  return array;
}

function getDataPackages(ReportData, ExternalData) {
  const arrayPack = [];
  ReportData[0].packages.forEach((pack) => {
    arrayPack.push([
      pack.name,
      pack.description,
      pack.failed + pack.successful,
      ExternalData.attackName,
    ]);
  });
  return arrayPack;
}

function getListDataArtifact(ReportData) {
  const artifacts = [];
  ReportData[0].packages.forEach((pack) => {
    pack.artifacts.forEach((art) => {
      artifacts.push([art.name, art.md5]);
    });
  });

  return artifacts;
}

function getListDataArtifactsDetails(ReportData) {
  const artifacts = [];
  ReportData[0].packages.forEach((pack) => {
    pack.artifacts.forEach((art) => {
      artifacts.push(
        ["Name \n \n" + art.name],
        ["MITRE TACTICS \n \n" + art.phaseAttacks],
        ["MITRE ATT&CK \n \n" + (art?.urlMitre ? art.urlMitre : "without URL")],
        []
      );
    });
  });

  return artifacts;
}
function getReferencesLinks(ReportData) {
  const referents = [];
  ReportData[0].packages.forEach((pack) => {
    pack.artifacts.forEach((art) => {
      if (art?.urlMitre) {
        referents.push([art.name, art.urlMitre]);
      }
    });
  });

  return referents;
}
function getMitreInterpretation(ReportData) {
  const array = [];
  ReportData.Reports.historys?.forEach((element) =>
    element.packages?.forEach((pack) => {
      pack.artifacts.forEach((artifact) => {
        array.push([
          artifact.name,
          ValidateInterpretations({
            NetworkSecurity:
              artifact.history[0]?.event === "download" &&
              artifact.history[0]?.value === 1 &&
              artifact.history[1]?.event === "survivedowlandfile" &&
              artifact.history[1]?.value === 1
                ? true
                : false,
            EPP:
              artifact.history[2]?.event === "tmpcopy" &&
              artifact.history[2]?.value === 1 &&
              artifact.history[3]?.event === "survivedfile" &&
              artifact.history[3]?.value === 1
                ? true
                : false,
            ExecutionActive:
              artifact.history[4]?.event === "execute" &&
              artifact.history[4]?.value === 1 &&
              artifact.history[5]?.event === "surviveexec" &&
              artifact.history[5]?.value === 1
                ? true
                : false,
            callback: artifact.callback,
          }),
        ]);
      });
    })
  );
  return array;
}

function attackLifeCicle(Data) {
  let value = [];
  Data.forEach((phase) => {
    value.push({ dataphase: phase.phaseAttacks?.split(" & ") });
  });
  return value;
}
function getMitreDescription(ReportData) {
  const data1 = [];
  ReportData[0].packages.forEach((pack) => {
    pack.artifacts.forEach((art) => {
      data1.push(
        [art.phaseAttacks.toString().replace(/,/g, " & ")],
        [art.description]
      );
    });
  });

  return data1;
}

function ValidateInterpretations(ArtifactGlobalData) {
  /**000***/
  if (
    ArtifactGlobalData.NetworkSecurity === false &&
    ArtifactGlobalData.EPP === false &&
    ArtifactGlobalData.ExecutionActive === false &&
    ArtifactGlobalData.callback === false
  ) {
    return "Great security, no one artifact has been able to attack to your host and any artifact was able to reach the endpoint.";
  } else if (
    /***100***/
    ArtifactGlobalData.NetworkSecurity === true &&
    ArtifactGlobalData.EPP === true &&
    ArtifactGlobalData.ExecutionActive === false &&
    ArtifactGlobalData.callback === false
  ) {
    return "Network security need to be improved, some artifacts had been able to penetrate your host, you need to improve and check your network elements like as FW, IPS, NW Advanced. The security in the Endpoint was able to stop the artifacts, the configuration is good and updated on this vector.";
  } else if (
    /***101**/
    ArtifactGlobalData.NetworkSecurity === true &&
    ArtifactGlobalData.EPP === true &&
    ArtifactGlobalData.ExecutionActive === true &&
    ArtifactGlobalData.callback === false
  ) {
    return "Network security need to be improved, some artifacts had been able to penetrate and attack your host, you need to improve and check your network elements like as FW, IPS, NW Advanced. Some Artifacts were able to be executed please review your configuration on EDR and EPP, in order to avoid future breach. Verify the advance features of the Endpoint Security solution.";
  } else if (
    /***110**/
    ArtifactGlobalData.NetworkSecurity === true &&
    ArtifactGlobalData.EPP === true &&
    ArtifactGlobalData.ExecutionActive === false &&
    ArtifactGlobalData.callback === false
  ) {
    return "Network security and EPP security or security based on hashing detection need to be improved, some artifacts had been able to penetrate and attack your host, you need to improve and check your network elements like as FW, IPS, NW Advanced, also it is necessary to check de EPP configuration. Some Artifacts were able to be executed please review your configuration on EDR and EPP, in order to avoid future breach. Verify the advance features of the Endpoint Security Solution.";
  } else if (
    /***111**/
    ArtifactGlobalData.NetworkSecurity === true &&
    ArtifactGlobalData.EPP === true &&
    ArtifactGlobalData.ExecutionActive === true &&
    ArtifactGlobalData.callback !== false
  ) {
    return "Network security need to be improved, some artifacts had been able to penetrate, attack and make callbacks from your host, you need to improve and check your network elements like as FW, IPS, NW Advanced. Also check IoC & BoC configurations and Endpoint Security Solution.";
  } else {
    return "Great security, no one artifact has been able to attack to your host and any artifact was able to reach the endpoint.";
  }
}
