import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import "./Advance.scss";
export default function Interpretation(props) {
  const { ArtifactGlobalData } = props;
  const [TextLabel, setTextLabel] = useState("");

  const ValidateInterpretations = useCallback(() => {
    /**000***/
    if (
      (ArtifactGlobalData.fase1 === false || ArtifactGlobalData.fase1 === 0) &&
      (ArtifactGlobalData.fase2 === false || ArtifactGlobalData.fase2 === 0) &&
      (ArtifactGlobalData.fase3 === false || ArtifactGlobalData.fase3 === 0) &&
      (ArtifactGlobalData.fase4 === false || ArtifactGlobalData.fase4 === 0) &&
      (ArtifactGlobalData.fase5 === false || ArtifactGlobalData.fase5 === 0) &&
      (ArtifactGlobalData.fase6 === false || ArtifactGlobalData.fase6 === 0) &&
      ArtifactGlobalData.callback === false
    ) {
      setTextLabel(
        "Great security, no one artifact has been able to attack to your host and any artifact was able to reach the Endpoint."
      );
    } else if (
      /***100***/
      ArtifactGlobalData.fase1 === 1 &&
      ArtifactGlobalData.fase2 === 1 &&
      (ArtifactGlobalData.fase3 === false || ArtifactGlobalData.fase3 === 0) &&
      (ArtifactGlobalData.fase4 === false || ArtifactGlobalData.fase4 === 0) &&
      (ArtifactGlobalData.fase5 === false || ArtifactGlobalData.fase5 === 0) &&
      (ArtifactGlobalData.fase6 === false || ArtifactGlobalData.fase6 === 0) &&
      ArtifactGlobalData.callback === false
    ) {
      setTextLabel(
        "Network security need to be improved, some artifacts had been able to penetrate your host, you need to improve and check your network elements like as FW, IPS, NW Advanced. The security in the Endpoint was able to stop the artifacts, the configuration is good and updated on this vector."
      );
    } else if (
      /***101**/
      ArtifactGlobalData.fase1 === 1 &&
      ArtifactGlobalData.fase2 === 1 &&
      ArtifactGlobalData.fase3 === 1 &&
      ArtifactGlobalData.fase4 === 1 &&
      ArtifactGlobalData.fase5 === 1 &&
      ArtifactGlobalData.fase6 === 1 &&
      ArtifactGlobalData.callback === false
    ) {
      setTextLabel(
        "Network security need to be improved, some artifacts had been able to penetrate and attack your host, you need to improve and check your network elements like as FW, IPS, NW Advanced. Some Artifacts were able to be executed please review your configuration on EDR and EPP, in order to avoid future breach. Verify the advance features of the Endpoint Security solution."
      );
    } else if (
      /***110**/
      ArtifactGlobalData.fase1 === 1 &&
      ArtifactGlobalData.fase2 === 1 &&
      ArtifactGlobalData.fase3 === 1 &&
      ArtifactGlobalData.fase4 === 1 &&
      (ArtifactGlobalData.fase5 === false || ArtifactGlobalData.fase5 === 0) &&
      (ArtifactGlobalData.fase6 === false || ArtifactGlobalData.fase6 === 0) &&
      ArtifactGlobalData.callback === false
    ) {
      setTextLabel(
        "Network security and EPP security or security based on hashing detection need to be improved, some artifacts had been able to penetrate and attack your host, you need to improve and check your network elements like as FW, IPS, NW Advanced, also it is necessary to check de EPP configuration. Some Artifacts were able to be executed please review your configuration on EDR and EPP, in order to avoid future breach. Verify the advance features of the Endpoint Security Solution."
      );
    } else if (
      /***111**/
      ArtifactGlobalData.fase1 === 1 &&
      ArtifactGlobalData.fase2 === 1 &&
      ArtifactGlobalData.fase3 === 1 &&
      ArtifactGlobalData.fase4 === 1 &&
      ArtifactGlobalData.fase5 === 1 &&
      ArtifactGlobalData.fase6 === 1 &&
      ArtifactGlobalData.callback !== false
    ) {
      setTextLabel(
        "Network security need to be improved, some artifacts had been able to penetrate, attack and make callbacks from your host, you need to improve and check your network elements like as FW, IPS, NW Advanced. Also check IoC & BoC configurations and Endpoint Security Solution."
      );
    } else {
      setTextLabel(
        "Great security, no one artifact has been able to attack to your host and any artifact was able to reach the Endpoint."
      );
    }
  }, [ArtifactGlobalData]);

  useEffect(() => {
    ValidateInterpretations();
  }, [ArtifactGlobalData, ValidateInterpretations]);

  return (
    <Box className="GeneralContent">
      <Typography>Interpretation of the Sample</Typography>
      <Typography className="InterpretationBody">{TextLabel}</Typography>
    </Box>
  );
}
