import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CalendarPicker from "@mui/lab/CalendarPicker";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import { SecondaryButton } from "../../../../Helpers/Themes";
import { GetDate } from "../../../../Helpers/Scripts";

import "./PopUpDetailsScheduled.scss";

const style = {
  boxShadow: "18px 4px 35px #00000026",
  borderRadius: "14px",
  maxWidth: "700px",

  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "transparent",
  color: "white",
  p: 2,
};

const CalendarPickerColor = styled(CalendarPicker)(({ theme }) => ({
  // backgroundColor: "#c59d48",

  "& .MuiTypography-caption": {
    color: "#ffff !important",
  },
  "& .MuiIconButton-root": {
    color: "#ffff !important",
  },
  "& .Mui-selected": {
    backgroundColor: "#e328af !important",
  },
  "& .Mui-selected:hover": {
    backgroundColor: "#e328af",
  },
  "& .Mui-selected:focus": {
    backgroundColor: "#e328af",
  },
  "& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root:active": {
    backgroundColor: "#e328af",
  },
}));

export default function PopUpDetailsScheduled(props) {
  const { GetOpenModal, InfoExecutionScheduled } = props;
  const [infoExecutionScheduled, setInfoExecutionScheduled] = useState(
    InfoExecutionScheduled
  );
  const [open, setOpen] = useState(GetOpenModal);

  const handleClose = () => {
    setOpen(false);
    props.effectModalEdit(false);
  };

  useEffect(() => {
    setOpen(GetOpenModal);
    setInfoExecutionScheduled(InfoExecutionScheduled);
    return () => {
      setOpen(GetOpenModal);
      setInfoExecutionScheduled(InfoExecutionScheduled);
    };
  }, [GetOpenModal, InfoExecutionScheduled]);

 
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#0a0818",
                border: "1px solid #1e1c3a",
                borderRadius: "8px",
                fontSize: "14px",
                padding: "3px 15px",
                color: "#8e97ad",
              }}
              sx={{ maxWidth: 600 }}
            >
              <CardHeader
                sx={{
                  "& .MuiCardHeader-title": { fontSize: "18px" },
                  "& .MuiCardHeader-subheader": {
                    fontSize: "15px",
                    color: "#8e97ad",
                  },
                }}
                
                action={
                  <SecondaryButton
                    onClick={handleClose}
                    style={{ margin: "0px" }}
                    aria-label="settings"
                  >
                    <CloseIcon style={{ color: "white" }} />
                  </SecondaryButton>
                }
                title="Details of emulation scheduled"
                subheader={`Emulation scheduled name: ${infoExecutionScheduled?.name}`}
              />
              <Box
                style={{
                  borderRadius: "10px",
                  backgroundColor: "#0a0818",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CalendarPickerColor
                    minDate={new Date(infoExecutionScheduled?.startDate)}
                    maxDate={new Date(infoExecutionScheduled?.startDate)}
                    date={
                      infoExecutionScheduled?.startDate
                        ? new Date(infoExecutionScheduled?.startDate)
                        : null
                    }
                    onChange={() => {}}
                  />
                </LocalizationProvider>
              </Box>

              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        width: "100%",
                        textAlign: "center",
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "17px",
                        color: "#8e97ad",
                      }}
                    >
                      Date the scheduled emulation starts
                    </Box>

                    {GetDate(infoExecutionScheduled?.startDate)}
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        width: "100%",
                        textAlign: "center",
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "17px",
                        padding: "0px 14px",
                        color: "#8e97ad",
                      }}
                    >
                      Endpoints emulation scheduled
                    </Box>
                    <Box style={{maxHeight:"130px",overflowY:"auto" }}>
                    {infoExecutionScheduled.clients?.map((row) => {
                      return <div>{row.workUser[0].hostname}</div>;
                    })}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      style={{
                        width: "100%",
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "17px",
                        maxWidth: "600px",
                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Packages
                    </Box>
                    <Box style={{textAlign:"center", margin:"auto", display:"inline"}}>
                    {infoExecutionScheduled.fk_package?.length !== 0 ? (
                      infoExecutionScheduled.fk_package?.map((row) => {
                      return(  
                      <>                    
                          <Typography
                            style={{
                              textAlign:"center",
                              color: "#66638f",
                              maxWidth: "240px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              minWidth: "190px",
                            }}
                          >
                            {row.name}
                          </Typography>
                        </>
                      )
                        // return <div>{row.name}</div>;
                      })
                    ) : (
                      <>
                        <br></br>Information about their one or many packages
                        deleted
                      </>
                    )}
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      style={{
                        width: "100%",
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "17px",

                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Vector
                    </Box>
                    <Typography>{infoExecutionScheduled?.vector === "RealExecution" ? "Execution" 
                    : infoExecutionScheduled?.vector === "EPP" || infoExecutionScheduled?.vector === "Epp" ? "Endpoint" 
                    : "Network"                     
                    }</Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Box style={{ display: "inline-block" }}>
                {/* <PopUpEditScheduled
                  DataUser={DataUser}
                  InfoExecutionDataScheduled={infoExecutionScheduled}
                  handleClose={handleClose}
                ></PopUpEditScheduled> */}
              </Box>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
