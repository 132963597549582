/* eslint-disable no-undef */

export default function AgentLocationMapScript(agentslocation,disposed) {
  var agentlocation=[];
  var colorSet = new am4core.ColorSet();

  agentslocation.forEach(element => {
        var locationObj={
          title: element.hostname,                
          latitude: element.coordinates[0],
          longitude: element.coordinates[1],
          color: colorSet.next(),
          //url: "https://www.google.com.mx/maps/@" + element.coordinates[0]+","+ element.coordinates[1]
      }
      agentlocation.push(locationObj);
      });

am4core.ready(function () {
    //am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);
    // Themes end
    am4core.options.autoDispose = true;
    // Create map instance
    var chart = am4core.create("AgentLocationMap", am4maps.MapChart);
    chart.logo.height = -15000;
    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();
    chart.responsive.enabled = true;
    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Exclude Antartica
    polygonSeries.exclude = ["AQ"];

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Configure series
    var polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#454958");
    polygonTemplate.polygon.fillOpacity = 0.8;
    polygonTemplate.polygon.stroke = am4core.color("#667A90");

    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#75D3FF");

    // Add image series
    var imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    imageSeries.mapImages.template.propertyFields.latitude = "latitude";
    imageSeries.mapImages.template.tooltipText = "{title}";
   // imageSeries.mapImages.template.propertyFields.url = "url";

    var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle.radius = 3;
    circle.propertyFields.fill = "color";

    var circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
    circle2.radius = 3;
    circle2.propertyFields.fill = "color";

    circle2.events.on("inited", function (event) {
      animateBullet(event.target);
    });

    function animateBullet(circle) {
      var animation = circle.animate(
        [
          { property: "scale", from: 1, to: 5 },
          { property: "opacity", from: 1, to: 0 },
        ],
        1000,
        am4core.ease.circleOut
      );
      animation.events.on("animationended", function (event) {
        animateBullet(event.target.object);
      });
    }

  

    imageSeries.data = agentlocation;
    if(disposed === true)
    {chart.dispose();}
  });
}
