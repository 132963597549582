
/* eslint-disable no-undef */
export default function Top5MostThreatenedAgentScript(
  top5ThreatList,
  disposed,
  exported
  ){
  var objecImageExporting;
  am4core.ready(function () {
    // Themes begin
    am4core.useTheme(am4themes_dark);
    am4core.useTheme(am4themes_animated);
    am4core.options.autoDispose = true;

    let chart = am4core.create("threatenedAgentsChart", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.logo.height = -15000;
    
    chart.data = top5ThreatList;
    
    chart.colors.step = 2;
    chart.padding(30, 30, 10, 30);
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
    
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "client";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.fontSize="10px";
    
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 60;
    
    
    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);
    series1.columns.template.tooltipText =
      "{name}: {valueY}";
    series1.name = "Failed";
    series1.dataFields.categoryX = "client";
    series1.dataFields.valueY = "failed";
    series1.dataFields.valueYShow = "totalPercent";
    series1.dataItems.template.locations.categoryX = 0.5;
    series1.stacked = true;
    series1.tooltip.pointerOrientation = "vertical";    
    series1.fill = am4core.color("#E61E5E");
    series1.stroke = am4core.color("#E61E5E");  
   
    let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false; 
    bullet1.label.text = "{valueY.totalPercent.formatNumber('#.0')}%";   
    bullet1.label.fill = am4core.color("#ffffff");
    bullet1.locationY = 0.5;
    bullet1.label.adapter.add("textOutput", function (text, target) {                    
      if (target.dataItem && target.dataItem.valueY === 0)
         return "";                
      return text;
   });

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(80);
    series2.columns.template.tooltipText =
      "{name}: {valueY}";
    series2.name = "Success";
    series2.dataFields.categoryX = "client";
    series2.dataFields.valueY = "satisfactory";
    series2.dataFields.valueYShow = "totalPercent";
    series2.dataItems.template.locations.categoryX = 0.5;
    series2.stacked = true;
    series2.tooltip.pointerOrientation = "vertical";    
    
    let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{valueY.totalPercent.formatNumber('#.0')}%";   
    bullet2.locationY = 0.5;
    bullet2.label.fill = am4core.color("#ffffff");  
   
  if(disposed === true)
  {
    chart.dispose();
  }
  if(exported)
  {
    objecImageExporting = chart.exporting.getImage('png');
  }
  }); // end am4core.ready()
  return objecImageExporting;
}

