import jsPDF from "jspdf";
import { CoverPageInfo, NewPage } from "./HelperDataPdf/DesignPdfs";

const WarningText = {
  1: "Sorry, but this report doesn't \n contain history emulation results.",
  2: "Sorry, this report cannot display because the Endpoint Is not reachable, or it was turned off during the test.",
  3: "Sorry, this report does not contain information about their one or many package processing.",
  4: "Sorry, this report does not contain information about their one or many artifacts processing.",
};

export const openPdfInfo = (Report, Select,date) => {
  let ReportData = Report;
  var lMargin = 15; //left margin in mm
  var rMargin = 15; //right margin in mm
  var pdfInMM = 210; // width of A4 in mm

  const doc = new jsPDF();
  CoverPageInfo(doc, ReportData.name.toString(),"Info Report",date);
  NewPage(doc);
  doc.setTextColor("#214a90");
  doc.setFontSize(25);
  doc.text("Detailed Report", 70, 35);
  doc.setFillColor("#132a5a");
  doc.setTextColor("#000");
  doc.setFontSize(12);
  doc.setFont("Helvetica-Bold", "normal", "bold"); //
  var paragraph = WarningText[Select];
  var lines = doc.splitTextToSize(paragraph, pdfInMM - lMargin - rMargin);
  doc.text(lines, lMargin, 110);
  doc.save(ReportData.name.toString()+"_Info"); //opens the data uri in new window
};
