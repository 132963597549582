import {
  ContentPaste,
  Coronavirus,
  DesktopWindows,
  ExpandMore,
  SwapCalls,
  Webhook,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ActionButton, SecondaryButton } from "../../../../Helpers/Themes";
import { NotifySuccess } from "../../../../Helpers/Scripts";
import Interpretation from "./InterpretationSection";

export default function GeneralInfo(props) {
  const { ArtifactGlobalData, ArtifactData, typeStatus } = props;
  const [handleInfo, sethandleInfo] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [mitigationShow, setMitigationShow] = useState(true);
  const evaluateMitigations = useCallback(() => {
    if (
      ((ArtifactGlobalData.fase1 === 0 || ArtifactGlobalData.fase1 === false) &&
        (ArtifactGlobalData.fase2 === 0 ||
          ArtifactGlobalData.fase2 === false)) ||
      (ArtifactGlobalData.fase1 === 1 &&
        (ArtifactGlobalData.fase2 === 0 || ArtifactGlobalData.fase2 === false))
    ) {
      setMitigationShow(false);
    }
  }, [ArtifactGlobalData]);

  useEffect(() => {
    evaluateMitigations();
  }, [ArtifactGlobalData, evaluateMitigations]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    NotifySuccess("Copied to Clipboard");
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={8}>
        <Box className="GeneralContent">
          <Typography>Name : {ArtifactGlobalData.name}</Typography>
          <Typography>Start emulation : {ArtifactGlobalData.start}</Typography>
          <Typography>
            Finish emulation : {ArtifactGlobalData.finish}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={4}>
        <Box className="GeneralContent">
          <Box className="ContainerListIcon">
            {ArtifactGlobalData.vector === "NetworkSecurity" ? (
              <Box className="ContainerIcon Web">
                <Tooltip title="Network">
                  <Coronavirus className="Icon" />
                </Tooltip>
              </Box>
            ) : null}
            {ArtifactGlobalData.vector === "EPP" ? (
              <Box className="ContainerIcon End">
                <Tooltip title="Endpoint">
                  <DesktopWindows className="Icon" />
                </Tooltip>
              </Box>
            ) : null}
            {ArtifactGlobalData.vector === "RealExecution" ? (
              <Box className="ContainerIcon Exe">
                <Tooltip title="Execution">
                  <Webhook className="Icon" />
                </Tooltip>
              </Box>
            ) : null}
            {ArtifactGlobalData.callback ? (
              <Box className="ContainerIcon Call">
                <Tooltip title="CallBack">
                  <SwapCalls className="Icon" />
                </Tooltip>
              </Box>
            ) : null}
          </Box>
          <Typography>
            Status :{" "}
            {ArtifactGlobalData.satisfactory === typeStatus ? "True" : "False"}
          </Typography>
          <Typography>
            Callback : {ArtifactGlobalData.callback ? "True" : "False"}
          </Typography>
          <Typography>C2 : {ArtifactGlobalData.C2}</Typography>
        </Box>
      </Grid>
      <Grid item md={12}>
        <Interpretation ArtifactGlobalData={ArtifactGlobalData} />
      </Grid>
      <Grid item md={12}>
        <Box className="GeneralContent">
          <ActionButton
            onClick={() => {
              sethandleInfo(!handleInfo);
            }}
            className="ActionsChange"
          >
            {handleInfo ? "Show resolutions Actions" : "Show emulation msg"}
          </ActionButton>
          {handleInfo ? (
            <Box className="InterpretationBody">
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <Typography color="white">Artifact steps messages</Typography>
                  {ArtifactGlobalData.ErrorDetails.Error ? (
                    <Typography color="red">
                      Error message <br />
                      {ArtifactGlobalData.ErrorDetails.Description}
                    </Typography>
                  ) : (
                    <>
                      {ArtifactGlobalData?.msgFase1 ? (
                        <>
                          <Typography>
                            Phase one: <br />
                            {ArtifactGlobalData?.msgFase1}
                          </Typography>
                        </>
                      ) : null}
                      {ArtifactGlobalData?.msgFase2 ? (
                        <>
                          <Typography>
                            Phase two: <br />
                            {ArtifactGlobalData?.msgFase2}
                          </Typography>
                        </>
                      ) : null}
                      {ArtifactGlobalData?.msgFase3 ? (
                        <>
                          <Typography>
                            Phase three:
                            <br /> {ArtifactGlobalData?.msgFase3}
                          </Typography>
                        </>
                      ) : null}
                      {ArtifactGlobalData?.msgFase4 ? (
                        <>
                          <Typography>
                            Phase four:
                            <br /> {ArtifactGlobalData?.msgFase4}
                          </Typography>
                        </>
                      ) : null}
                      {ArtifactGlobalData?.msgFase5 ? (
                        <>
                          <Typography>
                            Phase five: <br />
                            {ArtifactGlobalData?.msgFase5}
                          </Typography>
                        </>
                      ) : null}

                      {ArtifactGlobalData?.msgFase6 ? (
                        <>
                          <Typography>
                            Phase six: <br />
                            {ArtifactGlobalData?.msgFase6}
                          </Typography>
                        </>
                      ) : null}
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Typography>Follow Actions</Typography>
              {mitigationShow ? (
                <>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                      expandIcon={<ExpandMore />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      className="AccordionStyle"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Resolution Network
                      </Typography>
                      <Typography
                        sx={{ width: "53%", flexShrink: 0 }}
                      ></Typography>
                      <SecondaryButton
                        sx={{ width: "50px", height: "25px" }}
                        onClick={copyToClipboard.bind(
                          this,
                          ArtifactData.resolutionNetwork
                        )}
                      >
                        <ContentPaste style={{ fontSize: "small" }} />
                        <Typography style={{}}>Copy</Typography>
                      </SecondaryButton>
                    </AccordionSummary>
                    <AccordionDetails className="AccordionStyle">
                      <Grid container>
                        <Grid item md={12}>
                          <Typography className="AccordionText">
                            {ArtifactData ? ArtifactData.resolutionNetwork : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                      expandIcon={<ExpandMore />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      className="AccordionStyle"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Resolution Endpoint
                      </Typography>
                      <Typography
                        sx={{ width: "53%", flexShrink: 0 }}
                      ></Typography>
                      {ArtifactGlobalData.vector === "RealExecution" ||
                      ArtifactGlobalData.vector === "EPP" ? (
                        <>
                          {ArtifactGlobalData.fase3 === 1 &&
                          ArtifactGlobalData.fase4 === 1 ? (
                            <SecondaryButton
                              sx={{ width: "50px", height: "25px" }}
                              onClick={copyToClipboard.bind(
                                this,
                                ArtifactData.resolutionEndPoint
                              )}
                            >
                              <ContentPaste style={{ fontSize: "small" }} />

                              <Typography style={{}}>Copy</Typography>
                            </SecondaryButton>
                          ) : null}
                        </>
                      ) : null}
                    </AccordionSummary>
                    <AccordionDetails className="AccordionStyle">
                      <Grid container>
                        <Grid item md={12}>
                          {ArtifactGlobalData.vector === "RealExecution" ||
                          ArtifactGlobalData.vector === "EPP" ? (
                            <Typography className="AccordionText">
                              {ArtifactGlobalData.fase3 === 1 &&
                              ArtifactGlobalData.fase4 === 1 ? (
                                <>
                                  {ArtifactData
                                    ? ArtifactData.resolutionEndPoint
                                    : ""}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </Typography>
                          ) : (
                            <Typography className="AccordionText">
                              N/A
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {ArtifactGlobalData.C2 !== "Failed" &&
                  ArtifactGlobalData.C2 !== "Needless" ? (
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "white",
                          },
                        }}
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="AccordionHeader"
                      >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>
                          Resolution Callback
                        </Typography>
                        <Typography
                          sx={{ width: "53%", flexShrink: 0 }}
                        ></Typography>
                        {ArtifactGlobalData.C2 !== "Failed" &&
                        ArtifactGlobalData.C2 !== "Needless" ? (
                          <SecondaryButton
                            sx={{ width: "50px", height: "25px" }}
                            onClick={copyToClipboard.bind(
                              this,
                              ArtifactData.resolutionCallback
                            )}
                          >
                            <ContentPaste style={{ fontSize: "small" }} />

                            <Typography>Copy</Typography>
                          </SecondaryButton>
                        ) : null}
                      </AccordionSummary>
                      <AccordionDetails className="AccordionStyle">
                        <Grid container>
                          <Grid item md={12}>
                            <Typography className="AccordionText">
                              {ArtifactGlobalData.C2 !== "Failed" &&
                              ArtifactGlobalData.C2 !== "Needless" &&
                              ArtifactGlobalData.callback === true ? (
                                <>
                                  {ArtifactData
                                    ? ArtifactData.resolutionCallback
                                    : ""}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </>
              ) : (
                <Typography className="GeneralContent" color={"GrayText"}>
                  No Actions for this sample
                </Typography>
              )}
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
