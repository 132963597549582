import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function PasswordInput(props) {
  const { ErrorMessage } = props;
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    props.Value(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className="inputEnvolt" style={{position: "relative"}}>
      <input
        autocomplete="new-password"
        error={ErrorMessage}
        className="input-style-inputPassword"
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange("password")}
      />
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
        style={{marginRight:"1px",marginTop:"7px",float:"right",display:"inline-block",top:"-55px",color:"white"}}
      >
        {values.showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </Box>
  );
}
