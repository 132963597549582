import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import "./SelectAgent.scss";
import AgentsOnline from "./AgentsOnline/AgentsOnline";
import AgentsToAll from "./AgentsToAll/AgentsToAll";
//import Carousel from "./Carousel";

export default function StepSelectAgents(props) {
  const {
    DataUser,
    ValueIndexExecution,
    ValuesCheckedAgentsOnline,
    ValuesCheckedAgentsAll,
    AgentConnect
  } = props;
  const [checkedAgentsAll, setCheckedAgentsAll] = useState(
    ValuesCheckedAgentsAll
  );
  const [checkedAgentsOnline, setCheckedAgentsOnline] = useState(
    ValuesCheckedAgentsOnline
  );
  useEffect(() => {
    setCheckedAgentsAll(ValuesCheckedAgentsAll)
  }, [ValuesCheckedAgentsAll])
  
  useEffect(() => {
    setCheckedAgentsOnline(ValuesCheckedAgentsOnline)
  }, [ValuesCheckedAgentsOnline])

  const handleCheckedAgentsAll = (value) => {
    props.handleCheckedAgentsAll(value);
  };

  const handleCheckedAgentsOnline = (value) => {
    props.handleCheckedAgentsOnline(value);    
  }; 

  return (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      {ValueIndexExecution === 0 ? (
        <AgentsOnline
          DataUser={DataUser}
          ValuesCheckedAgentsOnline={checkedAgentsOnline}
          handleCheckedAgentsOnline={handleCheckedAgentsOnline}
          AgentConnect={AgentConnect}

        />
      ) : (
        <AgentsToAll
          DataUser={DataUser}
          ValuesCheckedAgentsAll={checkedAgentsAll}
          handleCheckedAgentsAll={handleCheckedAgentsAll}

        />
      )}
    </Box>
  );
}
